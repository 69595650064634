import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import DashboardSidebar from './DashboardSidebar';
import DashboardNavbar from './DashboardNavbar';
import useAuth from 'src/hooks/useAuth';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setExpandedContent } from 'src/redux/slices/Main/main';
import NewVersionValidation from 'src/components/NewVersionValidation';
import SessionExpiryAlert from 'src/components/SessionExpiryAlert';

// ----------------------------------------------------------------------

const MIN_SCREEN_WIDTH = 1280;
const RESIDENT = 'resident';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  flexDirection: 'row',
  position: 'relative',
  // overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: '200px',
  paddingBottom: theme.spacing(10),
  paddingRight: '30px !important',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: '30px !important',
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { pathname } = useLocation();
  const isCompliance = pathname.includes('/workbench');
  const showDashboard =
    pathname.includes('/workbench/user') || pathname.includes('/summary');
  const isCertificationConfigWorkbench = pathname.match(
    '/certification-config/(.+)$'
  );
  const isCertificationConfigList = pathname.match(
    '/workbench/(.+)/certification-config(/*)$'
  );
  const lockScroll = pathname.includes(
    '/workbench/user/summary-for-compliance-specialist'
  );
  const isApplicantsPage =
    pathname.includes('/workbench/my-renters') ||
    pathname.includes('/workbench/team-renters');

  const isBulkCommunications = pathname.includes(
    '/workbench/bulk_communications'
  );

  const dispatch = useDispatch();

  const expandedPageContent = useSelector(
    ({ main: { expandedPageContent } }) => expandedPageContent,
    shallowEqual
  );

  const forceExpanded = useSelector(
    ({ main: { forceExpanded } }) => forceExpanded,
    shallowEqual
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const mainWrapperSx = useMemo(() => {
    if (isCertificationConfigWorkbench) {
      return { paddingTop: '0px', paddingBottom: '10px' };
    }
    if (isCertificationConfigList) {
      return { paddingTop: '70px', paddingBottom: '10px' };
    }
    if (!isCompliance) {
      return { paddingTop: '70px' };
    }
    if (isApplicantsPage || isBulkCommunications) {
      return { paddingTop: '30px', paddingBottom: '30px' };
    }
    if (lockScroll && showDashboard) {
      return { paddingTop: '30px', height: '100vh', overflow: 'scroll' };
    }
    if (showDashboard) {
      return { paddingTop: '30px' };
    }
    return {};
  }, [
    isApplicantsPage,
    isCertificationConfigList,
    isCertificationConfigWorkbench,
    isCompliance,
    isBulkCommunications,
    lockScroll,
    showDashboard,
  ]);

  useEffect(() => {
    const onResize = () => {
      if (forceExpanded) {
        return;
      }
      if (window.innerWidth > MIN_SCREEN_WIDTH && !open) {
        setOpen(true);
        dispatch(setExpandedContent(false));
      }
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [dispatch, forceExpanded, open]);

  /*   useEffect(() => {
   LogRocket.identify(user.id, {
   name: user.first_name + " " + user.last_name,
   email: user.email
   });
   }, [user]); */

  useEffect(() => {
    if (window.innerWidth < MIN_SCREEN_WIDTH) {
      return;
    }
    if (expandedPageContent) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [expandedPageContent]);

  return (
    <RootStyle>
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <NewVersionValidation />
      <DashboardNavbar
        onOpenSidebar={handleOpen}
        isResident={
          !!user?.groups.find((g) => g?.name === RESIDENT) && !isCompliance
        }
      />
      <MainStyle id="testing-main" style={mainWrapperSx}>
        <Outlet id="test-outlet" />
        <SessionExpiryAlert />
      </MainStyle>
    </RootStyle>
  );
}
