import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/utils/axios';

export const DEFAULT_APP_VERSION = '1.0.0';

export const ACHIEVEMENTS = {
  UPLOADED_UNITS: 'UPLOADED_UNITS',
  UPLOADED_RENT_ROLL: 'UPLOADED_RENT_ROLL',
  CREATED_CERTIFICATION_NAME: 'CREATED_CERTIFICATION_NAME',
  COMPLETED_PROPERTY_SETUP: 'COMPLETED_PROPERTY_SETUP',
};

const initialState = {
  memberType: '',
  householdMember: {},
  isLoading: false,
  expandedPageContent: false,
  forceExpanded: false,
  appVersionAlertVisible: false,
  achievements: {
    visible: false,
    data: null,
  },
};

export const slice = createSlice({
  name: 'Main Slice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setExpandedContent(state, action) {
      state.expandedPageContent = action.payload;
    },

    setForceExpanded(state, action) {
      state.forceExpanded = action.payload;
    },

    // SET HOUSEHOLD MEMBER TYPE
    setMemberType(state, action) {
      state.isLoading = false;
      state.memberType = action.payload;
    },

    setHouseholdMember(state, action) {
      state.isLoading = false;
      state.householdMember = action.payload;
    },

    resetMemberType(state) {
      state.isLoading = false;
      state.memberType = '';
    },

    updateAchievementsOptions(state, action) {
      state.achievements = { ...state.achievements, ...action.payload };
    },
  },
});

export const {
  setExpandedContent,
  setForceExpanded,
  updateAchievementsOptions,
  setMemberType,
  setHouseholdMember,
} = slice.actions;

export default slice.reducer;

export function createAchievement({
  userId,
  achievementName,
  successFunc,
  errFunc,
}) {
  return async (dispatch) => {
    try {
      const { data: achievement } = await axiosInstance.get(
        `gamification/achievement/?name=${achievementName}`
      );

      if (achievement?.[0]?.id) {
        const res = await axiosInstance.post(
          'gamification/achievement/unlock/',
          { user: userId, achievement: achievement[0].id }
        );
        if (res.status === 200) {
          dispatch(
            updateAchievementsOptions({ visible: true, data: res?.data })
          );
          if (successFunc) {
            successFunc();
          }
        }
      }
    } catch (error) {
      if (errFunc) {
        errFunc();
      }
      console.error(error);
    }
  };
}
