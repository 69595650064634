import React, { FC } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';

interface LoginLinkSentProps {
  onBack: () => void;
}

const LoginLinkSent: FC<LoginLinkSentProps> = ({ onBack }) => {
  return (
    <Container>
      <Box sx={{ maxWidth: 480, mx: 'auto' }}>
        <Button
          size="small"
          onClick={onBack}
          startIcon={<Icon icon={arrowIosBackFill} width={20} height={20} />}
          sx={{ mb: 3 }}
        >
          Back
        </Button>
        <Typography variant="h3" paragraph>
          Please check your email!
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          We have sent you the link to login to your email.
        </Typography>
      </Box>
    </Container>
  );
};

export default LoginLinkSent;
