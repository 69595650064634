import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
import parseQueryParams from 'src/utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  household: {},
  updated: false,
};

const slice = createSlice({
  name: 'Household',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Single household
    getHouseholdSuccess(state, action) {
      state.isLoading = false;
      state.household = action.payload;
      state.updated = false;
    },

    setUpdatedSuccess(state, action) {
      state.updated = action.payload;
    },

    resetHousehold(state) {
      state.household = {};
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { resetHousehold, setUpdatedSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getHousehold(householdId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `household/all_household/${householdId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getHouseholdSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setUpdated(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setUpdatedSuccess(value));
  };
}
