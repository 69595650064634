import NProgress from 'nprogress';
import { useEffect, useMemo } from 'react';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
//
// import Logo from './Logo';

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme = {}) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme?.zIndex?.snackbar || 999999,
        backgroundColor: theme?.palette?.primary.main || '#00AB55',
        boxShadow: `0 0 2px ${theme?.palette?.primary.main || '#00AB55'}`,
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${
          theme?.palette?.primary.main || '#00AB55'
        }, 0 0 5px ${theme?.palette?.primary.main || '#00AB55'}`,
      },
    },
  },
}));

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme?.palette?.background.default || '#fff',
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
  nprogressStyle();

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return (
    <RootStyle data-testid={'loading-screen'} {...other}>
      <Box sx={{ width: 70 }} align="center">
        <CircularProgress size={70} />
      </Box>
    </RootStyle>
  );
}
