import { slice } from './main';

export const setMemberType = (memberType) => {
  return async (dispatch) => {
    dispatch(slice.actions.setMemberType(memberType));
  };
};

export const resetMemberType = () => async (dispatch) => {
  dispatch(slice.actions.resetMemberType());
};

export const setHouseholdMember = (householdMember) => async (dispatch) => {
  dispatch(slice.actions.setHouseholdMember(householdMember));
};
