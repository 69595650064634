import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { VerifyCodeForm } from '../../components/authentication/verify-code';
import useCountdown from '../../hooks/useCountdown';
import axios from 'src/utils/axios';
import { formatPhoneNumber } from 'src/utils/formatPhoneNumber';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function VerifyCode({ phone, onBack, onExtendSessionSuccess }) {
  const [isDisableSend, setIsDisableSend] = useState(true);
  const [countdownState, setCountdownState] = useState(false);
  const { countdown, reset } = useCountdown();

  useEffect(() => {
    if (countdown.minutes === '00' && countdown.seconds === '00') {
      setIsDisableSend(false);
    } else {
      setIsDisableSend(true);
    }
    if (countdown.minutes === '00' && countdown.seconds === '10') {
      setCountdownState(true);
    }
  }, [countdown]);

  const resendCode = async () => {
    await axios.post('mobile_phone/mobile_phone_login/send_sms_code/', {
      phone,
    });
    reset();
  };

  return (
    <>
      <Box sx={{ maxWidth: 480, mx: 'auto' }}>
        <Button
          size="small"
          onClick={onBack}
          startIcon={<Icon icon={arrowIosBackFill} width={20} height={20} />}
          sx={{ mb: 3 }}
        >
          Back
        </Button>

        <Typography variant="h3" paragraph>
          Please check your phone!
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          We have sent you a 6-digit confirmation code to{' '}
          {formatPhoneNumber(phone.replace('+1', ''))}, please enter the
          confirmation code to login.
        </Typography>

        <Box sx={{ mt: 5, mb: 3 }}>
          <VerifyCodeForm onExtendSessionSuccess={onExtendSessionSuccess} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography sx={{ color: 'text.secondary' }}>
            {"Didn't receive the code?"}
          </Typography>
          <LoadingButton
            disabled={isDisableSend || !countdownState}
            sx={{ ml: 2 }}
            variant="contained"
            color="info"
            underline="none"
            onClick={resendCode}
          >
            Resend code{' '}
            {isDisableSend && (
              <>
                {' '}
                - {countdown.minutes}:{countdown.seconds}
              </>
            )}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}
