import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
import parseQueryParams from 'src/utils/query';
import {
  getInitialStep,
  parseFile,
  validateHasErrors,
} from 'src/pages/PropertySetup/utils';

// ----------------------------------------------------------------------

const initialState = {
  selectedSetupProperty: null,
  availableSetupProperties: [],
  setup: null,
  initialStep: 0,
  unitsStep: {
    ready: false,
    file: null,
    fileName: '',
    fileSize: null,
    data: [],
    errors: {},
    hasErrors: false,
    needPost: false,
  },
  rentRollStep: {
    ready: false,
    file: null,
    fileName: '',
    fileSize: null,
    data: [],
    errors: {},
    hasErrors: false,
    needPost: false,
  },
  certificationStep: {
    name: '',
  },
  isLoadingSSP: true,
  isLoadingASP: false,
  isAnalyzingUnits: false,
  isAnalyzingRentRoll: false,
};

const slice = createSlice({
  name: 'PropertySetup',
  initialState,
  reducers: {
    // SELECTED SETUP PROPERTY
    getSelectedSetupPropertySuccess(state, action) {
      state.selectedSetupProperty = {
        id: action.payload.id,
        name: action.payload.name,
        slug: action.payload.slug,
        setup_completed: action.payload.setup_completed,
        units: action.payload.units,
      };
    },

    startLoadingSSP(state) {
      state.isLoadingSSP = true;
    },

    endLoadingSSP(state) {
      state.isLoadingSSP = false;
    },

    // AVAILABLE SETUP PROPERTIES
    getAvailableSetupPropertiesSuccess(state, action) {
      state.availableSetupProperties = action.payload;
      state.isLoadingASP = false;
    },

    startLoadingASP(state) {
      state.isLoadingASP = true;
    },

    endLoadingASP(state) {
      state.isLoadingASP = false;
    },

    // SETUP
    setInitialStep(state, action) {
      state.initialStep = getInitialStep(action.payload);
    },

    setInitialSetupState(state, action) {
      state.setup = action.payload;
    },

    updateSetupState(state, action) {
      state.setup = { ...state.setup, ...action.payload };
    },

    // UNITS STEP
    setUnitsStep(state, action) {
      state.unitsStep = action.payload;
      state.isAnalyzingUnits = false;
    },

    startAnalyzingUnits(state) {
      state.isAnalyzingUnits = true;
    },

    unitStepNeedPost(state, action) {
      state.unitsStep.needPost = action.payload;
    },

    resetUnitsStep(state) {
      state.unitsStep = {
        ready: false,
        file: null,
        fileName: '',
        fileSize: null,
        data: [],
        errors: {},
        hasErrors: false,
        needPost: false,
      };
      state.isAnalyzingUnits = false;
    },

    // RENT ROLL STEP
    setRentRollStep(state, action) {
      state.rentRollStep = action.payload;
      state.isAnalyzingRentRoll = false;
    },

    startAnalyzingRentRoll(state) {
      state.isAnalyzingRentRoll = true;
    },

    rentRollStepNeedPost(state, action) {
      state.rentRollStep.needPost = action.payload;
    },

    resetRentRollStep(state) {
      state.rentRollStep = {
        ready: false,
        file: null,
        fileName: '',
        fileSize: null,
        data: [],
        errors: {},
        hasErrors: false,
        needPost: false,
      };
      state.isAnalyzingRentRoll = false;
    },

    // CREATE CERTIFICATION STEP
    setCertificationStep(state, action) {
      state.certificationStep = action.payload;
    },

    // RESET
    resetPropertySetup(state) {
      state.selectedSetupProperty = null;
      state.availableSetupProperties = [];
      state.setup = null;
      state.initialStep = 0;
      state.unitsStep = {
        ready: false,
        file: null,
        fileName: '',
        fileSize: null,
        data: [],
        errors: {},
        hasErrors: false,
        needPost: false,
      };
      state.rentRollStep = {
        ready: false,
        file: null,
        fileName: '',
        fileSize: null,
        data: [],
        errors: {},
        hasErrors: false,
        needPost: false,
      };
      state.certificationStep = {
        name: '',
      };
      state.isLoadingSSP = false;
      state.isLoadingASP = false;
      state.isAnalyzingUnits = false;
      state.isAnalyzingRentRoll = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  updateSetupState,
  setUnitsStep,
  resetUnitsStep,
  unitStepNeedPost,
  startAnalyzingUnits,
  setRentRollStep,
  resetRentRollStep,
  rentRollStepNeedPost,
  startAnalyzingRentRoll,
  setCertificationStep,
  resetPropertySetup,
} = slice.actions;

// ----------------------------------------------------------------------

export function getSelectedSetupProperty(propertySlug, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingSSP());
    try {
      const response = await axios.get(
        `property_portfolio/property/?slug=${propertySlug}&${parseQueryParams(
          queryParams
        )}`
      );
      if (response.status === 200) {
        const data = response.data[0];
        dispatch(slice.actions.getSelectedSetupPropertySuccess(data));
        dispatch(slice.actions.setInitialStep(data.setup));
        dispatch(slice.actions.setInitialSetupState(data.setup));
        if (data.setup?.units_completed && data.setup?.units_file?.file) {
          dispatch(slice.actions.startAnalyzingUnits());
          try {
            const formData = new FormData();
            const unitsFile = data.setup.units_file;
            const blob = await fetch(unitsFile.file).then((r) => r.blob());
            const file = new File([blob], unitsFile.name);
            formData.append('file', file);
            formData.append('property_id', response.id);
            const { data: errors } = await axios.post(
              `pronto_setup/unit_file/validate_unit_file/`,
              formData
            );
            const results = await parseFile(unitsFile.file, true);
            const finalData = results.data.filter((row) =>
              Object.values(row).some((column) => column)
            );
            const hasErrors = validateHasErrors(errors);
            dispatch(
              slice.actions.setUnitsStep({
                ready: true,
                file: unitsFile.file,
                fileName: unitsFile.name,
                fileSize: unitsFile.size,
                data: finalData,
                errors: errors,
                hasErrors: hasErrors,
                needPost: false,
              })
            );
          } catch (error) {
            console.error(error);
            dispatch(slice.actions.resetUnitsStep());
          }
        }
        if (
          data.setup?.rent_roll_completed &&
          data.setup?.rent_roll_file?.file
        ) {
          dispatch(slice.actions.startAnalyzingRentRoll());
          try {
            const formData = new FormData();
            const rentRollFile = data.setup.rent_roll_file;
            const blob = await fetch(rentRollFile.file).then((r) => r.blob());
            const file = new File([blob], rentRollFile.name);
            formData.append('file', file);
            formData.append('property_id', response.id);
            const { data: errors } = await axios.post(
              `pronto_setup/rent_roll_file/validate_rent_roll_file/`,
              formData
            );
            const results = await parseFile(rentRollFile.file, true);
            const finalData = results.data.filter((row) =>
              Object.values(row).some((column) => column)
            );
            const hasErrors = validateHasErrors(errors);
            dispatch(
              slice.actions.setRentRollStep({
                ready: true,
                file: rentRollFile.file,
                fileName: rentRollFile.name,
                fileSize: rentRollFile.size,
                data: finalData,
                errors: errors,
                hasErrors: hasErrors,
                needPost: false,
              })
            );
          } catch (error) {
            console.error(error);
            dispatch(slice.actions.resetRentRollStep());
          }
        }
        if (data.setup?.certification_name) {
          dispatch(
            setCertificationStep({ name: data.setup?.certification_name })
          );
        }
        setTimeout(() => {
          dispatch(slice.actions.endLoadingSSP());
        }, 500);
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.endLoadingSSP());
    }
  };
}

export function getAvailableSetupProperties(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingASP());
    try {
      const response = await axios.get(
        `property_portfolio/property/?${parseQueryParams(queryParams)}`
      );
      if (response.status === 200) {
        dispatch(
          slice.actions.getAvailableSetupPropertiesSuccess(response.data)
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.endLoadingASP());
    }
  };
}
