import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
import parseQueryParams from 'src/utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  household: {},
  householdMemberSectionResponses: {},
  householdMemberSections: {},
  certification: {},
  surveysByCertification: [],
};

const slice = createSlice({
  name: 'Tenant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getHouseholdSuccess(state, action) {
      state.isLoading = false;
      state.household = action.payload;
    },

    getHouseholdMemberSectionResponsesSuccess(state, action) {
      state.isLoading = false;
      if (
        !state.householdMemberSectionResponses[action.payload.householdMemberId]
      ) {
        state.householdMemberSectionResponses[
          action.payload.householdMemberId
        ] = {};
      }

      state.householdMemberSectionResponses[action.payload.householdMemberId][
        action.payload.sectionId
      ] = action.payload.sections;
      state.householdMemberSectionResponses[
        action.payload.householdMemberId
      ].answers = action.payload.answers;
    },

    getHouseholdMemberSurveyResponsesSuccess(state, action) {
      state.isLoading = false;
      state.householdMemberSectionResponses[action.payload.householdMemberId] =
        {};
      state.householdMemberSectionResponses[
        action.payload.householdMemberId
      ].answers = action.payload.answers;
    },

    getHouseholdMemberSectionsSuccess(state, action) {
      state.isLoading = false;
      state.householdMemberSections[action.payload.householdMemberId] =
        action.payload.sections;
    },

    setCertification(state, action) {
      state.certification = action.payload;
    },
    setHousehold(state, action) {
      state.household = action.payload;
    },

    setSurveysByCertification(state, action) {
      state.surveysByCertification = action.payload;
    },

    resetHouseholdMemberState(state) {
      state.isLoading = false;
      state.error = false;
      state.household = {};
      state.householdMemberSectionResponses = {};
      state.householdMemberSections = {};
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setCertification, setHousehold } = slice.actions;

// ----------------------------------------------------------------------

export function resetHousehold() {
  return async (dispatch) =>
    dispatch(slice.actions.resetHouseholdMemberState());
}

export function getHousehold(householdId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `household/all_household/${householdId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getHouseholdSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
