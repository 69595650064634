import axios from 'axios';
import {
  removeTokensMetadata,
  setTokenMetadataInLocalStorage,
  isTokenMetaValid,
} from 'src/utils/jwt';

interface accessPayload {
  data: {
    access: string;
  };
}

let lastTokenUpdate = 0;
let refreshPromise = null;

const THIRTY_SECONDS = 30 * 1000;

const shouldUpdateToken = (): boolean => {
  return lastTokenUpdate + THIRTY_SECONDS < Date.now();
};

const refreshAccessToken = (): void => {
  lastTokenUpdate = Date.now();
  refreshPromise = axios.post('token/access/', null, {
    baseURL: process.env.REACT_APP_API_ROOT || 'http://localhost:8000/api/',
    withCredentials: true,
  });

  refreshPromise
    .then((result: accessPayload) => {
      setTokenMetadataInLocalStorage(result.data.access, 'accessTokenMetadata');
    })
    .catch((error: Promise<never>) => {
      removeTokensMetadata();
      return Promise.reject(error);
    });
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT || 'http://localhost:8000/api/',
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      (error?.response?.status === 401 || error?.response?.status === 400) &&
      error?.response?.config?.url === 'token/access/'
    ) {
      sessionStorage.clear();
      removeTokensMetadata();
    }

    if (
      error?.response?.status === 401 &&
      error?.response?.config?.url !== 'token/both/?validate' &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        // Redundancy system, in case the first filter fails to update the token
        if (shouldUpdateToken()) {
          refreshAccessToken();
        }
        await refreshPromise;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    let newError = error.message || 'Something went wrong';

    if (error.response && error.response.data) {
      newError = { ...error.response.data, status: error?.response?.status };
    }

    return Promise.reject(newError);
  }
);

axiosInstance.interceptors.request.use(
  async function (config) {
    // Start session whenever a request is called
    const accessMetadata = window.localStorage.getItem('accessTokenMetadata');
    const accessInvalid =
      accessMetadata && !isTokenMetaValid('accessTokenMetadata');
    if (accessInvalid && shouldUpdateToken()) {
      refreshAccessToken();
    }
    if (refreshPromise) {
      await refreshPromise;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
