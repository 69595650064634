import PropTypes from 'prop-types';

export const TYPES = {
  questionnaire: 'questionnaire',
  section: 'section',
  page: 'page',
  question: 'question',
  questionGroup: 'question_group',
  questionChoice: 'question_choice',
  contentType: 'content_type',
  miscellaneous: 'miscellaneous',
  ticBucket: 'ticBucket',
  signature: 'signature',
  document: 'document',
  mit: 'mit',
  sit: 'sit',
  sitDate: 'sitDate',
  sitName: 'sitName',
  sitUnassigned: 'sitUnassigned',
  sitUnsaved: 'sitUnsaved',
  questionContext: 'questionContext',
  calculation: 'calculation',
};

export const eSignInstanceConfig = {
  licenseKey: process.env.REACT_APP_PDF_LICENSE_KEY,
  path: '/webviewer/lib',
  css: '/pdf.css' /* https://pdfjs.community/t/extra-space-appears-below-toolbar-in-small-screen-size/966 */,
};

export const DISABLED_ELEMENTS = [
  'linkButton',
  'documentControl',
  'outlinesPanelButton',
  'notesPanelButton',
  'notesPanel',
  'outlinesPanel',
  'freeHandToolGroupButton',
  'textToolGroupButton',
  'shapeToolGroupButton',
  'eraserToolButton',
  'stickyToolButton',
  'miscToolGroupButton',
  'signatureToolButton',
  'freeTextToolButton',
  'textHighlightToolButton',
  'textUnderlineToolButton',
  'textSquigglyToolButton',
  'textStrikeoutToolButton',
  'textPopup',
  'contextMenuPopup',
  'richTextItalicButton',
  'richTextUnderlineButton',
  'richTextColorPalette',
  'richTextBoldButton',
  'richTextStrikeButton',
  'toolsHeader',
  'ribbonsDropdown',
  'toggleNotesButton',
  'annotationStyleEditButton',
  'highlightToolGroupButton',
  'ribbons',
  'undoButton',
  'redoButton',
  'toolsOverlay',
  'underlineToolGroupButton',
  'strikeoutToolGroupButton',
  'squigglyToolGroupButton',
  'stickyToolGroupButton',
  'freeTextToolGroupButton',
  'freeHandHighlightToolGroupButton',
  'printButton',
  'downloadButton',
  'thumbRotateClockwise',
  'thumbDelete',
  'pageManipulationOverlayButton',
];

const { any, arrayOf, bool, number, shape, string } = PropTypes;

export const questionShape = shape({
  html: string,
  id: number,
  isRequired: bool,
  name: string,
  order: number,
  page: number,
  parent_question: any,
  question_file_example: arrayOf(any),
  title: string,
  type: shape({
    autoComplete: string,
    choices: string,
    colCount: any,
    dateFormat: string,
    id: number,
    inputFormat: string,
    inputMask: string,
    inputType: string,
    mappable: bool,
    maxSize: number,
    panelAddText: string,
    panelRemoveText: string,
    showPreview: any,
    startWithNewLine: any,
    storeDataAsText: any,
    type: string,
  }),
  validators: arrayOf(any),
  visibleIf: any,
  visibleIfNot: any,
});

export const pageShape = shape({
  content_type_id: number,
  id: number,
  name: string,
  order: any,
  questions: arrayOf(questionShape),
  section: number,
});

export const sectionShape = shape({
  content_type_id: number,
  id: number,
  name: string,
  order: any,
  pages: arrayOf(pageShape),
});

export const DEFAULT_SECTION = {
  name: '',
  help_text: '',
  id: '',
  notSavedSection: true,
  wasCreated: false,
  firstQitOrder: 0,
  lastQitOrder: 0,
};

export const DEFAULT_PAGE = {
  name: '',
  help_text: '',
  id: '',
  notSaved: true,
  wasCreated: false,
  firstQitOrder: 0,
  lastQitOrder: 0,
};

export const DEFAULT_GROUP = {
  id: null,
  type: 'single',
  name: '',
  age_min: '',
  age_max: '',
  help_text: '',
  question: null,
  question_choice: null,
  householdmember_type: [],
  shouldOpen: true,
  firstQitOrder: 0,
  lastQitOrder: 0,
};

export const DEFAULT_QUESTION = {
  qit: null,
  name: '',
  help_text: '',
  id: '',
  necessity: '3',
  number_of_answers_required: 1,
  for_compliance: false,
  question_context: null,
  number_of_files_required_per_answer: '',
  number_of_pages_required_per_file: '',
};

export const QUESTIONS_WITH_CHOICES_MAPPABLE = ['frequency'];

export const QUESTIONS_TYPES_WITHOUT_CHOICES_TO_RENDER = ['ssn'];

const symbolRenderType = {
  id: 'SymbolRender',
  displayName: 'Symbol (if the value is not None, render a symbol)',
};
export const MISC_TIC_BUCKET_RENDER_TYPES = {
  currency: [
    {
      id: 'CurrencyNSBlankRender',
      displayName: 'Without $ and blank for empty values',
    },
    {
      id: 'CurrencySBlankRender',
      displayName: 'With $ and blank for empty values',
    },
    {
      id: 'CurrencyNSZeroRender',
      displayName: 'Without $ and 0 for empty values',
    },
    {
      id: 'CurrencySZeroRender',
      displayName: 'With $ and 0 for empty values',
    },
    symbolRenderType,
  ],
  number: [
    {
      id: 'NumberBlankRender',
      displayName: 'Whole number and blank for empty values',
    },
    {
      id: 'NumberZeroRender',
      displayName: 'Whole number and 0 for empty values',
    },
    symbolRenderType,
  ],
  // frequency: [],
  percentage: [
    {
      id: 'FloatBlankRender',
      displayName: 'Float and blank for empty values',
    },
    {
      id: 'FloatSBlankRender',
      displayName: 'Float with % sign and blank for empty values',
    },
    {
      id: 'FloatZeroRender',
      displayName: 'Float and 0 for empty values',
    },
    {
      id: 'FloatSZeroRender',
      displayName: 'Float with % and 0 for empty values',
    },
    symbolRenderType,
  ],
};

export const MISC_TIC_HAS_ANSWERS_RENDER_TYPES = [
  {
    id: 'TextRender',
    displayName: 'Text',
    description:
      'The value of this answer will be placed in the PDF as a normal text value with no modifications.',
  },
  {
    id: 'SymbolRender',
    displayName: 'Symbol',
    description: 'Symbol (if the value is not None, render a symbol)',
  },
];

export const MISC_TIC_MATCH_ANSWERS_RENDER_TYPES = [
  {
    id: 'TextRender',
    displayName: 'Text',
    description:
      'The value of this answer will be placed in the PDF as a normal text value with no modifications.',
  },
  {
    id: 'SymbolRender',
    displayName: 'Symbol',
    description: 'Symbol (if the value is not None, render a symbol)',
  },
];

export const MISC_TIC_BUCKET_HHM_OPTIONS = [
  {
    val: 'RENDER_ONE_PER_PAGE',
    text: 'Render a single value for each hhm, only on forms that generate for that hhm.',
  },
  {
    val: 'RENDER_ALL_ONE_PAGE',
    text: 'Render as a list of values, each representing a hhm, in a single form',
  },
];

export const RENDER_TYPES = {
  TextRender: {
    id: 'TextRender',
    displayName: 'Text',
    description:
      'The value of this answer will be placed in the PDF as a normal text value with no modifications.',
  },
  InitialsRender: {
    id: 'InitialsRender',
    displayName: 'Only Initials',
    description:
      'The value of this context will render the first letter of each answer.',
  },
  SymbolRender: {
    displayName: 'Symbol',
    description:
      'The value of this answer will not be used, instead an X will be placed in the PDF as text.',
  },
  HiddenSSNRender: {
    displayName: 'Hidden SSN Render',
    description:
      'The value for this answer will be hide allowing only the last 4 digits (***-**-9999).',
  },
  ListRender: {
    id: 'ListRender',
    displayName: 'List of answers',
    description:
      'The answers will be organized into a simple list, with one answer as text on each line. Answers will be sorted alphabetically.',
  },
  NumberedListRender: {
    id: 'NumberedListRender',
    displayName: 'Numbered list of answers',
    description:
      'The answers will be organized into a numbered list, with one answer as text on each line. Answers will be sorted alphabetically.',
  },
  DateRender: {
    displayName: 'Date',
    description:
      'The answer will be formatted into a date and then placed in the PDF as text.',
  },
  JoinedStringRender: {
    id: 'JoinedStringRender',
    displayName: 'Text List of Answers',
    description:
      'The answers will be organized into a simple list, with one answer as text followed by a comma and then the next answer. Answers will be sorted alphabetically.',
  },
  Calculation: {
    id: 'Calculation',
    displayName: 'Calculation',
    description: 'Calculation rendert type.',
  },
  SUM: {
    id: 'SUM',
    displayName: 'SUM',
    description: 'Sums all the values of the selected house hold members.',
  },
  AVG: {
    id: 'AVG',
    displayName: 'AVG',
    description: 'Average value of the selected house hold members.',
  },
  WithMoneySignRender: {
    id: 'WithMoneySignRender',
    displayName: 'With Money Sign',
    description:
      'The value of this answer will be placed in the PDF as a currency with money sign (Dollar sign).',
  },
  WithoutMoneySignRender: {
    id: 'WithoutMoneySignRender',
    displayName: 'Without Money Sign',
    description:
      'The value of this answer will be placed in the PDF as a currency without money sign (Dollar sign).',
  },
};

export const GROUP_RENDER_TYPES = {
  RENDER_ONE_PER_PAGE: {
    id: 'RENDER_ONE_PER_PAGE',
    displayName: 'Render One page Per Answer Group',
    description: `This option would allow mapping of all questions in question group
    using their existing question type options to map. One generation, one populated page would appear per
    answer group.`,
  },
  RENDER_ALL_ONE_PAGE: {
    id: 'RENDER_ALL_ONE_PAGE',
    displayName: 'Render Multiple Answer Groups in a Single Page',
    description: `This option would allow mapping of multiple answer groups
    on a single rendered page. Since you are mapping more than one thing, these values will always rendered as a list
    of the mapped question type and result in one generated page.`,
  },
};

export const CONTENT_TYPES = {
  certificationconfig: {
    fields: ['attr_'],
    name: 'Attributes',
    endpoint: 'certification/certification_config/',
  },
  user: {
    fields: ['email'],
    name: 'User',
    endpoint: 'user_profile/user/',
  },
  certification: {
    fields: [
      'current_date',
      'effective_date',
      'log_number',
      'lease_start',
      'lease_end',
      'move_in_date',
      'move_out_date',
    ],
    name: 'Certification',
    endpoint: 'certification/certification/',
  },
  householdmembertype: {
    fields: ['display_name'],
    name: 'Household Member Type',
    endpoint: 'household/household_member_type/',
  },
  householdmember: {
    fields: [
      'first_name',
      'middle_name',
      'last_name',
      'email_addresses',
      'mobile_phones',
    ],
    name: 'Household Member',
    endpoint: 'household/household_member/',
  },
  household: {
    fields: ['preference_groups', 'household_size'],
    name: 'Household',
    endpoint: 'household/all_household/',
  },
  unit: {
    fields: ['rent', 'ami', 'name', 'unit_type', 'unit_square_feet'],
    name: 'Unit',
    endpoint: 'property_portfolio/units/',
  },
  building: {
    fields: [
      'address',
      'affordable_square_feet',
      'total_square_feet',
      'city',
      'building_identification',
      'type_of_building',
      'state',
      'zip_code',
      'county',
    ],
    name: 'Building',
    endpoint: 'property_portfolio/buildings/',
  },
  property: {
    fields: [
      'contact_name',
      'set_aside',
      'contact_phone_number',
      'fax_number',
      'name',
      'property_email',
      'form_fill_color',
    ],
    name: 'Property',
    endpoint: 'property_portfolio/property/',
  },
  propertyownercompany: {
    fields: ['name'],
    name: 'Company',
    endpoint: 'property_portfolio/property_owner_company/',
  },
  ticbucketcalculation: {
    fields: ['name', 'formula'],
    name: 'Bucket Calculation',
    endpoint: 'tic_flex/tic_bucket/',
  },
  tichasanswers: {
    fields: ['name', 'formula'],
    name: 'TIC Has Answers',
    endpoint: 'tic_flex/tic_has_answers/',
  },
  ticmatchanswers: {
    fields: ['name', 'formula'],
    name: 'TIC Match Answers',
    endpoint: 'tic_flex/tic_match_answers/',
  },
};
