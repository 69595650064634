// routes
import { COMPLIANCE_PATHS } from '../../routes/paths';
// icons
import outlineFactCheck from '@iconify/icons-ic/outline-fact-check';
import { Icon } from '@iconify/react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
// redux
import React from 'react';
import { setResetPropertyPage } from '../../redux/slices/Compliance/Property';
import { store } from '../../redux/store';
// ----------------------------------------------------------------------

const complianceSidebarConfig = [
  {
    subheader: 'My Workbench',
    needsDashboard: true,
    items: [
      {
        title: 'Specialist Summary',
        path: COMPLIANCE_PATHS.user.complianceSpecialistDashboard,
        icon: <GridViewOutlinedIcon />,
        complianceManager: false,
        complianceSpecialist: true,
      },
      {
        title: 'My Renters',
        path: COMPLIANCE_PATHS.applicants.myApplicants,
        icon: <AssignmentIcon />,
        complianceManager: false,
        complianceSpecialist: true,
      },
      {
        title: 'Communication',
        path: COMPLIANCE_PATHS.user.communications,
        icon: <ContactMailOutlinedIcon />,
        complianceManager: false,
        complianceSpecialist: true,
      },
      {
        title: 'Bulk Communications',
        path: COMPLIANCE_PATHS.user.bulkCommunications,
        icon: <CampaignIcon />,
        complianceManager: true,
        complianceSpecialist: true,
      },
      {
        title: 'Team Renters',
        path: COMPLIANCE_PATHS.applicants.teamApplicants,
        icon: <FamilyRestroomIcon />,
        complianceManager: true,
        complianceSpecialist: false,
      },
      {
        title: 'Team Tasks',
        path: COMPLIANCE_PATHS.user.teamTasks,
        icon: <GroupsIcon />,
        complianceManager: true,
        complianceSpecialist: true,
      },
    ],
  },
  {
    subheader: 'Property Workbench',
    needsDashboard: false,
    items: [
      {
        title: 'Property Home',
        path: COMPLIANCE_PATHS.general.property,
        icon: <DashboardOutlinedIcon />,
        onClick: () => {
          store.dispatch(setResetPropertyPage(true));
        },
        compliance: true,
      },
      {
        title: 'Certification Workbench',
        path: COMPLIANCE_PATHS.general.propertyCertWorkbench,
        icon: <Icon icon={outlineFactCheck} width={20} height={20} />,
        certificationW: true,
      },
      {
        title: 'Create Questionnaire',
        path: COMPLIANCE_PATHS.createQuestionnaire,
        icon: <PlaylistAddIcon />,
        compliance: true,
      },
    ],
  },
];

export default complianceSidebarConfig;
