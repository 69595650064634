import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { Box, Card, Collapse, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getFeatureFlags } from 'src/redux/slices/FeatureFlags';
import { AppDispatch } from 'src/redux/store';

const TIMEOUT_MINUTES = 3;
const TIME_INTERVAL = TIMEOUT_MINUTES * 60 * 1000;

interface MetaJSON {
  buildDate: string;
}

const NewVersionValidation: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [initialURL, setInitialURL] = useState<string | null>(null);
  const [interceptNavigation, setInterceptNavigation] = useState(false);
  const [storedVersion, setStoredVersion] = useState<number | null>(
    +localStorage.getItem('app_version') || null
  );

  const dispatch = useDispatch<AppDispatch>();
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const getMetaJSON = async (): Promise<MetaJSON> => {
    const res = await fetch('/meta.json');
    return await res.json();
  };

  const pingVersion = useCallback(async (): Promise<number> => {
    try {
      const { buildDate } = await getMetaJSON();
      return +buildDate;
    } catch (error) {
      console.error(error);
      return -1;
    }
  }, []);

  const validateVersion = useCallback(async () => {
    const buildDate = await pingVersion();
    if (buildDate === -1) {
      return;
    }
    // First time use of the application or cache cleared.
    if (!storedVersion) {
      localStorage.setItem('app_version', buildDate.toString());
      return;
    }

    if (storedVersion !== buildDate) {
      setVisible(true);
      setStoredVersion(buildDate);
      localStorage.setItem('app_version', buildDate.toString());

      setInterceptNavigation(true);
      setInitialURL(window.location.pathname);
    }
  }, [pingVersion, storedVersion]);

  useEffect(() => {
    if (!interceptNavigation) {
      return;
    }
    if (location.pathname !== initialURL) {
      window.location.reload();
    }
  }, [initialURL, interceptNavigation, location]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    validateVersion().then(() => {
      interval.current = setInterval(() => {
        void validateVersion();
        dispatch(getFeatureFlags());
      }, TIME_INTERVAL);
    });

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [isAuthenticated, validateVersion, dispatch]);

  return (
    <Box
      sx={{
        maxHeight: !visible ? 0 : 'fit-content',
        width: '100%',
        transition: 'max-height 0.5s ease',
        zIndex: 9999,
        position: 'fixed',
      }}
    >
      <Stack>
        <Collapse in={visible} unmountOnExit>
          <Card
            className={'info-card'}
            sx={{
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Stack flexDirection={'row'} gap={'10px'}>
              <InfoIcon
                sx={{ color: '#1890FF', width: '20px', height: '20px' }}
              />
              <Typography variant={'body1'} fontSize="14px">
                {"There's a new version of the app!"}
              </Typography>
              <Typography
                variant={'body1'}
                fontSize="12px"
                color={'#1890FF'}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  setShowDetails(!showDetails);
                }}
              >
                {'Tell me more'}
              </Typography>
              <CloseIcon
                sx={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                  ml: '30px',
                }}
                onClick={() => setVisible(false)}
              />
            </Stack>
            <Collapse in={showDetails}>
              <Typography variant={'body1'} fontSize="12px">
                We will automatically reload this page when you navigate to
                another section. You can also manually refresh the page to get
                the latest version.
              </Typography>
            </Collapse>
          </Card>
        </Collapse>
      </Stack>
    </Box>
  );
};

export default NewVersionValidation;
