import React, { FC, ReactNode } from 'react';
import AGDropdownSelector from './AGDropdownSelector';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/redux/rootReducerType';
import { ComplianceUser } from 'src/types/Certification';
import parseQueryParams from 'src/utils/query';
import { updateComplianceUser } from 'src/redux/slices/RentersDashboard/RentersDashboardSlice';
import MenuItem from '@mui/material/MenuItem';

export interface AGFileOwnerProps {
  certificationId: number;
  complianceUserId: number;
}

const AGFileOwner: FC<AGFileOwnerProps> = ({
  certificationId,
  complianceUserId,
}) => {
  const complianceUsers = useSelector(
    (state: RootState) => state.rentersDashboard.complianceUsers,
    shallowEqual
  );

  const complianceUserOnChange = (
    dispatch,
    certificationId,
    value
  ): Promise<void> => {
    const queryParams = parseQueryParams({
      fields: ['compliance_user.id'],
    });
    const payload = { compliance_user: value || null };
    return dispatch(
      updateComplianceUser({
        certificationId,
        payload,
        params: queryParams,
      })
    );
  };

  const complianceUserOptionMapper = (option: ComplianceUser): ReactNode => (
    <MenuItem
      sx={{ fontSize: 13 }}
      key={`${certificationId}-compliance-user`}
      value={option.id}
    >
      {`${option.first_name} ${option.last_name}`}
    </MenuItem>
  );

  return (
    <AGDropdownSelector
      allowNullValues
      certificationId={certificationId}
      initialValue={complianceUserId}
      onValueChange={complianceUserOnChange}
      options={complianceUsers}
      optionMapper={complianceUserOptionMapper}
    />
  );
};

export default AGFileOwner;
