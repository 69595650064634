import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { AppBar, IconButton, Stack, Toolbar } from '@mui/material';
// icons
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// components
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  pointerEvents: 'none',
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  pointerEvents: 'none',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

DashboardNavbar.defaultProps = {
  onOpenSidebar: () => null,
};

export default function DashboardNavbar({ onOpenSidebar, isResident }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              mr: 1,
              color: 'text.primary',
              pointerEvents: 'initial',
              backgroundColor: 'white !important',
            }}
            size="large"
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        {isResident && (
          <Stack
            direction="row"
            spacing={{ xs: 0.5, sm: 1.5 }}
            sx={{ pointerEvents: 'initial', ml: 'auto' }}
          >
            <AccountPopover isResident />
          </Stack>
        )}
      </ToolbarStyle>
    </RootStyle>
  );
}
