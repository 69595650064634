import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function useCountdown() {
  let id = 0;
  const d = new Date(Date.now() + 1000 * 60);
  const [countdown, setCountdown] = useState({
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    // eslint-disable-next-line
    id = setInterval(() => setNewTime(), 1000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewTime = (a = null) => {
    const startTime = a || d;
    const endTime = new Date();
    const distanceToNow = startTime - endTime;

    const getMinutes = `0${Math.floor(
      (distanceToNow % (1000 * 60 * 60)) / (1000 * 60)
    )}`.slice(-2);
    const getSeconds = `0${Math.floor(
      (distanceToNow % (1000 * 60)) / 1000
    )}`.slice(-2);

    if (getSeconds <= 0 && getMinutes <= 0) {
      pause();
    }
    setCountdown({
      minutes: getMinutes || '000',
      seconds: getSeconds || '000',
    });
  };

  const reset = () => {
    clearInterval(id);
    const a = new Date(Date.now() + 1000 * 60);
    id = setInterval(() => setNewTime(a), 1000);
  };

  const pause = () => {
    clearInterval(id);
  };

  const countR = {
    countdown,
    reset,
    pause,
  };

  return countR;
}

// Usage
// const countdown = useCountdown(new Date('07/07/2022 21:30'));
