import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  ChangeEvent,
  ForwardRefRenderFunction,
} from 'react';

export interface TermsOfServiceDialogV2Props {
  onCancel?: () => void;
  onAccept: () => Promise<void>;
}

export interface TermsOfServiceDialogV2Ref {
  toggleOpenDialog: () => void;
}

const TermsOfServiceDialogV2: ForwardRefRenderFunction<
  TermsOfServiceDialogV2Ref,
  TermsOfServiceDialogV2Props
> = ({ onCancel, onAccept }, ref) => {
  const [openTermsOfService, setOpenTermsOfService] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [requestErrors, setRequestErrors] = useState(null);

  const handleAccept = async (): Promise<void> => {
    try {
      setLoading(true);
      await onAccept();
      closeDialog();
    } catch (error) {
      setRequestErrors('Something went wrong.');
    }
  };

  const toggleCheckbox = (event: ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
  };

  const toggleOpenDialog = (): void => {
    setOpenTermsOfService(!openTermsOfService);
  };

  const closeDialog = (): void => {
    localStorage.removeItem('temporalSession');
    toggleOpenDialog();
    setChecked(false);
    setLoading(false);
  };

  const handleCancel = (): void => {
    if (!loading) {
      if (onCancel) {
        onCancel();
      }
      closeDialog();
    }
  };

  useImperativeHandle(ref, () => ({
    toggleOpenDialog,
  }));

  return (
    <Dialog open={openTermsOfService} maxWidth="md" onClose={handleCancel}>
      <DialogTitle>Terms Of Service</DialogTitle>
      <DialogContent>
        {requestErrors && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {requestErrors}
          </Alert>
        )}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={toggleCheckbox}
                disabled={loading}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <>
                <span>I have read and agree to the </span>
                <a
                  href="https://www.prontohousing.com/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms Of Service
                </a>
                <span> and </span>
                <a
                  href="https://www.prontohousing.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                <span>.</span>
              </>
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!checked}
          onClick={handleAccept}
        >
          Accept
        </LoadingButton>
        <Button color="inherit" disabled={loading} onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(TermsOfServiceDialogV2);
