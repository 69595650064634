import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import animationData from './confeti-animation_badge';
import { updateAchievementsOptions } from 'src/redux/slices/Main/main';

const AchievementModal = () => {
  const d = useDispatch();
  const { visible, data } = useSelector((st) => st?.main?.achievements);
  const [paused, setPaused] = useState(true);
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
  };

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setPaused(false);
      }, 1500);
    }
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <Dialog maxWidth="lg" fullWidth open={visible}>
      <DialogContent sx={{ p: '0 !important', overflow: 'hidden' }}>
        <Box
          sx={{
            minWidth: '100%',
            position: 'relative',
            maxHeight: '90vh',
            minHeight: 500,
            py: 4,
            background:
              'url(/static/achievements/conffeti.svg) no-repeat top center/contain',
          }}
        >
          <Box
            sx={{ mx: 'auto', maxHeight: '9vh', mb: '3vh' }}
            component="img"
            src={'/static/achievements/badge.svg'}
            alt="Badge"
          />

          <Typography
            sx={{ fontSize: '4.5vh', fontWeight: 700 }}
            align="center"
          >
            All done!
          </Typography>

          <Typography
            sx={{
              fontSize: '1.8vh',
              color: 'text.secondary',
              opacity: 0.7,
              mb: '2vh',
            }}
            align="center"
          >
            You have earned a new badge: <strong>{data?.label}</strong>
          </Typography>

          <Box
            sx={{ mx: 'auto', maxHeight: '32vh' }}
            component="img"
            src={data?.image || '/static/achievements/illustration.svg'}
            alt="Achievements illustration"
          />

          <Box
            sx={{
              textAlign: 'center',
              mt: '2vh',
              position: 'relative',
              zIndex: 2,
            }}
          >
            <Button
              size="medium"
              variant="contained"
              sx={{ width: '15vh' }}
              color="primary"
              onClick={() => {
                d(updateAchievementsOptions({ visible: false, data: null }));
              }}
            >
              Close
            </Button>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              width: '100%',
              transform: 'translateY(-50%)',
            }}
          >
            <Lottie options={defaultOptions} isPaused={paused} height="100%" />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(AchievementModal);
