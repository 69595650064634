import { Box, Tooltip } from '@mui/material';
import Label from '../Label';
import { Icon } from '@iconify/react';
import building16Filled from '@iconify/icons-fluent/building-16-filled';
import React from 'react';

export default function BNLabel({ bn, small, cover, table }) {
  return (
    <Tooltip title={'Building Number'} arrow placement={'top'}>
      <Box ml={table ? 0 : 1}>
        <Label variant={cover ? 'filled' : 'ghost'}>
          <Icon
            style={!small ? { width: 15, height: 15 } : {}}
            icon={building16Filled}
          />
          &nbsp; {bn}
        </Label>
      </Box>
    </Tooltip>
  );
}
