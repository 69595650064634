import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
} from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import {
  alpha,
  experimentalStyled as styled,
  useTheme,
} from '@mui/material/styles';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import PropertyDropdown from './Compliance/PropertyDropdown';
import useAuth from 'src/hooks/useAuth';
// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}));

const ListEmptyHeaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.disabled,
}));

const ListItemStyle = styled((props) => (
  <ListItem button disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, onClick }) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const isCompliance = pathname.includes('/workbench');

  const { selectedProperty } = useSelector((state) => state.property);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '&:before': { display: 'block' },
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  onClick={onClick}
                  to={
                    isCompliance && selectedProperty
                      ? path.replace(':property_slug', selectedProperty.slug)
                      : path
                  }
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'text.disabled',
                        transition: (theme) =>
                          theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          backgroundColor: 'primary.main',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText
                    disableTypography
                    primary={title}
                    sx={{ textTransform: 'none' }}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      onClick={onClick}
      to={
        isCompliance && selectedProperty
          ? path.replace(':property_slug', selectedProperty.slug)
          : path
      }
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText
        disableTypography
        primary={title}
        sx={{ textTransform: title === 'eSign' && 'none' }}
      />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
  emptyMessage: PropTypes.string,
};

NavSection.defaultProps = {
  emptyMessage: 'No options available.',
};

export default function NavSection({
  navConfig,
  emptyMessage,
  onClose,
  ...other
}) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const match = (path) =>
    path ? !!matchPath({ path, end: true }, pathname) : false;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { selectedProperty } = useSelector((state) => state.property);
  const isWorkbench = pathname.startsWith('/workbench');

  return (
    <Box {...other}>
      {user.isCompliance && isWorkbench && (
        <Box sx={{ mb: 2, mx: 2.5 }}>
          <PropertyDropdown />
        </Box>
      )}

      {navConfig.length > 0 ? (
        navConfig.map((list) => {
          const { subheader, items } = list;
          if (list.needsDashboard && !user.hasDashboard) {
            return null;
          }

          return (
            <List key={subheader} disablePadding>
              {!(
                subheader === 'Property Workbench' && !selectedProperty?.id
              ) && (
                <>
                  <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
                  {items.map((item) => {
                    const isBulkCommunication =
                      item.title === 'Bulk Communications';
                    const willShowBulkCommunications = user.groups.some(
                      (group) => group.name === 'Bulk Communications'
                    );
                    const willShowManager =
                      user.isComplianceManager && item.complianceManager;
                    const willShowSpecialist =
                      user.isComplianceSpecialist && item.complianceSpecialist;
                    const willShowWorkbench =
                      user.isCompliance && item.compliance;
                    const willShowAdmin = user.isProntoAdmin && item.compliance;
                    const willShowTenant = !user.isCompliance && item.tenant;
                    const willShowCertWorkbench =
                      user.isCertificationWorkbench && item.certificationW;
                    const willShow = isBulkCommunication
                      ? willShowBulkCommunications
                      : willShowManager ||
                        willShowSpecialist ||
                        willShowWorkbench ||
                        willShowAdmin ||
                        willShowTenant ||
                        willShowCertWorkbench;
                    if (willShow) {
                      return (
                        <NavItem
                          key={item.title}
                          item={item}
                          active={match}
                          onClick={() => {
                            if (item.onClick) {
                              item.onClick();
                            }
                            if (!isDesktop) {
                              onClose();
                            }
                          }}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
            </List>
          );
        })
      ) : (
        <List key={'no-items'} disablePadding>
          <ListEmptyHeaderStyle>{emptyMessage}</ListEmptyHeaderStyle>
        </List>
      )}
    </Box>
  );
}
