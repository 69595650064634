export const environmentsWithDatadog = ['production', 'demo'];

export const getEnvironmentName = (): string => {
  let name = process.env.NODE_ENV;
  if (window?.location?.hostname === 'prontohousing.io') {
    name = 'production';
  }
  if (window?.location?.hostname === 'stage.prontohousing.io') {
    name = 'stage';
  }
  if (window?.location?.hostname === 'dev.prontohousing.io') {
    name = 'dev';
  }
  if (window?.location?.hostname === 'demo.prontohousing.io') {
    name = 'demo';
  }
  return name;
};
