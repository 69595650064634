import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

interface FeatureFlagsState {
  loading: boolean;
  flags: Record<string, boolean>;
  error: string | null;
}

const initialState: FeatureFlagsState = {
  loading: false,
  flags: {},
  error: null,
};

interface FeatureFlagsResponse {
  [key: string]: boolean;
}

export const getFeatureFlags = createAsyncThunk<FeatureFlagsResponse, void>(
  'featuresFlags/getFeatureFlags',
  async () => {
    const res = await axios.get('/feature-flags/');
    return res.data;
  }
);

const slice = createSlice({
  name: 'featuresFlags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFeatureFlags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getFeatureFlags.fulfilled,
        (state, action: PayloadAction<FeatureFlagsResponse>) => {
          state.loading = false;
          state.flags = action.payload;
          state.error = null;
        }
      )
      .addCase(getFeatureFlags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to load feature flags';
      });
  },
});

export default slice.reducer;
