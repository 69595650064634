import axios from 'src/utils/axios';
import { AxiosResponse } from 'axios';

export default class RentersDashboardService {
  public static fetchRentersCertifications = async (
    params: any
  ): Promise<AxiosResponse> => {
    return axios.get(`/renters_dashboard/${params}`);
  };
  public static fetchRentersCertificationsFilters = async (
    params: any
  ): Promise<AxiosResponse> => {
    return axios.get(`/renters_dashboard_filters/${params}`);
  };
}
