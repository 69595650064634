import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingBubble: false,
  error: false,
  property: {},
  propertyList: [],
  selectedProperty: {},
  availableProperties: {},
  unitList: [],
  isUnitListReady: false,
  applicantsList: [],
  isApplicantListReady: false,
  setAsidesList: [],
  householdMembersList: [],
  resetPropertyPage: false,
  noAvailableProperties: false,
  tableFilters: {},
};

const slice = createSlice({
  name: 'Property',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.noAvailableProperties = false;
    },

    updatePropertiesListItem(state, action) {
      const { propertySlug, value = {} } = action.payload;
      const updatedAvailableProperties = Array.isArray(
        state?.availableProperties
      )
        ? [...state.availableProperties]
        : [];
      const propertyBySlugIndex = updatedAvailableProperties.findIndex(
        (prop) => prop?.slug === propertySlug
      );

      if (propertyBySlugIndex >= 0) {
        updatedAvailableProperties[propertyBySlugIndex] = {
          ...updatedAvailableProperties[propertyBySlugIndex],
          ...value,
        };
        state.availableProperties = updatedAvailableProperties;
      }
    },

    updatePropertyField(state, action) {
      state.selectedProperty = { ...state.selectedProperty, ...action.payload };
    },

    setResetPropertyPage(state, action) {
      state.resetPropertyPage = action.payload;
    },

    setApplicantNotes(state, action) {
      state.applicantsList = action.payload;
    },

    setUnitsNotes(state, action) {
      state.isLoading = false;
      state.isUnitListReady = true;
      state.unitList = action.payload;
    },

    // START LOADING
    startLoadingBubble(state) {
      state.isLoadingBubble = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Single property
    getPropertySuccess(state, action) {
      state.isLoading = false;
      state.property = action.payload;
    },

    // GET Single property
    addPropertySuccess(state, action) {
      state.isLoading = false;
      state.property = action.payload;
    },

    // GET Property list
    getPropertyListSuccess(state, action) {
      state.isLoading = false;
      state.propertyList = action.payload;
    },

    // GET set aside list
    getSetAsideListSuccess(state, action) {
      state.isLoading = false;
      state.setAsideList = action.payload;
    },

    // GET Available Properties for sidebar dropdown
    getAvailablePropertiesSuccess(state, action) {
      state.isLoading = false;
      state.availableProperties = action.payload;
      if (action?.payload?.length === 0) {
        state.noAvailableProperties = true;
      }
    },

    // GET selectedProperty
    getSelectedPropertySuccess(state, action) {
      state.isLoading = false;
      state.selectedProperty = action.payload;
    },

    // GET Units
    getUnitListSuccess(state, action) {
      state.isLoading = false;
      state.isUnitListReady = true;
      state.unitList = action.payload;
    },

    resetUnitList(state) {
      state.isUnitListReady = false;
      state.unitList = [];
    },

    // GET Applicants
    getApplicantsListSuccess(state, action) {
      state.isLoading = false;
      state.isApplicantListReady = true;
      state.applicantsList = action.payload;
    },

    resetApplicantsList(state) {
      state.isApplicantListReady = false;
      state.applicantsList = [];
    },

    getHouseholdMembersListSuccess(state, action) {
      state.isLoadingBubble = false;
      state.householdMembersList = action.payload;
    },

    clearHouseholdMembersListSuccess(state) {
      state.householdMembersList = [];
    },

    // RESET state
    resetPropertyState(state) {
      state.isLoading = false;
      state.error = false;
      state.property = {};
      state.propertyList = [];
      state.selectedProperty = {};
      state.availableProperties = {};
      state.unitList = [];
      state.isUnitListReady = false;
      state.applicantsList = [];
      state.isApplicantListReady = false;
      state.setAsidesList = [];
      state.householdMembersList = [];
    },

    // RESET Units
    resetPropertyUnits(state) {
      state.isLoading = false;
      state.error = false;
      state.unitList = [];
      state.isUnitListReady = false;
      state.applicantsList = [];
      state.isApplicantListReady = false;
    },
    setTableFilters(state, action) {
      state.tableFilters = action?.payload || {};
    },
  },
});

//Actions
export const {
  setResetPropertyPage,
  updatePropertyField,
  getSelectedPropertySuccess,
  updatePropertiesListItem,
  setTableFilters,
} = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetPropertyState() {
  return async (dispatch) => dispatch(slice.actions.resetPropertyState());
}

export function getProperty(propertyId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `property_portfolio/property/${propertyId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getPropertySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSetAsideList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `affordable_housing/set_aside/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getSetAsideListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAvailableProperties(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `property_portfolio/property/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getAvailablePropertiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSelectedProperty(propertySlug, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `property_portfolio/property/?slug=${propertySlug}&${parseQueryParams(
          queryParams
        )}`
      );
      response.data[0].property_managers =
        response.data[0].property_managers.filter((m) =>
          response.data[0].exclude_pronto_users
            ? !m.email.endsWith('@prontohousing.com')
            : true
        );
      dispatch(slice.actions.getSelectedPropertySuccess(response.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedProperty(property) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `property_portfolio/property/${property?.id}/?fields=exclude_pronto_users,property_managers.first_name,property_managers.last_name,property_managers.email,property_managers.id&expand=property_managers`
      );
      data.property_managers = data.property_managers.filter((m) =>
        data.exclude_pronto_users
          ? !m.email.endsWith('@prontohousing.com')
          : true
      );
      dispatch(slice.actions.getSelectedPropertySuccess({ property, ...data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProperty(
  propertyId,
  propertyData,
  logoData,
  resetPropertyData
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let responseStatus = 'success';
    try {
      const response = await axios.patch(
        `property_portfolio/property/${propertyId}/`,
        propertyData
      );
      if (response.status !== 200 && response.status !== 201) {
        responseStatus = 'error';
        // throw new Error("Error saving changes on the property");
      } else {
        if (logoData) {
          const logoResponse = await axios.patch(
            `property_portfolio/property/${propertyId}/`,
            logoData
          );
          if (logoResponse.status !== 200 && logoResponse.status !== 201) {
            responseStatus = 'error';
            // throw new Error("Error saving changes on the property");
          }
          if (resetPropertyData) {
            resetPropertyData();
          }
        }
        if (resetPropertyData) {
          resetPropertyData();
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // throw error;
      responseStatus = 'error';
    }
    return responseStatus;
  };
}

export function addProperty(propertyData, logoData, resetPropertyData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `property_portfolio/property/`,
        propertyData
      );
      if (response.status === 200 || response.status === 201) {
        if (logoData) {
          const logoResponse = await axios.patch(
            `property_portfolio/property/${response.data.id}/`,
            logoData
          );
          if (logoResponse.status !== 200 && logoResponse.status !== 201) {
            console.error(logoResponse);
          }
          if (resetPropertyData) {
            resetPropertyData();
          }
        }
        dispatch(slice.actions.addPropertySuccess({ ...response.data }));
        if (resetPropertyData) {
          resetPropertyData();
        }
      } else {
        throw new Error('Error adding property');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function resetPropertyUnits() {
  return async (dispatch) => {
    dispatch(slice.actions.resetPropertyUnits());
  };
}

export function clearHouseholdMembersList() {
  return async (dispatch) => {
    dispatch(slice.actions.clearHouseholdMembersListSuccess());
  };
}

export function getHouseholdMembersList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingBubble());
    try {
      const response = await axios.get(
        `household/household_member/?${parseQueryParams(queryParams)}`
      );

      dispatch(slice.actions.getHouseholdMembersListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
