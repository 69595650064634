import axios from 'src/utils/axios';
import { AxiosResponse } from 'axios';

export default class CertificationService {
  public static createCertificationCategoryAndStatuses = async (
    payload
  ): Promise<AxiosResponse> => {
    return axios.post(
      `/certification/certification_config/create_new_category_and_statuses/`,
      payload
    );
  };

  public static deleteCertificationCategory = async (
    categoryID
  ): Promise<AxiosResponse> => {
    return axios.delete(`/certification/category/${categoryID}`);
  };

  public static createCategoryStatus = async (
    payload
  ): Promise<AxiosResponse> => {
    return axios.post(`/certification/status/`, payload);
  };

  public static updateCategoryStatus = async (
    payload
  ): Promise<AxiosResponse> => {
    return axios.patch(`/certification/status/bulk_update/`, payload);
  };

  public static updateCertification = async (
    certification_id,
    payload,
    queryParams = null
  ): Promise<AxiosResponse> => {
    let url = `/certification/certification/${certification_id}/`;
    if (queryParams) {
      url += `?${queryParams}`;
    }
    return axios.patch(url, payload);
  };

  public static createNewCertification = async (
    certificationsPayload
  ): Promise<AxiosResponse> => {
    return axios.post('invite_to_new_program/', certificationsPayload);
  };

  public static updateCertCategoryStatus = async (
    payload,
    params = null
  ): Promise<AxiosResponse> => {
    let url = 'certification/status_step/';
    if (params) {
      url += `?${params}`;
    }
    return axios.post(url, payload);
  };

  public static fetchCategoriesInCerts = async (
    ids,
    params
  ): Promise<AxiosResponse> => {
    return axios.get(`/certification/category/?id__in=${ids}&${params}`);
  };
}
