import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import parseQueryParams from '../../../utils/query';
import _ from 'lodash';
import { buildData } from 'src/components/UserDashboard/MyTasks/utils';

// ----------------------------------------------------------------------
// TODO: Revisit this file as a lof of code might be unused
const initialState = {
  isLoading: false,
  error: false,
  openDashboardMailCompose: false,
  dashboardSelectedRecord: false,
  communication: {
    communicationModuleList: [],
    user_certification: {},
    isLoading: false,
  },
  myTasks: {
    tasksList: [],
    forceUpdate: false,
    isLoading: false,
    openInviteCertification: false,
    certification: {},
  },
  notice: {
    forceUpdate: false,
    isLoading: false,
    openCertificationNotice: false,
    task: {},
  },
  teamTasks: {
    tasksList: [],
    isLoading: false,
  },
  complianceManagerDashboard: {
    isLoading: false,
    fetchingKPIs: false,
    date_range_type: null,
    created: null,
    overdue: null,
    overdue_certifications: '',
    coming_due: null,
    coming_due_certifications: '',
    due_within: { not_started: null, in_progress: null },
    due_within_certifications: '',
    completed_past: null,
    completed_past_certifications: '',
    completed: null,
    completed_certifications: '',
    average_time: null,
    average_time_certifications: '',
    average_time_in_status: {
      labels: [],
      data: [],
    },
    average_time_file_certifications: '',
    in_progress: {
      labels: [],
      data: [],
    },
    in_progress_certifications: '',
    per_compliance_specialist: {
      labels: [],
      data: [],
    },
    per_compliance_specialist_certifications: '',
  },
  documentManagerTasks: {
    selectedTask: null,
    fileTaskIndex: null,
    tasksList: [],
    shouldReselect: false,
  },
  requestSignaturesTasks: {
    selectedTask: null,
    tasksList: [],
    openSignatures: false,
  },
  forceUpdate: false,
  todosList: [],
  todo: {},
};

const slice = createSlice({
  name: 'UserDashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING COMMUNICATIONMODULE
    startLoadingCommunication(state) {
      state.communication = {
        ...state.communication,
        isLoading: true,
      };
    },

    // START LOADING Compliance Manager Dashboard
    startLoadingComplianceManagerDashboard(state, action) {
      const silenced = action.payload === true;
      state.complianceManagerDashboard = {
        ...state.complianceManagerDashboard,
        isLoading: !silenced,
        fetchingKPIs: true,
      };
    },

    startLoadingTasks(state) {
      state.myTasks = {
        ...state.myTasks,
        isLoading: true,
      };
    },

    startLoadingTeamTasks(state) {
      state.teamTasks = {
        ...state.myTasks,
        isLoading: true,
      };
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    hasErrorKPIS(state, action) {
      state.complianceManagerDashboard = {
        ...state.complianceManagerDashboard,
        isLoading: false,
        fetchingKPIs: false,
      };
      state.error = action.payload;
    },

    getTodosSuccess(state, action) {
      state.isLoading = false;
      state.todosList = action.payload;
    },

    getTodoSuccess(state, action) {
      state.isLoading = false;
      state.todo = action.payload;
    },

    resetTodosList(state) {
      state.todosList = [];
    },

    updateTodoSuccess(state, action) {
      state.isLoading = false;
      const list = [...state.todosList];
      const idx = list.findIndex((element) => element.id === action.payload.id);
      list[idx] = action.payload;
      state.todosList = list;
    },

    updateTaskSuccess(state, action) {
      const list = state.myTasks.tasksList;
      const idx = list.results.findIndex(
        (element) => element.id === action.payload.id
      );
      list.results[idx] = {
        ...list.results[idx],
        ...action.payload,
      };
      state.myTasks = {
        ...state.myTasks,
        tasksList: list,
      };
    },

    updateTeamTaskSuccess(state, action) {
      const list = state.teamTasks.tasksList;
      const idx = list.results.findIndex(
        (element) => element.id === action.payload.id
      );
      list.results[idx] = {
        ...list.results[idx],
        ...action.payload,
      };
      state.teamTasks = {
        ...state.teamTasks,
        tasksList: list,
      };
    },

    updateTeamTasksList(state, action) {
      const elements =
        'results' in state.teamTasks.tasksList
          ? state.teamTasks.tasksList.results
          : state.teamTasks.tasksList;
      const list = [action.payload, ...elements];

      state.teamTasks = {
        ...state.teamTasks,
        tasksList: list,
      };
    },

    addTodoSuccess(state, action) {
      state.isLoading = false;
      state.todosList = [action.payload, ...state.todosList];
    },

    // GET MY TASKS LIST
    getMyTasksListSuccess(state, action) {
      state.myTasks = {
        ...state.myTasks,
        tasksList: action.payload,
        isLoading: false,
      };
    },

    // GET TEAM TASKS LIST
    getTeamTasksListSuccess(state, action) {
      state.teamTasks = {
        ...state.teamTasks,
        tasksList: action.payload,
        isLoading: false,
      };
    },

    // GET MY TASKS LIST
    resetTasksList(state) {
      state.myTasks = {
        ...state.myTasks,
        tasksList: [],
      };
    },

    // GET TEAM TASKS LIST
    resetTeamTasksList(state) {
      state.teamTasks = {
        ...state.teamTasks,
        tasksList: [],
      };
    },

    // GET COMMUNICATION LIST BY USER
    getCommunicationModuleListSuccess(state, action) {
      state.communication = {
        ...state.communication,
        communicationModuleList: action.payload,
        isLoading: false,
      };
    },

    // GET COMMUNICATION LIST BY USER
    getComplianceManagerSuccess(state, action) {
      const response = _.cloneDeep(action.payload);
      state.complianceManagerDashboard = {
        ...state.complianceManagerDashboard,
        created: response.created,
        date_range_type: response.date_range_type,
        overdue: response.kpisByType?.overdue?.results?.total || 0,
        overdue_certifications: response.kpisByType?.overdue?.certifications,
        coming_due: response.kpisByType?.coming_due?.results?.total || 0,
        coming_due_certifications:
          response.kpisByType?.coming_due?.certifications,
        due_within: response.kpisByType?.due_within_120_days?.results,
        due_within_certifications:
          response.kpisByType?.due_within_120_days?.certifications,
        completed_past:
          response.kpisByType?.completed_but_past_due?.results?.total || 0,
        completed_past_certifications:
          response.kpisByType?.completed_but_past_due?.certifications,
        completed: response.kpisByType?.completed?.results?.total || 0,
        completed_certifications:
          response.kpisByType?.completed?.certifications,
        average_time: `${
          response.kpisByType?.average_completed?.results?.total || 0
        } day${
          response.kpisByType?.average_completed?.results?.total !== 1
            ? 's'
            : ''
        }`,
        average_time_certifications:
          response.kpisByType?.average_completed?.certifications,
        average_time_in_status: response.kpisByType?.average_status?.results,
        average_time_in_status_certifications:
          response.kpisByType?.average_status?.certifications,
        in_progress: response.kpisByType?.status?.results,
        in_progress_certifications: response.kpisByType?.status?.certifications,
        per_compliance_specialist: response.kpisByType?.by_compliance?.results,
        per_compliance_specialist_certifications:
          response.kpisByType?.by_compliance?.certifications,
        isLoading: false,
        fetchingKPIs: false,
      };
    },

    // GET CERTIFICATION FOR COMMUNICATION
    setCertification(state, action) {
      state.communication = {
        ...state.communication,
        user_certification: action.payload,
      };
    },

    // GET HOUSEHOLD DATA FOR INVITE CERTIFICATION
    setCertificationData(state, action) {
      state.myTasks = {
        ...state.myTasks,
        certification: action.payload,
        openInviteCertification: true,
      };
    },

    // GET DATA FOR CERTIFICATION NOTICE
    setCertificationNoticeTask(state, action) {
      state.notice = {
        ...state.myTasks,
        task: action.payload,
        openCertificationNotice: true,
      };
    },

    // CLEAR HOUSEHOLD DATA FOR INVITE CERTIFICATION
    clearCertificationData(state) {
      state.myTasks = {
        ...state.myTasks,
        certification: null,
        openInviteCertification: false,
      };
    },

    // CLEAR CERTIFICATION NOTICE
    clearCertificationNoticeTask(state) {
      state.notice = {
        ...state.myTasks,
        task: null,
        openCertificationNotice: false,
      };
    },

    // SET SELECTED TASK
    setSelectedTask(state, action) {
      state.documentManagerTasks = {
        ...state.documentManagerTasks,
        selectedTask: action.payload,
        tasksList: action.payload?.tasks,
      };
    },

    setFileTaskIndex(state, action) {
      state.documentManagerTasks.fileTaskIndex = action.payload;
    },

    setOpenRequestSignatures(state, action) {
      state.requestSignaturesTasks = {
        ...state.requestSignaturesTasks,
        selectedTask: action.payload,
        tasksList: action.payload?.tasks,
        openRequestSignatures: true,
      };
    },

    setShouldReselect(state, action) {
      state.documentManagerTasks = {
        ...state.documentManagerTasks,
        shouldReselect: action.payload,
      };
    },

    clearOpenRequestSignatures(state) {
      state.requestSignaturesTasks = {
        ...state.requestSignaturesTasks,
        selectedTask: null,
        tasksList: [],
        openRequestSignatures: false,
      };
    },

    // SET SELECTED TASK
    cleanTasks(state) {
      state.documentManagerTasks = {
        ...state.documentManagerTasks,
        selectedTask: null,
        tasksList: [],
      };
    },

    setForceUpdate(state, action) {
      state.myTasks = {
        ...state.myTasks,
        forceUpdate: action.payload,
      };
    },

    setForceUpdateTodoSummary(state, action) {
      state.forceUpdate = action.payload;
    },

    setOpenDashboardMailCompose(state, action) {
      state.openDashboardMailCompose = action.payload;
    },

    setDashboardSelectedRecord(state, action) {
      state.dashboardSelectedRecord = action.payload;
    },

    setReassignedTeamTasks(state, action) {
      const teamTasks = state.teamTasks.tasksList;
      action.payload.selectedItems.forEach((taskId) => {
        const index = teamTasks.results.findIndex(
          (element) => element.id === taskId
        );
        teamTasks.results[index] = {
          ...teamTasks.results[index],
          user: action.payload.user,
        };
      });
      state.teamTasks = {
        ...state.teamTasks,
        tasksList: teamTasks,
      };
    },

    setFile(state, action) {
      const inf = { ...state.complianceManagerDashboard };
      inf[`${action.payload.type}`] = action.payload.jsonData;
      state.complianceManagerDashboard = inf;
    },

    resetComplianceManager(state) {
      state.complianceManagerDashboard = {
        ...state.complianceManagerDashboard,
        created: null,
        overdue: null,
        overdue_certifications: '',
        coming_due: null,
        coming_due_certifications: '',
        due_within: null,
        due_within_certifications: '',
        completed_past: null,
        completed_past_certifications: '',
        completed: null,
        completed_certifications: '',
        average_time: null,
        average_time_certifications: '',
        average_time_in_status: null,
        average_time_in_status_certifications: '',
        in_progress: null,
        in_progress_certifications: '',
        per_compliance_specialist: null,
        per_compliance_specialist_certifications: null,
        isLoading: true,
        fetchingKPIs: true,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCertification,
  resetTodosList,
  resetTasksList,
  resetTeamTasksList,
  setOpenDashboardMailCompose,
  setDashboardSelectedRecord,
  setReassignedTeamTasks,
  setSelectedTask,
  setFileTaskIndex,
  setForceUpdate,
  setForceUpdateTodoSummary,
  setOpenRequestSignatures,
  clearOpenRequestSignatures,
  setCertificationData,
  setCertificationNoticeTask,
  clearCertificationData,
  clearCertificationNoticeTask,
} = slice.actions;

// ----------------------------------------------------------------------

export function getTodos(silenced, queryParams) {
  return async (dispatch) => {
    if (!silenced) {
      dispatch(slice.actions.startLoading());
    }
    try {
      const response = await axios.get(
        `todos/todo/get_task_groups/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getTodosSuccess(buildData(response.data, true)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTodo(todoId, todoData) {
  return async (dispatch) => {
    try {
      const body = {
        ...todoData,
        certification: todoData.certification.id,
        user: todoData.user?.id,
        property: todoData.property.id,
        household: todoData.household.id,
      };
      await axios.patch(`todos/todo/${todoId}/`, body);
      dispatch(slice.actions.updateTodoSuccess(todoData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTask(taskId, taskData) {
  return async (dispatch) => {
    try {
      await axios.patch(`todos/todo/${taskId}/`, taskData);
      dispatch(slice.actions.updateTaskSuccess({ ...taskData, id: taskId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTeamTask(taskId, taskData) {
  return async (dispatch) => {
    try {
      await axios.patch(`todos/todo/${taskId}/`, taskData);
      dispatch(
        slice.actions.updateTeamTaskSuccess({ id: taskId, ...taskData })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addTodo(todo, successFn, errorFn) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`todos/todo/`, todo);

      if (successFn) {
        successFn(data);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (errorFn) {
        errorFn(error);
      }
    }
  };
}

export function getMyTasksList(silenced, queryParams) {
  return async (dispatch) => {
    if (!silenced) {
      dispatch(slice.actions.startLoadingTasks());
    }
    try {
      const response = await axios.get(
        `todos/todo/get_task_groups/?${parseQueryParams(queryParams)}`
      );
      dispatch(
        slice.actions.getMyTasksListSuccess(buildData(response.data, false))
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTeamTasksList(silenced, queryParams) {
  return async (dispatch) => {
    if (!silenced) {
      dispatch(slice.actions.startLoadingTeamTasks());
    }
    try {
      const response = await axios.get(
        `todos/todo/get_task_groups/?${parseQueryParams(queryParams)}`
      );
      dispatch(
        slice.actions.getTeamTasksListSuccess(buildData(response.data, false))
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCommunicationModuleList(silenced, queryParams) {
  return async (dispatch) => {
    if (!silenced) {
      dispatch(slice.actions.startLoadingCommunication());
    }
    try {
      const response = await axios.get(
        `communication/new_conversation/conversation/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(
        slice.actions.getCommunicationModuleListSuccess(response.data.results)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
