import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { getRecordResponseKey } from '../../../pages/tenant/QuestionnaireV4/utils';
import { areObjectListsEqualByKey } from '../../../components/DocumenManagerV2/utils';

// ----------------------------------------------------------------------
export const DOC_MANAGER_MODES = {
  reject: 'reject',
  accept: 'accept',
  move: 'move',
  unapprove: 'unapprove',
};

const initialState = {
  isLoading: false,

  childRecord: null,

  movePagesVisible: false,
  selectionMode: false,
  mode: null,
  isAllAction: false,
  treeSelection: [],
  pagesTreeSelection: [],
  deletedPages: [],
  autoSaveAfterChange: false,

  isFetchingTreeData: false,
  error: false,
  groupAnswers: [],
  isCompliance: false,

  showRejectionModal: false,

  open: false,
  currentPage: null,
  pageNumber: null,
  childSchema: {},
  groupSchema: {},
  parentFileAnswers: {},
  childAnswersAreDirty: false,
  groupAnswersAreDirty: false,
  childAnswersAreValid: false,
  groupAnswersAreValid: false,
  answerGroup: null,
  hadBeenApprovedOrUnapproved: false,
};

const slice = createSlice({
  name: 'DocumentManager',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // SET FETCHING TREE STATE
    setIsFetchingTreeData(state, action) {
      state.isFetchingTreeData = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET ANSWER GROUPS
    setComplianceStatus(state, action) {
      state.isCompliance = action.payload;
    },

    // SET DELETED PAGES
    setDeletedPages(state, action) {
      state.deletedPages = action.payload;
    },
    setAutoSaveAfterChange(state, action) {
      state.autoSaveAfterChange = action.payload;
    },

    setHadBeenApprovedOrUnapproved(state, action) {
      state.hadBeenApprovedOrUnapproved = action.payload;
    },

    // SET DOC MODE
    setDocManagerMode(state, action) {
      state.mode = action.payload;
      state.selectionMode = Boolean(action.payload);
    },

    resetSelectionMode(state, action) {
      state.mode = null;
      state.selectionMode = false;
      state.isAllAction = false;
      state.treeSelection = [];
      state.pagesTreeSelection = [];
      state.deletedPages = [];
      state.movePagesVisible = false;
    },

    addTreeSelection(state, action) {
      state.treeSelection = [...state.treeSelection, action.payload];
    },

    removeTreeSelection(state, action) {
      let newSelection = [...state.treeSelection];
      state.treeSelection = newSelection.filter(
        (item) => item !== action.payload
      );
    },

    updateTreeSelection(state, action) {
      let newSelection = [...state.treeSelection];

      if (newSelection.includes(action.payload)) {
        newSelection = newSelection.filter(
          (answer) => answer !== action.payload
        );
      } else {
        newSelection.push(action.payload);
      }

      state.treeSelection = newSelection;
    },

    updatePagesTreeSelection(state, action) {
      let pagesSelection = [...state.pagesTreeSelection];
      const { items, all, onlyOne, fileID, totalPages } = action?.payload || {};

      if (onlyOne) {
        const incomingPage = items[0];
        const incomingPageIndex = pagesSelection.findIndex(
          (p) => p === incomingPage
        );
        if (incomingPageIndex !== -1) {
          pagesSelection.splice(incomingPageIndex, 1);
        } else {
          pagesSelection.push(incomingPage);
        }
      }

      if (all) {
        const allIsSelected = items.every((p) => pagesSelection.includes(p));
        if (allIsSelected) {
          pagesSelection = pagesSelection.filter((p) => !items.includes(p));
        } else {
          pagesSelection = [...pagesSelection, ...items].filter(
            (p, i, arr) => arr.findIndex((each) => each === p) === i
          );
        }
      }

      if (fileID && totalPages) {
        let newSelection = [...state.treeSelection];

        if (pagesSelection.length !== totalPages) {
          newSelection = newSelection.filter((answer) => answer !== fileID);
        } else {
          newSelection.push(fileID);
        }

        state.treeSelection = newSelection;
      }
      state.pagesTreeSelection = pagesSelection;
    },

    setTreeSelection(state, action) {
      state.treeSelection = action.payload;
    },

    setMovePagesVisible(state, action) {
      state.movePagesVisible = action.payload;
    },

    setShowRejectionModal(state, action) {
      state.showRejectionModal = action.payload;
    },

    setIsAllAction(state, action) {
      state.isAllAction = action.payload;
    },

    setChildRecord(state, action) {
      state.childRecord = action.payload;
    },

    setOpen(state, action) {
      state.open = action.payload;
    },

    updateStoreDmState(state, action) {
      const p = action.payload || {};

      for (const key in p) {
        if (Object.hasOwnProperty.call(p, key) && key in state) {
          const value = p[key];
          state[key] = value;
        }
      }
    },

    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },

    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },

    updateParentFileAnswers(state, action) {
      const { fileAnswerId, answers, errors } = action.payload || {};
      const newParentFileAnswers = [...state.parentFileAnswers];
      let fileToUpdateIndex = newParentFileAnswers.findIndex(
        (file) => file.id === fileAnswerId
      );
      if (fileToUpdateIndex !== -1) {
        const fileToUpdate = newParentFileAnswers[fileToUpdateIndex];

        fileToUpdate.errors = errors;
        for (const surveyRecordID in answers) {
          let answerToUpdateIndex = newParentFileAnswers[
            fileToUpdateIndex
          ]?.children_answers?.findIndex(
            (answer) => answer.survey_record === parseInt(surveyRecordID)
          );
          if (answerToUpdateIndex === -1) {
            const answerToUpdate = {
              survey_record: parseInt(surveyRecordID),
              parent_file: fileAnswerId,
              value: answers[surveyRecordID],
              dirty: true,
            };
            fileToUpdate.children_answers.push(answerToUpdate);
          } else {
            const answerToUpdate =
              fileToUpdate.children_answers[answerToUpdateIndex];
            if (!answerToUpdate?.dirty) {
              if (Array.isArray(answerToUpdate.value)) {
                answerToUpdate.dirty = areObjectListsEqualByKey(
                  answerToUpdate.value,
                  answers[surveyRecordID],
                  'id'
                );
              }
              answerToUpdate.dirty = !isEqual(
                answers[surveyRecordID],
                answerToUpdate.value
              );
            }
            answerToUpdate.value =
              answers[surveyRecordID] ?? answerToUpdate.value;
          }
        }
        state.childAnswersAreValid = newParentFileAnswers.every(
          (answer) => !answer?.errors
        );
      }
      state.parentFileAnswers = newParentFileAnswers;
    },

    setParentFileAnswers(state, action) {
      if (state.isCompliance) {
        const newParentFileAnswers = [...action.payload].map((file) => {
          return {
            ...file,
            children_answers: file.children_answers.map((answer) => {
              const answerKey = getRecordResponseKey(
                state.childSchema[answer?.survey_record]
              );

              return {
                ...answer,
                dirty: false,
                value: answer[answerKey],
              };
            }),
          };
        });

        state.parentFileAnswers = newParentFileAnswers;
      } else {
        state.parentFileAnswers = [];
      }
    },

    updateChildAnswersIds(state, action) {
      const createdAnswers = action.payload;
      const updatedparentFileAnswers = { ...state.parentFileAnswers };

      for (const parentFilekAnswerKey in updatedparentFileAnswers) {
        if (parentFilekAnswerKey in updatedparentFileAnswers) {
          const parentFileAnswer =
            updatedparentFileAnswers[parentFilekAnswerKey];

          parentFileAnswer.childAnswers = parentFileAnswer.childAnswers.map(
            (ca) => ({
              ...ca,
              id:
                typeof ca?.id === 'number'
                  ? ca.id
                  : createdAnswers.find(
                      (a) =>
                        a?.parent_file === ca?.parent_file &&
                        a?.survey_record === ca?.survey_record
                    )?.id || ca?.id,
            })
          );
        }
      }
      state.parentFileAnswers = updatedparentFileAnswers;
    },

    updateGroupAnswers(state, action) {
      const { values, errors } = action.payload || {};
      const newGroupAnswers = [...state.groupAnswers];

      for (const [key, value] of Object.entries(values)) {
        const index = newGroupAnswers.findIndex(
          (a) => a.survey_record.toString() === key
        );
        if (index !== -1) {
          const wasDirty = newGroupAnswers[index]?.dirty || false;
          if (!wasDirty) {
            if (Array.isArray(newGroupAnswers[index])) {
              newGroupAnswers[index].dirty = areObjectListsEqualByKey(
                newGroupAnswers[index].value,
                value,
                'id'
              );
            } else {
              newGroupAnswers[index].dirty = !isEqual(
                newGroupAnswers[index].value,
                value
              );
            }
          }
          newGroupAnswers[index].value = value ?? value;
          newGroupAnswers[index].errors = errors?.[key] || null;
        }
      }

      state.groupAnswers = newGroupAnswers;
      state.groupAnswersAreValid = newGroupAnswers.every(
        (answer) => !answer.error
      );
    },

    addParentFileAnswer(state, action) {
      if (Object.values(action?.payload).length) {
        state.parentFileAnswers = {
          ...state.parentFileAnswers,
          ...action.payload,
        };
      }
    },

    addGroupAnswers(state, action) {
      if (action?.payload?.length) {
        state.groupAnswers = [...state.groupAnswers].concat(action.payload);
      }
    },

    resetState(state) {
      state.parentFileAnswers = {};
      state.childAnswersAreDirty = false;
      state.childAnswersAreValid = false;
      state.groupAnswersAreDirty = false;
      state.groupAswersAreValid = false;
      state.childSchema = {};
      state.groupSchema = {};
      state.isLoading = false;
      state.movePagesVisible = false;
      state.selectionMode = false;
      state.mode = null;
      state.isAllAction = false;
      state.treeSelection = [];
      state.pagesTreeSelection = [];
      state.deletedPages = [];
      state.autoSaveAfterChange = false;
      state.isFetchingTreeData = false;
      state.error = false;
      state.groupAnswers = [];
      state.isCompliance = false;
      state.showRejectionModal = false;
      state.currentPage = null;
      state.pageNumber = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  endLoading,
  setComplianceStatus,
  setIsFetchingTreeData,
  setDocManagerMode,
  resetSelectionMode,
  updateTreeSelection,
  setMovePagesVisible,
  setShowRejectionModal,
  setIsAllAction,
  setTreeSelection,
  setDeletedPages,
  updatePagesTreeSelection,
  removeTreeSelection,
  addTreeSelection,
  setAutoSaveAfterChange,
  setChildRecord,
  setOpen,
  setCurrentPage,
  resetState,
  updateStoreDmState,
  setPageNumber,
  setParentFileAnswers,
  updateParentFileAnswers,
  updateChildAnswersIds,
  addParentFileAnswer,
  updateGroupAnswers,
  addGroupAnswers,
  setHadBeenApprovedOrUnapproved,
} = slice.actions;

// ----------------------------------------------------------------------
