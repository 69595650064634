import { createSlice } from '@reduxjs/toolkit';
// utils
import parseQueryParams from '../../utils/query';
import axios from 'src/utils/axios';
import {
  mapConversation,
  mapMessage,
} from 'src/components/ConversationsV2/utils';
import {
  EMAIL_TYPE,
  FAX_TYPE,
  SMS_TYPE,
} from 'src/components/ConversationsV2/ConversationsV2Constants';

export const FILTER_IDS = {
  all: 'all',
  inbox: 'inbox',
  sms: 'sms',
  unassigned: 'unassigned',
  fax: 'fax',
};

const initialState = {
  isLoading: false,
  shouldReload: false,
  sendingMessage: false,
  error: false,
  list: [],
  allOptedOutSMS: false,
  totalPages: 1,
  selectedConversation: null,
  count: 1,
  unreadCount: 0,
  update: false,
  selectedCertification: null,
  selectedFilter: {
    id: FILTER_IDS.all,
    filters: { conversation_type: EMAIL_TYPE },
  },
  labels: {
    [FILTER_IDS.all]: {
      id: FILTER_IDS.all,
      type: 'system',
      name: 'email',
      unreadCount: 0,
      filters: { conversation_type: EMAIL_TYPE },
      uppercase: false,
    },
    // [FILTER_IDS.inbox]: {
    //   id: FILTER_IDS.inbox,
    //   type: 'system',
    //   name: 'unread',
    //   unreadCount: 0,
    //   filters: { conversation_type: EMAIL_TYPE, property_inbox: false, has_new_message: true },
    //   uppercase: false
    // },
    [FILTER_IDS.unassigned]: {
      id: FILTER_IDS.unassigned,
      type: 'system',
      name: 'unassigned',
      unreadCount: 0,
      filters: { conversation_type: EMAIL_TYPE, property_inbox: true },
      uppercase: false,
    },
    [FILTER_IDS.sms]: {
      id: FILTER_IDS.sms,
      type: 'system',
      name: 'sms',
      unreadCount: 0,
      filters: { conversation_type: SMS_TYPE },
      uppercase: true,
    },
    [FILTER_IDS.fax]: {
      id: FILTER_IDS.fax,
      type: 'system',
      name: 'fax',
      unreadCount: 0,
      filters: { conversation_type: FAX_TYPE },
      uppercase: false,
    },
  },
  params: {
    search: '',
    page: 1,
    page_size: 10,
  },
};

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MESSAGES
    getMessagesSuccess(state, action) {
      const { list, count, unreadCount } = action.payload;
      state.list = list;
      state.totalPages = Math.ceil(count / state.params.page_size);
      state.count = count;
      state.isLoading = false;
      state.allOptedOutSMS =
        list.length > 0
          ? list.every(
              (item) =>
                item.hhm_mobile_phone && item.hhm_mobile_phone.opt_out === true
            )
          : false;

      state.labels[FILTER_IDS.all].unreadCount = unreadCount.all || 0;
      state.labels[FILTER_IDS.sms].unreadCount = unreadCount.sms || 0;
      state.labels[FILTER_IDS.unassigned].unreadCount =
        unreadCount.unassigned || 0;
      state.labels[FILTER_IDS.fax].unreadCount = unreadCount.fax || 0;
    },

    // GET SMS CONVERSATIONS
    getSMSConversationsSuccess(state, action) {
      const { list } = action.payload;
      state.smsConversations = list;
    },

    // SET PARAMS
    setParams(state, action) {
      state.params = { ...state.params, ...action.payload };
    },

    // SET SHOULD RELOAD
    setShouldReload(state, action) {
      state.shouldReload = action.payload || false;
    },

    // SET RELOAD
    setSelectedConversation(state, action) {
      const { conversation, conversationHadNewMessages } = action.payload;
      const selectedFilter = state?.selectedFilter?.id;
      const isEmail = [
        FILTER_IDS.all,
        FILTER_IDS.inbox,
        FILTER_IDS.unassigned,
      ].includes(selectedFilter);
      const filterId = isEmail ? FILTER_IDS.inbox : selectedFilter;
      const unreadCount = state?.labels[filterId]?.unreadCount;

      state.selectedConversation = conversation;
      state.isLoading = false;

      if (unreadCount > 0 && conversationHadNewMessages) {
        if (isEmail) {
          state.labels[FILTER_IDS.all].unreadCount = unreadCount - 1;
        }

        if (selectedFilter === FILTER_IDS.sms) {
          state.labels[FILTER_IDS.sms].unreadCount = unreadCount - 1;
        }
        if (selectedFilter === FILTER_IDS.unassigned) {
          state.labels[FILTER_IDS.unassigned].unreadCount = unreadCount - 1;
        }
        if (selectedFilter === FILTER_IDS.fax) {
          state.labels[FILTER_IDS.fax].unreadCount = unreadCount - 1;
        }
      }
    },

    // SET RELOAD
    setSelectedConversationMessages(state, action) {
      const { messages } = action.payload;
      state.selectedConversation = {
        ...state.selectedConversation,
        messages: (messages || []).map(mapMessage),
      };
    },

    // UPDATE SELECTED CONVERSATION
    updateSelectedConversation(state, action) {
      const { conversationId, value = {} } = action.payload;
      const updatedConversation = { ...state.selectedConversation };

      if (state?.selectedConversation?.id === conversationId) {
        state.selectedConversation = { ...updatedConversation, ...value };
      }
    },

    // UPDATE MESSAGE
    updateSelectedConversationMsg(state, action) {
      const { messageId, conversationId, value } = action.payload;
      const updatedConversation = { ...state.selectedConversation };

      if (updatedConversation?.id === conversationId) {
        const messageIdx = updatedConversation?.messages.findIndex(
          (msg) => msg.id === messageId
        );

        if ((messageIdx) => 0) {
          // TODO: Non constant violation - condition seems to be useless
          updatedConversation.messages[messageIdx] = value;
          state.selectedConversation = updatedConversation;
        }
      }
    },

    // UPDATE CONVERSATION
    updateConversation(state, action) {
      const { conversationId, value = {} } = action.payload;
      const currentConversations = [...state.list];
      const conversationIdx = currentConversations?.findIndex(
        (c) => c.id === conversationId
      );

      if (conversationIdx >= 0) {
        currentConversations[conversationIdx] = {
          ...currentConversations[conversationIdx],
          ...value,
        };
        state.list = currentConversations;
      }
    },

    // UPDATE CONVERSATION LIST
    updateConversationsList(state, action) {
      const currentConversations = [...state.list];
      // if the conversation is not on the list
      if (
        !currentConversations.some(
          (conversation) => conversation?.id === action?.payload?.id
        )
      ) {
        state.list = [action.payload, ...currentConversations];
      }
    },

    deleteItemFromConversationsList(state, action) {
      const currentConversations = [...state.list];
      const itemIndex = currentConversations.findIndex(
        (conversation) => conversation?.id === action?.payload?.id
      );

      if (itemIndex !== -1) {
        currentConversations.splice(itemIndex, 1);
        state.list = currentConversations;
      }
    },

    // SEND MESSAGE SUCCESS
    sendMessageSuccess(state, action) {
      const { conversationId, message } = action.payload;
      const selectedConversation = { ...state.selectedConversation };

      if (selectedConversation.id === conversationId) {
        selectedConversation.messages.unshift(message);
        state.selectedConversation = selectedConversation;
      }
    },

    cleanFilter(state) {
      state.selectedFilter = state.labels[FILTER_IDS.all];
    },

    setSelectedFilter(state, action) {
      state.list = [];
      state.selectedFilter = action.payload;
    },

    setUpdate(state, action) {
      state.update = action.payload;
    },

    setSelectedCertification(state, action) {
      state.selectedCertification = action.payload;
    },

    updateLabelCount(state, action) {
      const selectedFilter = state?.selectedFilter?.id;
      const isEmail = [
        FILTER_IDS.all,
        FILTER_IDS.inbox,
        FILTER_IDS.unassigned,
      ].includes(selectedFilter);
      const filterId = isEmail ? FILTER_IDS.all : selectedFilter;
      const unreadCount = state?.labels[filterId]?.unreadCount || 0;
      const { increase = false } = action.payload;
      let finalCount = unreadCount;

      if (increase && unreadCount >= 0) {
        finalCount += 1;
      } else if (!increase && unreadCount >= 0) {
        finalCount -= 1;
      }

      if (state.labels[selectedFilter]) {
        if (isEmail) {
          state.labels[FILTER_IDS.all].unreadCount = finalCount;
        }

        if (selectedFilter === FILTER_IDS.sms) {
          state.labels[FILTER_IDS.sms].unreadCount = finalCount;
        }

        if (selectedFilter === FILTER_IDS.unassigned) {
          state.labels[FILTER_IDS.unassigned].unreadCount = finalCount;
        }

        if (selectedFilter === FILTER_IDS.fax) {
          state.labels[FILTER_IDS.fax].unreadCount = finalCount;
        }
      }
    },

    updateAttachmentData(state, action) {
      const attachment = action.payload;
      const newMessages = [];

      state.selectedConversation?.messages.forEach((message) => {
        const newFiles = message?.files.map((file) =>
          file.id === attachment.id ? attachment : file
        );
        newMessages.push({ ...message, files: newFiles });
      });

      state.selectedConversation = {
        ...state.selectedConversation,
        messages: newMessages,
      };
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  setParams,
  setShouldReload,
  setSelectedConversation,
  sendMessageSuccess,
  updateSelectedConversation,
  updateSelectedConversationMsg,
  updateAttachmentData,
  cleanFilter,
  setSelectedFilter,
  updateConversation,
  updateConversationsList,
  deleteItemFromConversationsList,
  setUpdate,
  setSelectedCertification,
} = slice.actions;

// ----------------------------------------------------------------------
export function getConversations(filterValue, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const {
        data: { count: emailUnreadCount },
      } = await axios.get(
        `communication/new_conversation/conversation/?${filterValue}has_new_message=true&page_size=1&page=1&conversation_type=${EMAIL_TYPE}`
      );
      const {
        data: { count: smsUnreadCount },
      } = await axios.get(
        `communication/new_conversation/conversation/?${filterValue}has_new_message=true&page_size=1&page=1&conversation_type=${SMS_TYPE}`
      );
      const {
        data: { count: unassignedUnreadCount },
      } = await axios.get(
        `communication/new_conversation/conversation/?${filterValue}has_new_message=true&page_size=1&page=1&property_inbox=true&conversation_type=${EMAIL_TYPE}`
      );
      const {
        data: { count: faxUnreadCount },
      } = await axios.get(
        `communication/new_conversation/conversation/?${filterValue}has_new_message=true&page_size=1&page=1&property_inbox=true&conversation_type=${FAX_TYPE}`
      );
      const response = await axios.get(
        `communication/new_conversation/conversation/?${filterValue}${parseQueryParams(
          queryParams
        )}`
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(
          slice.actions.getMessagesSuccess({
            list: Array.isArray(response?.data?.results)
              ? response.data.results
              : response.data,
            count: Array.isArray(response?.data?.results)
              ? response.data.count
              : response.data.length,
            unreadCount: {
              all: emailUnreadCount,
              sms: smsUnreadCount,
              unassigned: unassignedUnreadCount,
              fax: faxUnreadCount,
            },
          })
        );
      } else {
        throw new Error('Error getting communications');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getSMSConversations(filterValue, filterBy, queryParams) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `communication/new_conversation/conversation/?${filterBy}=${filterValue}&${parseQueryParams(
          queryParams
        )}&conversation_type=${SMS_TYPE}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(
          slice.actions.getSMSConversationsSuccess({
            list: Array.isArray(response?.data?.results)
              ? response.data.results
              : response.data,
          })
        );
      } else {
        throw new Error('Error getting SMS conversations');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getConversation(
  id,
  queryParams,
  conversationHadNewMessages = false,
  showLoader = false
) {
  return async (dispatch) => {
    if (showLoader) {
      dispatch(slice.actions.startLoading());
    }

    try {
      const response = await axios.get(
        `communication/new_conversation/conversation/${id}/?${parseQueryParams(
          queryParams
        )}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(
          slice.actions.setSelectedConversation({
            conversation: mapConversation(response.data),
            conversationHadNewMessages,
          })
        );
        dispatch(updateConversationsList(response.data));

        if (conversationHadNewMessages) {
          // PATCH has_new_message field
          await axios.patch(
            `communication/new_conversation/conversation/${id}/`,
            { has_new_message: false }
          );
        }
      } else {
        throw new Error('Error getting communications');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getFullConversation(
  id,
  queryParams,
  conversationHadNewMessages = false,
  showLoader = true
) {
  return async (dispatch) => {
    if (showLoader) {
      dispatch(slice.actions.startLoading());
    }

    try {
      const response = await axios.get(
        `communication/new_conversation/conversation/${id}/?${parseQueryParams(
          queryParams
        )}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(
          slice.actions.setSelectedConversation({
            conversation: mapConversation(response.data),
            conversationHadNewMessages,
          })
        );

        if (
          conversationHadNewMessages &&
          response.data.conversation_type !== FAX_TYPE
        ) {
          // PATCH has_new_message field
          await axios.patch(
            `communication/new_conversation/conversation/${id}/`,
            { has_new_message: false }
          );
        }
      } else {
        throw new Error('Error getting communications');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSelectedConversationMessages(id, queryParams) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `communication/new_conversation/conversation/${id}/?${parseQueryParams(
          queryParams
        )}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(
          slice.actions.setSelectedConversationMessages({
            messages: response.data.messages,
          })
        );
      } else {
        throw new Error('Error getting communications');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function includeMessageOnFileManager({
  message,
  conversationId,
  successFunc,
  errFunc,
  selected = false,
}) {
  const body = {
    add_to_certification_file: selected,
    update_certification_file: true,
  };
  const updatedMessage = { ...message };

  return async (dispatch) => {
    try {
      await axios.patch(
        `communication/new_conversation/message/${message?.id}/`,
        body
      );

      updatedMessage.add_to_certification_file = selected;
      dispatch(
        updateSelectedConversationMsg({
          messageId: message?.id,
          conversationId,
          value: updatedMessage,
        })
      );
      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      if (successFunc) {
        errFunc();
      }
      console.error(error);
    }
  };
}

export function toggleConversationReadStatus({
  conversationId,
  readValue,
  errFunc,
}) {
  if (!conversationId) {
    return;
  }

  return async (dispatch) => {
    try {
      await axios.patch(
        `communication/new_conversation/conversation/${conversationId}/`,
        { has_new_message: readValue }
      );
      dispatch(
        slice.actions.updateConversation({
          value: { has_new_message: readValue },
          conversationId,
        })
      );

      dispatch(
        slice.actions.updateLabelCount({
          increase: readValue,
        })
      );
    } catch (error) {
      if (errFunc) {
        errFunc();
      }
      console.error(error);
    }
  };
}
