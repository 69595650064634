export const parseProgress = (progressString: string): number => {
  if (typeof progressString === 'string') {
    const cleanedString = progressString.replace('%', '').trim();
    return Number(cleanedString) || 0;
  }
  return Number(progressString) || 0;
};

export const currencyFormatter = (currency: number): string => {
  const currencyDec = currency ? currency.toFixed(2) : '';
  const formatted = currencyDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return currencyDec.length ? `$${formatted}` : null;
};

export const addThousandsComma = (number: number): string => {
  return number ? String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
};

export const dateFormatter = (date: string): string => {
  if (!date) {
    return '';
  }

  const dateParts = date.split('-');
  if (dateParts.length !== 3) {
    return date;
  }
  const [year, month, day] = dateParts;
  return `${month}/${day}/${year}`;
};
