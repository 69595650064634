import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  questionnaireProgress: {},
  isLoading: false,
  error: '',
};

export const slice = createSlice({
  name: 'Questionnaire Progress Slice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // SET PROGRESS
    setProgress(state, action) {
      state.questionnaireProgress = action.payload;
      state.isLoading = false;
    },
    // RESET PROGRESS
    resetProgressData(state, action) {
      state.questionnaireProgress = {};
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { resetProgressData, setProgress } = slice.actions;

// ----------------------------------------------------------------------

export function getQuestionnaireProgress(surveysId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `questionnaire/survey_progress/?surveys=${surveysId}`
      );
      dispatch(slice.actions.setProgress(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
