import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
export const IS_QUESTION_CHANGE = 'IS_QUESTION_CHANGE';
const initialState = {
  isLoading: false,
  error: false,
  certificationConfiguration: {},
};

const slice = createSlice({
  name: 'Certification Configuration Workbench',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Single property
    getCertificationConfigSuccess(state, action) {
      state.isLoading = false;
      state.certificationConfiguration = action.payload;
    },

    setCertificationConfiguration(state, action) {
      const certConfig = action.payload;

      if (certConfig?.updated) {
        certConfig.updated = new Date(certConfig.updated);
      }
      if (certConfig?.last_publish) {
        certConfig.last_publish = new Date(certConfig.last_publish);
      }

      state.certificationConfiguration = certConfig;
    },

    configUpdated(state, action) {
      const isTemplate = !(
        state?.certificationConfiguration?.certification_config &&
        state?.certificationConfiguration?.property
      );
      const isQuestionChange = action?.payload === IS_QUESTION_CHANGE;

      // if the certification config is a template the updated field is patched
      if (isTemplate) {
        state.certificationConfiguration = {
          ...state.certificationConfiguration,
          updated: new Date(),
        };
      }

      // if the certification config is not a template the last_publish field is patched
      if (!isTemplate && isQuestionChange) {
        state.certificationConfiguration = {
          ...state.certificationConfiguration,
          last_publish: new Date(),
        };
      }
    },
  },
});

// Reducer
export default slice.reducer;
// Actions

export const { setCertificationConfiguration, configUpdated } = slice.actions;
