import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export const PHONE_FORMAT = '(999) 999-9999';

export function fCurrency(number, dollarSign = true) {
  return dollarSign
    ? numeral(number).format(Number.isInteger(number) ? '$ 0,0' : '$ 0,0.00')
    : numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPhone(number) {
  const cleanNumber = number.toString().replace(/\D/g, '');
  const match = cleanNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
  } else {
    return null;
  }
}

export function fStringPhone(number) {
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  return number;
}

export function fLog(lognumber) {
  if (lognumber) {
    lognumber = lognumber.toString();
    if (lognumber.includes(',')) {
      return Number(lognumber.replace(/,/g, '')).toLocaleString('en-US');
    } else {
      return Number(lognumber).toLocaleString('en-US');
    }
  } else if (lognumber === 0) {
    return lognumber;
  } else {
    return null;
  }
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fDecimalToPercent(number) {
  return numeral(number).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
