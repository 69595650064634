export class AnswerGroup {
  constructor(
    name = '',
    file_date = '',
    id = 'answer-group-0',
    isNew = true,
    order = 0
  ) {
    this.id = id;
    this.name = name;
    this.file_date = file_date;
    this.isNew = isNew;
    this.order = order;
  }
}
