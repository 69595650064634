import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHouseholdMember,
  setMemberType,
} from '../redux/slices/Main/mainActions';
import { getHousehold } from '../redux/slices/Tenant/Tenant';
import { useLocation, useNavigate } from 'react-router';
import ForbiddenPage from 'src/pages/ForbiddenPage';

// ----------------------------------------------------------------------

const RoleBasedGuard = ({ accessibleRoles, children }) => {
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();
  const { memberType } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem(
        'nextUrl',
        `${location?.pathname || ''}${location?.search || ''}`
      ); // set next URL
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  // if a next url exits and the user is authenticated then redirect to that URL
  useEffect(() => {
    const nextUrl = localStorage.getItem('nextUrl');
    if (!nextUrl) {
      return;
    }

    const currentUrl = location
      ? `${location?.pathname || ''}${location?.search || ''}`
      : '';

    if (
      isAuthenticated &&
      nextUrl !== '/' &&
      Boolean(currentUrl) &&
      nextUrl !== currentUrl
    ) {
      navigate(nextUrl);
      localStorage.removeItem('nextUrl');
    }
  }, [isAuthenticated, location, navigate]);

  if (!isAuthenticated) {
    return <></>;
  }

  const currentRoles = user.groups.map((role) => role.name.toLowerCase());
  const hasRole = currentRoles.some((role) =>
    accessibleRoles.map((role) => role.toLowerCase()).includes(role)
  );

  if (currentRoles[0] === 'resident' && user.household_member && !memberType) {
    /**
     * Check and assign member type
     */
    dispatch(setHouseholdMember(user.household_member));
    dispatch(setMemberType(user.household_member.member_type.name));
    dispatch(
      getHousehold(user.household_member.household, {
        expand: [
          'household',
          'certifications',
          'household.certifications',
          'household_members',
          'household_members.member_type',
          'household_members.householdmember_compliance',
          'household_members.survey',
        ],
        fields: [
          'id',
          'name',
          'household_members.first_name',
          'household_members.last_name',
          'household_members.id',
          'household_members.member_type.name',
          'household_members.householdmember_compliance.survey_id',
          'household_members.survey.id',
          'household',
          'certifications',
        ],
      })
    );
  }

  if (!hasRole) {
    return <ForbiddenPage />;
  }

  return <>{children}</>;
};

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
};

export default RoleBasedGuard;
