import { createSlice } from '@reduxjs/toolkit';
import { CONTENT_TYPES } from 'src/components/ProntoHousingAdministration/QuestionMapping/constants';
import axios from 'src/utils/axios';
import parseQueryParams from 'src/utils/query';
import CertificationWorkbenchService from 'src/services/CertificationWorkbench.service';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isOptionsLoading: false,
  isQitsLoading: true,
  isDitsLoading: false,
  error: false,
  contentTypeId: null,
  objectId: null,
  sections: [],
  questionnaireChildQuestions: [],
  plainQits: [],
  documents: [],
  formList: [],
  contentTypes: [],
  qits: [],
  mits: [],
  sits: [],
  defaultSits: [],
  dits: [],
  selectedDocument: '',
  mappings: [],
  mitMappings: [],
  sitMappings: [],
  newAnnotations: [],
  calculationMappings: [],
  mappingState: {
    isSaving: false,
    willBeCreated: false,
    createData: null,
    requestBody: null,
    resetNavSelection: false,
    mitBody: null,
    choicesSelection: {
      qitParent: null,
      choices: [],
      renderType: '',
      blank: false,
    },
    hhmt: {
      parent: null,
      options: [],
      all: false,
      renderType: null,
    },
    multiGroup: {
      groupId: null,
      question_group_render_type: null,
      question_group_render_type_choice: null,
    },
    textQuestionTypes: {
      qitId: null,
      renderType: null,
    },
    contextConfiguration: {
      contextId: null,
      renderType: null,
    },
    miscTICBucketCalculationConfiguration: {
      renderType: null,
      questionGroupRenderType: null,
      questionGroupRenderTypeChoice: null,
    },
    miscTICHasAnswersConfiguration: {
      renderType: null,
      questionGroupRenderType: null,
      questionGroupRenderTypeChoice: null,
    },
    miscTICMatchAnswersConfiguration: {
      renderType: null,
      questionGroupRenderType: null,
      questionGroupRenderTypeChoice: null,
    },
    readyToMap: false,
    selectedNode: {},
    configChoices: false,
  },
  sectionFormOptions: {
    sections: [],
    pages: [],
    questions: [],
    hhmts: [],
  },
  hiddenQits: null,
  hoverElement: {
    elementId: null,
    type: '',
  },
  editingGroup: null,
};

const slice = createSlice({
  name: 'Question Mapping',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startQitsLoading(state) {
      state.isQitsLoading = true;
    },

    startDitsLoading(state) {
      state.isDitsLoading = true;
    },

    // START OPTIONS LOADING
    startOptionsLoading(state) {
      state.isOptionsLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FORMS
    getFormsSuccess(state, action) {
      state.isLoading = false;
      state.documents = action.payload;
    },
    // GET FORMS
    getFormListSuccess(state, action) {
      state.isLoading = false;
      state.formList = action.payload;
    },

    // GET FORMS
    updateFormFileURL(state, action) {
      state.isLoading = false;
      const updatedForm = action.payload;
      const oldForms = state.formList?.results
        ? state.formList.results
        : state.formList;
      const newForms = oldForms.map((f) => {
        return f.form?.id === updatedForm.id
          ? {
              ...f,
              form: {
                ...f.form,
                file: updatedForm.file,
                pages: [...updatedForm.pages],
              },
            }
          : f;
      });
      state.formList = state.formList?.results
        ? { ...state.formList, results: newForms }
        : newForms;
    },

    // GET MAPPINGS
    addMappingToStore(state, action) {
      state.mappings = [...state.mappings, action.payload];
    },
    addMitMappingToStore(state, action) {
      state.mitMappings = [...state.mitMappings, action.payload];
    },
    addSitMappingToStore(state, action) {
      state.sitMappings = [...state.sitMappings, action.payload];
    },
    addCalculationMappingToStore(state, action) {
      state.calculationMappings = [
        ...state.calculationMappings,
        action.payload,
      ];
    },
    removeMapping(state, action) {
      const newMappings = [...state.mappings];
      const toDeleteIdx = newMappings.findIndex(
        (el) => el.id === action.payload
      );

      if (toDeleteIdx >= 0) {
        newMappings.splice(toDeleteIdx, 1);
      }

      state.mappings = newMappings;
    },

    setMappings(state, action) {
      state.mappings = action.payload;
    },

    setMitMappings(state, action) {
      state.mitMappings = action.payload;
    },

    setSitMappings(state, action) {
      state.sitMappings = action.payload;
    },

    setCalculationMappings(state, action) {
      state.calculationMappings = action.payload;
    },

    setNewAnnotations(state, action) {
      state.newAnnotations = action.payload;
    },

    setDeletedAnnotations(state, action) {
      state.deletedAnnotations = action.payload;
    },

    removeMitMapping(state, action) {
      const newMitMappings = [...state.mitMappings];
      const toDeleteIdx = newMitMappings.findIndex(
        (el) => el.id === action.payload
      );

      if (toDeleteIdx >= 0) {
        newMitMappings.splice(toDeleteIdx, 1);
      }

      state.mitMappings = newMitMappings;
    },
    removeSitMapping(state, action) {
      const newSitMappings = [...state.sitMappings];
      const toDeleteIdx = newSitMappings.findIndex(
        (el) => el.id === action.payload
      );

      if (toDeleteIdx >= 0) {
        newSitMappings.splice(toDeleteIdx, 1);
      }

      state.sitMappings = newSitMappings;
    },
    removeCalculationMapping(state, action) {
      const newCalculationMappings = [...state.calculationMappings];
      const toDeleteIdx = newCalculationMappings.findIndex(
        (el) => el.id === action.payload
      );

      if (toDeleteIdx >= 0) {
        newCalculationMappings.splice(toDeleteIdx, 1);
      }

      state.calculationMappings = newCalculationMappings;
    },
    // END MAPPINGS

    // GET QITs
    getQitsSuccess(state, action) {
      state.isQitsLoading = false;
      state.sections = action.payload.sections;
      state.plainQits = action.payload.plainQits;
    },

    // GET MITs
    getMitsSuccess(state, action) {
      state.isLoading = false;
      state.mits = action.payload;
    },
    addMitsSuccess(state, action) {
      state.mits = [...state.mits, ...action.payload];
      state.isLoading = false;
    },
    deleteMitSuccess(state, action) {
      const updatedMits = [...state.mits];
      const mitIdx = updatedMits.findIndex((mit) => mit.id === action.payload);
      if (mitIdx >= 0) {
        updatedMits.splice(mitIdx, 1);
      }
      state.mits = updatedMits;
    },

    // GET SITs
    getSitsSuccess(state, action) {
      state.sits = action.payload;
      state.isLoading = false;
    },

    // GET DEFAULT SITs
    getDefaultSitsSuccess(state, action) {
      state.defaultSits = action.payload;
      state.isLoading = false;
    },

    //GET DITs
    getDitsSuccess(state, action) {
      state.dits = action.payload;
      state.isDitsLoading = false;
    },

    // Get form Options
    getSectionFormOptionsSuccess(state, action) {
      state.isOptionsLoading = false;
      state.sectionFormOptions = {
        ...state.sectionFormOptions,
        ...action.payload,
      };
    },

    // get contentTypes
    getContentTypesSuccess(state, action) {
      state.isLoading = false;
      state.contentTypes = action.payload;
    },

    // Set document
    setSelectedDocument(state, action) {
      state.selectedDocument = action.payload;
    },

    // reset data
    resetData(state) {
      state.sections = [];
      state.mits = [];
      state.qits = [];
      state.sits = [];
      state.plainQits = [];
      state.contentTypes = [];
      state.documents = [];
      state.selectedDocument = '';
      state.mappings = [];
      state.mitMappings = [];
      state.sitMappings = [];
      state.calculationMappings = [];
      state.contentTypeId = null;
      state.objectId = null;
      state.editingGroup = null;
      state.mappingState = {
        ...state.mappingState,
        resetNavSelection: false,
        isSaving: false,
        willBeCreated: false,
        createData: null,
        requestBody: null,
        mitBody: null,
        choicesSelection: {
          qitParent: null,
          choices: [],
          renderType: '',
          blank: false,
        },
        hhmt: {
          parent: null,
          options: [],
          all: false,
          enderType: null,
        },
        multiGroup: {
          groupId: null,
          question_group_render_type: null,
          question_group_render_type_choice: null,
        },
        textQuestionTypes: {
          qitId: null,
          renderType: null,
        },
        contextConfiguration: {
          contextId: null,
          renderType: null,
        },
        miscTICBucketCalculationConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        miscTICHasAnswersConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        miscTICMatchAnswersConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        readyToMap: false,
        selectedNode: {},
        configChoices: false,
      };
      state.ticSetupsIds = [];
    },

    // set mapping options
    setMappingOptions(state, action) {
      state.mappingState = { ...state.mappingState, ...action.payload };
    },

    resetMappingOptions(state) {
      state.mappingState = {
        ...state.mappingState,
        isSaving: false,
        resetNavSelection: false,
        willBeCreated: false,
        createData: null,
        requestBody: null,
        mitBody: null,
        choicesSelection: {
          qitParent: null,
          choices: [],
          renderType: '',
          blank: false,
        },
        hhmt: {
          parent: null,
          options: [],
          all: false,
          renderType: null,
        },
        multiGroup: {
          groupId: null,
          question_group_render_type: null,
          question_group_render_type_choice: null,
        },
        textQuestionTypes: {
          qitId: null,
          renderType: null,
        },
        contextConfiguration: {
          contextId: null,
          renderType: null,
        },
        miscTICBucketCalculationConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        miscTICHasAnswersConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        miscTICMatchAnswersConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        readyToMap: false,
        selectedNode: {},
        configChoices: false,
      };
    },

    deletePage(state, action) {
      const updatedSections = [...state.sections];
      const { pageIndex, sectionIndex } = action.payload;

      if (updatedSections[sectionIndex]?.pages[pageIndex]) {
        updatedSections[sectionIndex].pages.splice(pageIndex, 1);
        state.sections = updatedSections;
      }
    },

    // GROUPS
    addPageGroupSuccess(state, action) {
      const { sectionIndex, section, plainQits } = action.payload;
      const updatedSections = [...state.sections];
      const notSavedSection = updatedSections[sectionIndex]?.notSavedSection;
      const sectionOverrides = notSavedSection
        ? { sectionId: section.id, notSavedSection: false, wasCreated: true }
        : {};

      state.sections = buildSectionsTree(plainQits, sectionOverrides);
    },

    getSectionQits(state, action) {
      state.isLoadingSection = false;
      state.sections = action.payload.sectionsWithLoadedData;
    },

    setQits(state, action) {
      state.qits = action.payload;
      state.isQitsLoading = false;
    },

    setHiddenQits(state, action) {
      state.hiddenQits = action.payload;
    },

    setDocumentTemplate(state, action) {
      const docs = [...state.formList.results];
      const idx = docs.findIndex(
        (element) => element.form.id === action.payload.id
      );
      docs[idx].form.template = action.payload.template;
      state.formList.results = docs;
    },

    setDocumentFormQuestions(state, action) {
      const docs = [...state.formList.results];
      const idx = docs.findIndex(
        (element) => element.form.id === action.payload.id
      );
      docs[idx].form.parent_qit = action.payload.parent_qit;
      docs[idx].form.parent_choice = action.payload.parent_choice;
      state.formList.results = docs;
    },

    // Hover element
    setHoverElement(state, action) {
      state.hoverElement = action.payload;
    },

    removeHoverElement(state) {
      state.hoverElement = {
        elementId: null,
        type: '',
      };
    },

    updatePageOption(state, action) {
      const newPageData = action.payload;
      const newPages = (state.sectionFormOptions?.pages || []).map((p) =>
        p.id === newPageData.id ? newPageData : p
      );
      state.sectionFormOptions = {
        ...state.sectionFormOptions,
        pages: newPages,
      };
    },

    updateSectionOption(state, action) {
      const newSectionData = action.payload;
      const newSections = (state.sectionFormOptions?.sections || []).map((s) =>
        s.id === newSectionData.id ? newSectionData : s
      );
      state.sectionFormOptions = {
        ...state.sectionFormOptions,
        sections: newSections,
      };
    },

    // Tic Setups Ids
    setTicSetupsIds(state, action) {
      state.ticSetupsIds = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetData,
  setSelectedDocument,
  setMappingOptions,
  resetMappingOptions,
  setMappings,
  addMappingToStore,
  removeMapping,
  addSitMappingToStore,
  addCalculationMappingToStore,
  addMitMappingToStore,
  setMitMappings,
  setSitMappings,
  removeMitMapping,
  removeSitMapping,
  removeCalculationMapping,
  deletePage,
  setHiddenQits,
  setDocumentFormQuestions,
  addMitsSuccess,
  setHoverElement,
  removeHoverElement,
  updateFormFileURL,
  updatePageOption,
  updateSectionOption,
  setCalculationMappings,
  setNewAnnotations,
  setDeletedAnnotations,
  setTicSetupsIds,
} = slice.actions;

// ----------------------------------------------------------------------
export function mapQits(qit) {
  if (!qit) {
    return;
  }
  return {
    qit: qit,
    name: qit.question?.name,
    id: qit.question?.id,
    necessity: `${qit.necessity}`,
    number_of_answers_required: qit?.number_of_answers_required || '',
    number_of_files_required_per_answer:
      qit?.number_of_files_required_per_answer || '',
    number_of_pages_required_per_file:
      qit?.number_of_pages_required_per_file || '',
    order: qit?.question?.order,
    qitOrder: qit?.order,
    for_compliance: qit?.for_compliance,
    question_context: qit?.question_context,
    question_type: qit?.question?.question_type,
    help_text: qit?.help_text || '',
    question_choices: qit?.question_choices || [],
    member_types: qit?.member_types || [],
  };
}

export function getForms(certificationConfigId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `form_library/form_table/?expand=form&certification_config=${certificationConfigId}`
      );
      dispatch(slice.actions.getFormsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSectionFormOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startOptionsLoading());
    try {
      const { data: hhmts } = await axios.get(
        '/household/household_member_type/'
      );

      dispatch(
        slice.actions.getSectionFormOptionsSuccess({
          hhmts: Array.isArray(hhmts)
            ? hhmts.sort((a, b) => a.weight - b.weight)
            : [],
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getGroup(question_group) {
  const schemaParams = {
    expand: [
      'householdmember_type',
      'question',
      'question.question_choices',
      'question_choice',
    ],
  };

  const { data } = await axios.get(
    `questionnaire/question_library/question_group/${
      question_group.id
    }/?${parseQueryParams(schemaParams)}`
  );
  if (data) {
    return data;
  }
}

const extractSectionsTreeFromQitsArray = (qits = []) => {
  return qits.reduce((acc, curr) => {
    const result = [...acc];
    let sectionIdx = result?.findIndex(
      (sect) => sect?.id === curr?.section?.id
    );

    if (sectionIdx === -1) {
      result.push({ ...curr.section, pages: [] });
      sectionIdx = result.findIndex((sect) => sect?.id === curr?.section?.id);
    }

    let pageIdx = result[sectionIdx]?.pages?.findIndex(
      (page) => page?.id === curr?.page?.id
    );
    if (pageIdx === -1 && result[sectionIdx]?.pages) {
      result[sectionIdx].pages.push({ ...curr?.page, question_groups: [] });
      pageIdx = result[sectionIdx].pages.findIndex(
        (page) => page?.id === curr?.page?.id
      );
    }

    const groupIdx = result[sectionIdx]?.pages[
      pageIdx
    ]?.question_groups?.findIndex(
      (group) => group?.id === curr?.question_group?.id
    );
    if (
      groupIdx === -1 &&
      result[sectionIdx]?.pages[pageIdx]?.question_groups
    ) {
      result[sectionIdx].pages[pageIdx].question_groups.push({
        ...curr?.question_group,
        questions: [],
      });
    }

    return result;
  }, []);
};

// this function builds the questionnaire tree based on the list of qits
export const buildSectionsTree = (
  qits,
  sectionOverrides = {},
  calculations = []
) => {
  const builtTree = extractSectionsTreeFromQitsArray(qits);

  for (let x = 0; x < builtTree.length; x++) {
    if (sectionOverrides?.sectionId === builtTree[x]?.id) {
      builtTree[x] = { ...builtTree[x], ...sectionOverrides };
    }

    const section = builtTree[x];
    const sectionQits = qits.filter((qit) => qit.section?.id === section?.id);
    section.calculations = calculations.filter(
      (calc) =>
        calc.type === 'section' && section.calculations.includes(calc.id)
    );

    section.firstQitOrder = sectionQits[0]?.order;
    section.lastQitOrder = sectionQits[sectionQits.length - 1]?.order;
    for (let y = 0; y < section.pages.length; y++) {
      const page = section.pages[y];
      const pageQits = sectionQits.filter((qit) => qit?.page?.id === page?.id);
      page.firstQitOrder = pageQits[0]?.order;
      page.lastQitOrder = pageQits[pageQits.length - 1]?.order;

      if (section?.wasCreated) {
        page.notSaved = false;
        page.wasCreated = true;
      }

      for (let z = 0; z < page.question_groups.length; z++) {
        const group = page.question_groups[z];
        const groupQits = pageQits.filter(
          (qit) => qit.question_group?.id === group?.id
        );

        if (page?.wasCreated) {
          group.shouldOpen = true;
        }
        group.calculations = calculations.filter(
          (calc) =>
            calc.type === 'question_group' && group.id === calc.question_group
        );
        group.firstQitOrder = groupQits[0]?.order;
        group.lastQitOrder = groupQits[groupQits.length - 1]?.order;
        group.questions = transFormIncomingQits(groupQits); // qits && contexts
      }
    }
  }

  return builtTree;
};

export function fetchQuestionnaireFormData(certificationConfigId) {
  return async (dispatch) => {
    dispatch(slice.actions.startQitsLoading());

    try {
      const { data: qits } = await axios.get(
        `questionnaire_configuration_schema/?certification_config=${certificationConfigId}`
      );
      const { data: calculations } = await axios.get(
        `/calculations/calculation/?certification_config=${certificationConfigId}`
      );
      const sortedQits = qits.sort((a, b) => a.order - b.order);
      dispatch(
        slice.actions.getQitsSuccess({
          sections: buildSectionsTree(sortedQits, {}, calculations),
          plainQits: sortedQits,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
}

const getContentTypeName = (model, key) => {
  if (model === 'certification') {
    if (key === 'lease_start') {
      return 'Lease Start Date';
    }
    if (key === 'lease_end') {
      return 'Lease End Date';
    }
    if (key === 'move_in_date') {
      return 'Move In Date';
    }
    if (key === 'move_out_date') {
      return 'Move Out Date';
    }
  }
  if (model === 'householdmember') {
    if (key === 'email_addresses') {
      return 'Email Address';
    }
    if (key === 'mobile_phones') {
      return 'Mobile Phone';
    }
  }

  return key
    .replaceAll('_', ' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const CONTENT_TYPES_PARAMS = {
  app_label__in: [
    'property_portfolio',
    'household',
    'user_profile',
    'certification',
    'TICFlex',
  ],
  model__in: [
    'propertyownercompany',
    'property',
    'unit',
    'building',
    'household',
    'user',
    'householdmembertype',
    'householdmember',
    'certification',
    'certificationconfig',
    'ticbucketcalculation',
    'tichasanswers',
    'ticmatchanswers',
  ],
};

export function getContentTypes(
  certificationconfigId = null,
  ticSetupIDs = null
) {
  return async (dispatch) => {
    try {
      const endpointURL = `mapping_question/content_types/?${parseQueryParams(
        CONTENT_TYPES_PARAMS
      )}`;

      const { data } = await axios.get(endpointURL);

      for (let i = 0; i < data.length; i++) {
        const contentType = data[i];
        let endpoint = CONTENT_TYPES[contentType?.model]?.endpoint;

        // Check if certificationconfigId is provided and append it
        if (endpoint.includes('certification_config')) {
          if (certificationconfigId !== null) {
            endpoint += `${certificationconfigId}/`;
          }
        }

        if (endpoint) {
          try {
            if (endpoint === 'tic_flex/tic_bucket/') {
              endpoint =
                `certification/certification_config/${certificationconfigId}?expand=` +
                [
                  'tic_setups.buckets.calculations.tic_setups',
                  'tic_setups.buckets.calculations.member_types',
                ].join(',') +
                '&fields=' +
                [
                  'tic_setups.id',
                  'tic_setups.buckets.name',
                  'tic_setups.buckets.id',
                  'tic_setups.buckets.calculations.id',
                  'tic_setups.buckets.calculations.name',
                  'tic_setups.buckets.calculations.format',
                  'tic_setups.buckets.calculations.formula',
                  'tic_setups.buckets.calculations.is_for_certification',
                  'tic_setups.buckets.calculations.member_types.id',
                  'tic_setups.buckets.calculations.tic_setups.id',
                ].join(',');

              const { data } = await axios.get(endpoint);

              const finalBucketData = [];
              data.tic_setups.forEach((ticSetup) => {
                const ticSetupID = ticSetup.id;
                ticSetup.buckets.forEach((bucket) => {
                  const calculations = bucket.calculations.filter(
                    (calculation) =>
                      calculation.tic_setups.some(
                        (ticSetup) => ticSetup.id === ticSetupID
                      )
                  );
                  finalBucketData.push({
                    calculations: calculations,
                    name: bucket.name,
                    id: bucket.id,
                  });
                });
              });
              contentType.fields = finalBucketData;
            } else if (
              [
                'tic_flex/tic_has_answers/',
                'tic_flex/tic_match_answers/',
              ].includes(endpoint)
            ) {
              if (ticSetupIDs.length) {
                endpoint +=
                  `?tic_setups__id__in=${ticSetupIDs.join(
                    ','
                  )}&expand=member_types&fields=` +
                  [
                    'id',
                    'name',
                    'answers_level',
                    'member_types.id',
                    'member_types.display_name',
                  ].join(',');
                const { data } = await axios.get(endpoint);

                contentType.fields = data;
              } else {
                contentType.fields = [];
              }
            } else {
              const { data } = await axios.options(endpoint);
              let fieldsData;

              // Check if the 'actions' property exists
              if (data.actions && data.actions.POST) {
                fieldsData = data.actions.POST;
              } else {
                fieldsData = data;
              }

              if (fieldsData) {
                contentType.fields = Object.keys(fieldsData)
                  // Define filter conditions with variable names
                  .filter((key) => {
                    const isAttrField =
                      key.startsWith('attr_') &&
                      CONTENT_TYPES[contentType?.model].fields.some((field) =>
                        key.includes(field)
                      );
                    const isExactMatchField =
                      !key.startsWith('attr_') &&
                      CONTENT_TYPES[contentType?.model].fields.includes(key);

                    return isAttrField || isExactMatchField;
                  })
                  .map((key) => ({
                    id: key,
                    name: getContentTypeName(
                      contentType?.model || '',
                      key.startsWith('attr_') ? key.replace('attr_', '') : key
                    ),
                  }));
              } else {
                contentType.fields = [];
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
      dispatch(slice.actions.getContentTypesSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMitsByAsset(certificationConfigId) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `mapping_question/misc_intermediate_table/?certification_config=${certificationConfigId}`
      );

      dispatch(slice.actions.getMitsSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSitsByAsset(queryParams, isDefault) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `signature/signature_intermediate_table/?${parseQueryParams(
          queryParams
        )}`
      );
      if (!isDefault) {
        dispatch(slice.actions.getSitsSuccess(data));
      } else {
        dispatch(slice.actions.getDefaultSitsSuccess(data));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createSit(body, successFunc, errFunc) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('signature/signature_intermediate_table/', body);
      successFunc();
    } catch (error) {
      errFunc(error);
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSit(sitId, body, successFunc, errFunc) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(
        `signature/signature_intermediate_table/${sitId}/`,
        body
      );
      successFunc();
    } catch (error) {
      errFunc(error);
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSit({ sitId, successFunc, errFunc }) {
  return async (dispatch) => {
    try {
      await axios.delete(`signature/signature_intermediate_table/${sitId}/`);
      successFunc();
    } catch (error) {
      errFunc();
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

const transFormIncomingQits = (qits) => {
  if (!Array.isArray(qits)) {
    return [];
  }
  const questionGroupQits = [...qits].map((qit) => mapQits(qit));
  const contextIds = [
    ...new Set(
      questionGroupQits
        .filter((qit) => Boolean(qit.question_context))
        .map((qit) => qit.question_context)
    ),
  ];
  const contextList = contextIds.map((contextId) => {
    const contextQits = questionGroupQits.filter(
      (qit) => qit.question_context === contextId
    );
    return {
      id: contextId,
      contextId: contextId,
      qitOrder: Math.min(...contextQits.map((qit) => qit.qitOrder)),
      qits: contextQits,
    };
  });

  return [
    ...questionGroupQits.filter((el) => !el.question_context),
    ...contextList,
  ].sort((a, b) => a.qitOrder - b.qitOrder);
};

export function getAllDits(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startDitsLoading());
    try {
      const response = await CertificationWorkbenchService.getAllDits(
        queryParams
      );
      dispatch(slice.actions.getDitsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
