import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  notificationsList: [],
  notifications: [],
  totalCount: 1,
};

const slice = createSlice({
  name: 'Notification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Notifications List for the module
    getNotificationsListSuccess(state, action) {
      state.isLoading = false;
      state.notificationsList = action.payload;
    },

    // Append Notifications List for the Notification Page
    appendNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    // Get Notification List for the Notification Page
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload.results || action.payload;
      state.totalCount = action.payload.count || action.payload.length;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotificationsList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `notifications/notification/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getNotificationsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
