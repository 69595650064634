import axiosInstance from 'src/utils/axios';
import axios from 'src/utils/axios';
import { includeMessageOnFileManager } from 'src/redux/slices/conversationsV2';
import { COMPLIANCE_PATHS } from 'src/routes/paths';
import { fPhone } from 'src/utils/formatNumber';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Input } from '@mui/material';

export function htmlToPlainText(html) {
  if (!html) {
    return '';
  }
  return html.replace(/<\/?[^>]+(>|$)/g, '');
}

export const SMS_CONVERSATION_TYPE = 2;

export const mapMessage = (msg) => {
  const attachments = (msg?.attachments || []).map((file) => {
    const {
      name: fileName,
      ext,
      url,
    } = getFilenameAndExtension(file?.file_attachment_url || '');
    return {
      name: file?.name || fileName,
      error: file?.error || false,
      traceback: file?.traceback || '',
      file_date: file?.file_date,
      ext: ext,
      url: url,
      survey_file: file?.survey_file || null,
      certification_document: file?.certification_document || null,
      id: file?.id || null,
      messageId: msg?.id,
      message_type: msg?.message_type,
    };
  });

  return {
    ...msg,
    files: attachments,
  };
};

export function mapConversation({
  id,
  has_new_message = false,
  subject = '',
  messages = '',
  last_message_date = '',
  last_message = null,
  household_members_users = [],
  contacts = [],
  certification,
  conversation_type,
  mobile_phone,
  hhm_mobile_phone,
  contact_mobile_phone,
  contact_fax_number,
  hhm_emails = [],
  contact_emails = [],
  from_to_pair,
  pdf_file_url,
  property,
  documo_metadata,
  status,
}) {
  const getContactName = (c) => {
    let displayName = [];
    if (c?.first_name) {
      displayName.push(c?.first_name);
    }
    if (c?.last_name) {
      displayName.push(c?.last_name);
    }
    if (Boolean(!c?.first_name && !c?.last_name)) {
      displayName.push(c?.email);
    }

    return displayName.join(' ');
  };

  const fromList = [
    ...household_members_users.map((hmu) => ({
      name: hmu?.first_name,
      displayName: `${hmu?.first_name} ${hmu?.last_name}`,
      lastName: hmu?.last_name,
      email: hmu?.email,
      id: hmu?.id,
      mobile_phone:
        (hmu?.household_member && hmu?.household_member?.mobile_phone) || null,
      hhmId: (hmu?.household_member && hmu?.household_member?.id) || null,
      isHHM: true,
      fax_number: hmu?.fax_number,
    })),
    ...contacts.map((c) => ({
      name: c?.first_name ? c?.first_name : c?.email,
      displayName: getContactName(c),
      lastName: c?.last_name,
      email: c?.email,
      id: c?.id,
      mobile_phone: c?.mobile_phone || null,
      hhmId: null,
      isHHM: false,
      fax_number: c?.fax_number,
    })),
  ];

  return {
    id,
    mobile_phone,
    hhm_mobile_phone,
    contact_mobile_phone,
    contact_fax_number,
    from_to_pair,
    certification,
    conversation_type,
    subject,
    hhm_emails,
    contact_emails,
    documo_metadata,
    isUnread: has_new_message,
    last_message: last_message,
    createdAt: last_message_date,
    message: htmlToPlainText(last_message?.content || ''),
    messages: (messages || []).map(mapMessage),
    pdf_file_url: pdf_file_url,
    from: fromList,
    property,
    status,
  };
}

export function buildMessageFormData(payload) {
  let data = new FormData();

  for (let [key, _value] of Object.entries(payload)) {
    if (Array.isArray(_value) && key === 'files') {
      for (let i = 0; i < _value.length; i++) {
        const file = _value[i];
        data.append(`file[${i}]`, file);
      }
    } else {
      data.append(key, _value);
    }
  }

  return data;
}

export function getFilenameAndExtension(attachment) {
  const regex = /(\w+)(\.\w+)+(?!.*(\w+)(\.\w+)+)/;
  const match = attachment.match(regex);

  return {
    name: match?.[0] || '',
    ext: match?.[2] || '',
    url: attachment || '',
  };
}

export async function getMessageById(id) {
  const fields = [
    'id',
    'sender_user.id',
    'sender_user.first_name',
    'sender_user.email',
    'sender_user.last_name',
    'sender_contact.id',
    'sender_contact.first_name',
    'sender_contact.email',
    'sender_contact.last_name',
    'attachments.file_attachment_url',
    'attachments.created',
    'attachments.survey_file',
    'attachments.certification_document',
    'attachments.id',
    'created',
    'content',
    'add_to_certification_file',
  ].join(',');
  const expand = [
    'sender',
    'attachments',
    'sender_user',
    'sender_contact',
  ].join(',');

  const { data } = await axios.get(
    `communication/new_conversation/message/${id}/?fields=${fields}&expand=${expand}`
  );

  return mapMessage(data);
}

export const getFromField = (fromData, key) => {
  if (!Array.isArray(fromData)) {
    return null;
  }
  if (fromData?.length === 1) {
    return key === 'name' ? fromData[0]['displayName'] : fromData[0][key];
  }
  if (fromData?.length > 1) {
    return fromData.map((item) => item[key]).join(', ');
  }
};

export const EXPAND_CONVERSATION = [
  'certification',
  'certification.unit',
  'certification.property',
  'certification.household.household_members',
  'certification.household.household_members.member_type',
  'messages.sender_user',
  'messages.sender_contact',
  'messages.attachments',
  'household_members_users.household_member.mobile_phone',
  'mobile_phone',
  'hhm_mobile_phone.household_member',
  'contact_mobile_phone.contact',
  'contacts',
  'contacts.mobile_phone',
  'property',
  'documo_metadata',
  'hhm_emails.household_member',
  'contact_emails.contact',
  'contact_fax_number.contact',
];

export const FIELDS_CONVERSATION = [
  'from_to_pair',
  'pdf_file_url',
  'property',
  'conversation_type',
  'hhm_mobile_phone',
  'mobile_phone.id',
  'mobile_phone.phone_number',
  'mobile_phone.is_reachable',
  'mobile_phone.is_valid',
  'hhm_emails',
  'contact_emails',
  'contact_fax_number.contact',
  'contact_fax_number.fax_number',
  'id',
  'certification.id',
  'certification.unit.name',
  'certification.log_number',
  'certification.display_name',
  'certification.certification_config',
  'certification.is_done',
  'add_to_certification_file',
  'created',
  'has_new_message',
  'subject',
  'last_message_date',
  'certification.certification_file',
  'certification.property',
  'household_members_users.id',
  'household_members_users.email',
  'household_members_users.first_name',
  'household_members_users.last_name',
  'household_members_users.household_member.id',
  'household_members_users.household_member.mobile_phone',
  'messages.id',
  'messages.sender_user.id',
  'messages.sender_user.first_name',
  'messages.sender_user.email',
  'messages.sender_user.last_name',
  'messages.sender_contact.id',
  'messages.sender_contact.first_name',
  'messages.sender_contact.email',
  'messages.sender_contact.last_name',
  'messages.sender_contact.fax_number',
  'messages.attachments.file_attachment_url',
  'messages.attachments.name',
  'messages.attachments.file_date',
  'messages.attachments.created',
  'messages.attachments.survey_file',
  'messages.message_type',
  'messages.attachments.error',
  'messages.attachments.traceback',
  'messages.attachments.certification_document',
  'messages.attachments.id',
  'messages.created',
  'messages.content',
  'messages.add_to_certification_file',
  'messages.add_to_certification_file',
  'messages.from_email',
  'messages.sent',
  'messages.sent_error',
  'messages.recent_status',
  'certification.household.household_members',
  'certification.household.household_members.id',
  'certification.household.household_members.first_name',
  'certification.household.household_members.last_name',
  'certification.household.household_members.member_type',
  'certification.household.name',
  'contacts.id',
  'contacts.first_name',
  'contacts.last_name',
  'contacts.email',
  'contacts.fax_number',
  'contacts.mobile_phone.id',
  'contacts.mobile_phone.phone_number',
  'contacts.mobile_phone.is_reachable',
  'contacts.mobile_phone.is_valid',
  'last_message.content',
  'last_message.from_email',
  'last_message.sender_user',
  'last_message.sender_contact',
  'last_message.sender_hhm_email',
  'last_message.sender_contact_email',
  'property.id',
  'property.name',
  'property.fax_number',
  'documo_metadata',
  'status',
];

export const FIELDS_MESSAGES = [
  'messages.id',
  'messages.sender_user.id',
  'messages.sender_user.first_name',
  'messages.sender_user.email',
  'messages.sender_user.last_name',
  'messages.sender_contact.id',
  'messages.sender_contact.first_name',
  'messages.sender_contact.email',
  'messages.sender_contact.last_name',
  'messages.attachments.file_attachment_url',
  'messages.attachments.created',
  'messages.attachments.name',
  'messages.attachments.file_date',
  'messages.attachments.survey_file',
  'messages.message_type',
  'messages.attachments.error',
  'messages.attachments.traceback',
  'messages.attachments.certification_document',
  'messages.attachments.id',
  'messages.created',
  'messages.content',
  'messages.from_email',
  'messages.add_to_certification_file',
  'messages.add_to_certification_file',
  'messages.sender_user.fax_number',
  'messages.sender_contact.fax_number',
];

export const EXPAND_MESSAGES = [
  'messages.sender_user',
  'messages.sender_contact',
  'messages.attachments',
  'messages.from_email',
];

export const getCertificationPagePath = (conversation) => {
  const path = COMPLIANCE_PATHS.certification.view
    .replace(':property_slug', conversation.certification.property.slug)
    .replace(
      ':certification_id',
      Number(conversation.certification.id).toString()
    );
  return `${path.slice(0, -1)}?t1=summary`;
};

export const getUnitPagePath = (conversation) => {
  return COMPLIANCE_PATHS.unit.view
    .replace(':property_slug', conversation.certification.property.slug)
    .replace(
      '{UNIT_ID}',
      Number(conversation.certification.household.unit.id).toString()
    );
};

export const fFaxNumber = (faxNumber) => {
  if (faxNumber) {
    let fNumber = faxNumber.replace('+1', '');
    return fPhone(fNumber) || fNumber;
  } else {
    return '';
  }
};

export async function deleteEmptyConversations(conversationsList) {
  if (!conversationsList?.length) {
    return [];
  }
  const deletedConversationIds = [];

  try {
    for (const conversation of conversationsList) {
      const res = await axiosInstance.get(
        `communication/new_conversation/conversation/${conversation.id}/?fields=id,messages`
      );
      // the conversations are only delete if exist and the messages field is empty
      if ([200, 201].includes(res.status) && !res?.data?.messages?.length) {
        await axiosInstance.delete(
          `communication/new_conversation/conversation/${conversation.id}/`
        );
        deletedConversationIds.push(conversation.id);
      }
    }
  } catch (error) {
    console.error('The conversation');
    return [];
  }

  return deletedConversationIds;
}

export const selectMessage = (
  e,
  { msg, conversationId, dispatch, enqueueSnackbar }
) => {
  if (!msg || !conversationId) {
    return;
  }

  const message = msg?.add_to_certification_file
    ? 'The message has been unselected.'
    : 'The message has been selected to include in File Manager.';

  e.stopPropagation();
  dispatch(
    includeMessageOnFileManager({
      message: msg,
      conversationId: conversationId,
      selected: !Boolean(msg?.add_to_certification_file),
      successFunc: () => enqueueSnackbar(message, { variant: 'success' }),
      errFunc: () =>
        enqueueSnackbar('Something went wrong selecting the message.', {
          variant: 'error',
        }),
    })
  );
};
export const RootStyle = styled('div')(({ theme }) => ({
  right: 70,
  bottom: 0,
  zIndex: 1200,
  height: '100%',
  minHeight: '70vh',
  maxHeight: 'calc(100vh - 200px) ',
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor: theme.palette.background.paper,
  width: '60vw',
}));

export const InputStyle = styled(Input)(({ theme }) => ({
  padding: theme.spacing(0.5, 3),
  borderBottom: `solid 1px ${theme.palette.divider}`,
}));
