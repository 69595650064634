import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  question: {},
  questionsList: [],
  questionFormList: [],
  currentSearch: '',
};

const slice = createSlice({
  name: 'Question',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // reset data
    resetQuestion(state) {
      state.question = {};
    },

    // CREATE Question
    createUpdateQuestionSuccess(state) {
      state.isLoading = false;
    },

    // GET Single Question
    getQuestionSuccess(state, action) {
      state.isLoading = false;
      state.question = action.payload;
    },

    // GET Questions
    getQuestionsListSuccess(state, action) {
      state.isLoading = false;
      state.questionsList = action.payload;
    },

    getQuestionFormListSuccess(state, action) {
      state.isLoading = false;
      state.questionFormList = action.payload;
    },

    cleanQuestionFormListSuccess(state) {
      state.isLoading = false;
      state.questionFormList = [];
    },

    // Set currentSearch
    setCurrentSearch(state, action) {
      state.currentSearch = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetQuestion, setCurrentSearch } = slice.actions;

// ----------------------------------------------------------------------

export function createQuestion({
  questionData,
  exampleFileData,
  sendExample,
  successFunc,
  errFunc,
  fetch = false,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const questionBody = { ...questionData };

      if (questionData.question_type === 'file' && sendExample) {
        const { data: exampleFileResponse } = await axios.post(
          `questionnaire/example_file/`,
          exampleFileData
        );
        questionBody.example_file = [exampleFileResponse.id];
      }

      const { data } = await axios.post(
        'questionnaire/question_library/question/',
        questionBody
      );
      dispatch(slice.actions.createUpdateQuestionSuccess());

      if (fetch) {
        dispatch(getQuestionsList());
      }

      if (successFunc) {
        successFunc(data);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (errFunc) {
        errFunc(error);
      }
    }
  };
}

export function updateQuestion({
  id,
  questionData,
  exampleId,
  exampleFileData,
  sendExample,
  exampleFileDelete,
  successFunc,
  errFunc,
  fetch = false,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const questionBody = { ...questionData };

      if (questionData.question_type === 'file' && sendExample) {
        if (exampleId) {
          await axios.patch(
            `questionnaire/example_file/${exampleId}/`,
            exampleFileData
          );
        } else {
          const { data: exampleFileResponse } = await axios.post(
            `questionnaire/example_file/`,
            exampleFileData
          );
          questionBody.example_file = [exampleFileResponse.id];
        }
      } else if (exampleFileDelete && exampleId) {
        await axios.delete(`questionnaire/example_file/${exampleId}`);
        questionBody.example_file = [];
      }

      const { data } = await axios.patch(
        `questionnaire/question_library/question/${id}/?expand=question_choices,example_file`,
        questionBody
      );
      dispatch(slice.actions.createUpdateQuestionSuccess());

      if (fetch) {
        dispatch(getQuestionsList());
      }
      if (successFunc) {
        successFunc(data);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (errFunc) {
        errFunc(error);
      }
    }
  };
}

export function getQuestionsList(queryParams = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `questionnaire/question_library/question/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getQuestionsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQuestion(questionId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `questionnaire/question_library/question/${questionId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getQuestionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQuestionFormList(questionId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `base_document/?question=${questionId}&${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getQuestionFormListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
