import React, { FC, useMemo } from 'react';
import { IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import useAuth from 'src/hooks/useAuth';

interface CertLockIconProps {
  isApproved: boolean;
  isTerminal: boolean;
  disabled: boolean;
  setDisabled: (value: boolean) => void;
}

const AGCategoryStatusLock: FC<CertLockIconProps> = ({
  isApproved,
  isTerminal,
  disabled,
  setDisabled,
}) => {
  const { user } = useAuth();

  const userIsAbleToChangeCertificationStatus = useMemo(() => {
    if (user?.isComplianceManager) {
      return true;
    }
    return (
      user?.isComplianceSpecialist &&
      user?.canUnapprovedCertification &&
      !isApproved
    );
  }, [
    isApproved,
    user?.canUnapprovedCertification,
    user?.isComplianceManager,
    user?.isComplianceSpecialist,
  ]);

  const lockIcon = useMemo(() => {
    if (!isTerminal || !disabled) {
      return null;
    }

    if (userIsAbleToChangeCertificationStatus) {
      return (
        <IconButton
          size="small"
          sx={{
            flexShrink: 0,
            minWidth: '30px',
            width: '30px',
            backgroundColor: 'rgba(255, 255, 255, .25)',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, .25)' },
          }}
          color="inherit"
          onClick={() => setDisabled(false)}
        >
          <LockIcon fontSize="medium" color="inherit" />
        </IconButton>
      );
    }
    return <LockIcon color="inherit" />;
  }, [
    disabled,
    isTerminal,
    setDisabled,
    userIsAbleToChangeCertificationStatus,
  ]);

  return lockIcon;
};

export default AGCategoryStatusLock;
