// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOT_EMAIL_AUTH = '/verify_email_address';
const ROOTS_DOCS = '/docs';
const PHA_ROOT = '/pronto-admin';
const HM_ROOT = '/user';
const COMPLIANCE_ROOT = '/workbench';

// ----------------------------------------------------------------------

export const REGULATORY_AGENCY_ID = '{REGULATORY_AGENCY_ID}';
export const FORM_ID = '{FORM_ID}';
export const SIGNATURE_ID = '{SIGNATURE_ID}';
export const QUESTION_ID = '{QUESTION_ID}';
export const UNIT_ID = '{UNIT_ID}';
export const BUILDING_ID = '{BUILDING_ID}';
export const HOUSEHOLD_ID = '{HOUSEHOLD_ID}';
export const SURVEY_ID = '{SURVEY_ID}';
export const SEARCH = '{SEARCH}';
export const CONTACT_ID = '{CONTACT_ID}';
export const RULE_ID = '{RULE_ID}';
export const CERTIFICATION_CONFIG_ID = '{CERTIFICATION_CONFIG_ID}';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  resetPasswordForm: path(ROOTS_AUTH, '/reset-password/:uidb64/:token/'),
  verify: path(ROOTS_AUTH, '/verify'),
  password: path(ROOTS_AUTH, '/password'),
  oneClickLogin: path(ROOTS_AUTH, '/:auth_link_code'),
  verifyEmailAddress: path(ROOT_EMAIL_AUTH, '/:auth_email_code'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  termsOfUse: '/terms-of-use',
  cookiesPolicy: '/cookies-policy',
  privacyPolicy: '/privacy-policy',
};

export const HM_DASHBOARD = {
  root: HM_ROOT,
  general: {
    app: path(HM_ROOT, '/app'),
  },
  tenant: {
    user: {
      user: path(HM_ROOT, '/'),
      mySettings: path(HM_ROOT, '/settings'),
      addHouseholdMember: path(HM_ROOT, '/add-invite/add-household-member'),
      signatures: path(HM_ROOT, '/signatures'),
      signatureDetail: path(HM_ROOT, `/signatures/${SIGNATURE_ID}`),
      survey: path(HM_ROOT, '/survey/'),
      survey2: path(HM_ROOT, '/survey2/?s=5&p=2&mid=49&suv=1'),
      questionnaireCompleted: path(
        HM_ROOT,
        `/questionnaire-completed/${SURVEY_ID}`
      ),
    },
  },
};

export const PATH_DASHBOARD = {
  // PHA Paths
  root: PHA_ROOT,
  general: {
    app: path(PHA_ROOT, '/app'),
  },
  prontoHousingAdministration: {
    forms: {
      list: path(PHA_ROOT, '/forms'),
      listSearch: path(PHA_ROOT, `/forms/search/${SEARCH}`),
      new: path(PHA_ROOT, '/forms/new'),
      editById: path(PHA_ROOT, `/forms/${FORM_ID}/edit`),
      getById: path(PHA_ROOT, `/forms/${FORM_ID}`),
    },
    questions: {
      list: path(PHA_ROOT, '/questions'),
      listSearch: path(PHA_ROOT, `/questions/search/${SEARCH}`),
      new: path(PHA_ROOT, '/questions/new'),
      editById: path(PHA_ROOT, `/questions/${QUESTION_ID}/edit`),
    },
    templates: {
      list: path(PHA_ROOT, '/templates'),
    },
    contacts: {
      view: path(PHA_ROOT, `/contacts`),
      new: path(PHA_ROOT, `/contacts/contacts-create/`),
      edit: path(PHA_ROOT, `/contacts/contacts-create/${CONTACT_ID}/edit`),
    },
    dynamicRules: {
      list: path(PHA_ROOT, '/rules_templates/'),
      new: path(PHA_ROOT, `/rules_templates/new/`),
      edit: path(PHA_ROOT, `/rules_templates/${RULE_ID}/edit`),
    },
    certification_config: {
      list: path(PHA_ROOT, '/certification-config/'),
      edit: path(PHA_ROOT, `/certification-config/${CERTIFICATION_CONFIG_ID}`),
      /* new: path(PHA_ROOT, `/rules_templates/new/`),
       edit: path(PHA_ROOT, `/rules_templates/${RULE_ID}/edit`) */
    },
    demoSetup: {
      new: path(PHA_ROOT, `/demo_setup/`),
    },
  },
  // mail: {
  //   root: path(PHA_ROOT, '/mail'),
  //   all: path(PHA_ROOT, '/mail/all')
  // },
  // chat: {
  //   root: path(PHA_ROOT, '/chat'),
  //   new: path(PHA_ROOT, '/chat/new'),
  //   conversation: path(PHA_ROOT, '/chat/:conversationKey')
  // },
  // calendar: path(PHA_ROOT, '/calendar'),
  // kanban: path(PHA_ROOT, '/kanban'),
  user: {
    root: path(PHA_ROOT, '/'),
    profile: path(PHA_ROOT, '/user/profile'),
    account: path(PHA_ROOT, '/user/account'),
    // cards: path(PHA_ROOT, '/user/cards'),
    // list: path(PHA_ROOT, '/user/list'),
    // newUser: path(PHA_ROOT, '/user/new'),
    // editById: path(PHA_ROOT, '/user/ada-lindgren/edit'),
  },
  // eCommerce: {
  //   root: path(PHA_ROOT, '/e-commerce'),
  //   shop: path(PHA_ROOT, '/e-commerce/shop'),
  //   product: path(PHA_ROOT, '/e-commerce/product/:name'),
  //   productById: path(PHA_ROOT, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  //   list: path(PHA_ROOT, '/e-commerce/list'),
  //   newProduct: path(PHA_ROOT, '/e-commerce/product/new'),
  //   editById: path(PHA_ROOT, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
  //   checkout: path(PHA_ROOT, '/e-commerce/checkout'),
  //   invoice: path(PHA_ROOT, '/e-commerce/invoice')
  // },
  // blog: {
  //   root: path(PHA_ROOT, '/blog'),
  //   posts: path(PHA_ROOT, '/blog/posts'),
  //   post: path(PHA_ROOT, '/blog/post/:title'),
  //   postById: path(PHA_ROOT, '/blog/post/portfolio-review-is-this-portfolio-too-creative'),
  //   newPost: path(PHA_ROOT, '/blog/new-post')
  // }
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  quickstart: path(ROOTS_DOCS, '/quick-start'),
  package: path(ROOTS_DOCS, '/package'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  settings: path(ROOTS_DOCS, '/settings'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog'),
};

export const COMPLIANCE_PATHS = {
  root: COMPLIANCE_ROOT,
  general: {
    property: path(COMPLIANCE_ROOT, '/:property_slug'),
    propertyNotification: path(
      COMPLIANCE_ROOT,
      '/:property_slug/notifications'
    ),
    propertyCertWorkbench: path(
      COMPLIANCE_ROOT,
      '/:property_slug/certification-config/'
    ),
  },
  certification: {
    view: path(COMPLIANCE_ROOT, '/:property_slug/:certification_id/'),
  },
  certification_config: {
    view: path(
      COMPLIANCE_ROOT,
      '/:property_slug/certification-config/:certification_config_id'
    ),
  },
  household: {
    view: path(COMPLIANCE_ROOT, `/:property_slug/household/${HOUSEHOLD_ID}`),
  },
  applicants: {
    myApplicants: path(COMPLIANCE_ROOT, `/my-renters`),
    teamApplicants: path(COMPLIANCE_ROOT, `/team-renters`),
  },
  unit: {
    view: path(COMPLIANCE_ROOT, '/:property_slug/units/{UNIT_ID}'),
    bulkUpload: path(COMPLIANCE_ROOT, '/:property_slug/units/upload'),
    // list: path(COMPLIANCE_ROOT, '/:property_slug/units'),
    // new: path(COMPLIANCE_ROOT, '/:property_slug/units/new'),
    // editById: path(COMPLIANCE_ROOT, '/:property_slug/units/{UNIT_ID}/edit'),
  },
  building: {
    view: path(COMPLIANCE_ROOT, '/:property_slug/buildings/{BUILDING_ID}'),
  },
  user: {
    settings: path(COMPLIANCE_ROOT, '/user/settings'),
    complianceSpecialistDashboard: path(
      COMPLIANCE_ROOT,
      `/user/summary-for-compliance-specialist`
    ),
    myTasks: path(COMPLIANCE_ROOT, `/user/my-tasks`),
    teamTasks: path(COMPLIANCE_ROOT, `/user/team-tasks`),
    communications: path(COMPLIANCE_ROOT, `/user/communications`),
    conversation: path(
      COMPLIANCE_ROOT,
      `/user/communications/communications/:conversationKey`
    ),
    bulkCommunications: path(COMPLIANCE_ROOT, `/bulk_communications`),
    createBulkCommunication: path(
      COMPLIANCE_ROOT,
      '/bulk_communications/create_bulk_communication'
    ),
    bulkCommunication: path(
      COMPLIANCE_ROOT,
      '/bulk_communications/:bulk_communication_id'
    ),
    // dashboard: path(COMPLIANCE_ROOT, `/user/:active_tab`),
  },
  property: {
    setup: path(COMPLIANCE_ROOT, '/setup/:property_slug'),
    setupList: path(COMPLIANCE_ROOT, '/setup'),
    uploadUnits: path(COMPLIANCE_ROOT, '/upload_units/:property_slug'),
    uploadRentRoll: path(COMPLIANCE_ROOT, '/upload_rent_roll/:property_slug'),
  },
  // contacts: {
  //   view: path(COMPLIANCE_ROOT, `/contacts`),
  //   new: path(COMPLIANCE_ROOT, `/contacts-create/`),
  //   edit: path(COMPLIANCE_ROOT, `/contacts-create/${CONTACT_ID}/edit`),
  // },
  createQuestionnaire: path(
    COMPLIANCE_ROOT,
    '/:property_slug/create-questionnaire'
  ),
  addProperty: path(COMPLIANCE_ROOT, '/add-property'),
  addRentRoll: path(COMPLIANCE_ROOT, '/:property_slug/add-rent-roll'),
  conversations: path(COMPLIANCE_ROOT, '/:property_slug/communications'),
  conversationsCertification: path(
    COMPLIANCE_ROOT,
    '/:property_slug/:certification_id/communications'
  ),
};
