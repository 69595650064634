import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current['conversation'][key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  isMessagesLoading: false,
  isSendingMessage: false,
  error: false,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  activeConversation: {},
  participants: [],
  recipients: [],
  template: '',
};

const slice = createSlice({
  name: 'Conversations',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startSendingMessage(state) {
      state.isSendingMessage = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isMessagesLoading = false;
    },

    // GET CONTACT SUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    setTemplateSuccess(state, action) {
      state.template = action.payload;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      state.isMessagesLoading = false;
      state.activeConversation = action.payload;
    },

    // ON SEND MESSAGE
    addMessageSuccess(state, action) {
      if (state.activeConversation?.id && !action.payload.isNewConversation) {
        state.activeConversation.messages.unshift(action.payload.message);
      }
      state.isSendingMessage = false;
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      state.participants = action.payload;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      state.recipients = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setTemplateSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function sendMessage(payload, isNewConversation) {
  return async (dispatch) => {
    dispatch(slice.actions.startSendingMessage());
    try {
      const response = await axios.post(
        `communication/new_conversation/message/`,
        payload
      );
      if (response.status === 200 || response.status === 201) {
        const messageRes = await axios.get(
          `communication/new_conversation/message/${response.data.id}/?expand=attachments,views,clicks,sender`
        );

        if (messageRes.data && messageRes.data.id) {
          dispatch(
            slice.actions.addMessageSuccess({
              message: messageRes.data,
              isNewConversation,
            })
          );
        }
      } else {
        throw new Error('Error sending message');
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setConversation(conversation) {
  return async (dispatch) => {
    dispatch(slice.actions.getConversationSuccess(conversation));
  };
}
