import axios from '../utils/axios';
import parseQueryParams from 'src/utils/query';
import { AxiosResponse } from 'axios';

export default class CertificationWorkbenchService {
  public static fetchForms = async (
    queryParams,
    returnRawData = false
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      return axios
        .get(`form_library/form_table/?${parseQueryParams(queryParams)}`)
        .then((response) => {
          if (returnRawData) {
            resolve(response);
            return;
          }
          const formList = response.data;
          formList.results = (formList?.results || []).map((item) => {
            return {
              ...item,
              form: {
                ...item.form,
                hasSignatures: Boolean(
                  item.form?.has_mappings?.signatures?.length > 0
                ),
                hasMappings: Boolean(
                  item.form?.has_mappings?.questions?.length > 0 ||
                    item.form?.has_mappings?.misc?.length > 0
                ),
              },
            };
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  public static getAllDits = async (queryParams): Promise<AxiosResponse> => {
    return axios.get(`certification/dit/?${parseQueryParams(queryParams)}`);
  };
}
