import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import parseQueryParams from 'src/utils/query';
import CertificationWorkbenchService from 'src/services/CertificationWorkbench.service';

const BASE_DYNAMIC_MODAL_DATA = {
  showModal: false,
  inputType: '',
  params: {},
  options: {},
  property: null,
  label: '',
  value: null,
  initValProperty: null,
  id: null,
  useIDs: false,
  message: '',
  afterSubmit: null,
};

const BASE_INITIAL_STATE = {
  certificationConfig: null,
  formList: [],
  formListCount: null,
  isLoadingFormList: true,
  selectedForm: null,
  selectedForms: [],
  allFormsSelected: false,
  questionnaireOptions: [],
  formQuestions: null,
  isLoadingAffectedDocuments: false,
  affectedDocuments: [],
  showDeletionWarning: false,
  formToDelete: [],
  openQuestionModal: false,
  dynamicModalData: BASE_DYNAMIC_MODAL_DATA,
};

const slice = createSlice({
  name: 'CWForms',
  initialState: BASE_INITIAL_STATE,
  reducers: {
    setCertificationConfig: (state, action) => {
      state.certificationConfig = action.payload;
    },
    setFormList: (state, action) => {
      const { results, count } = action.payload;
      state.formList = results || [];
      state.formListCount = count || 0;
    },
    setIsLoadingFormList: (state, action) => {
      const value = action.payload;
      state.isLoadingFormList = value;
      if (value) {
        state.allFormsSelected = false;
      }
    },
    setSelectedForm: (state, action) => {
      state.selectedForm = action.payload;
    },
    setSelectedForms: (state, action) => {
      const formID = action.payload;
      if (state.selectedForms.includes(formID)) {
        state.selectedForms = state.selectedForms.filter((id) => id !== formID);
      } else {
        state.selectedForms.push(formID);
      }
    },
    clearSelectedForms: (state) => {
      state.selectedForms = [];
    },
    toggleSelectAllForms: (state) => {
      if (state.allFormsSelected) {
        const filteredFormsIDs = state.formList.map(({ form }) => form.id);
        state.selectedForms = state.selectedForms.filter(
          (id) => !filteredFormsIDs.includes(id)
        );
      } else {
        const newValues = state.formList
          .filter(({ form }) => {
            return !state.selectedForms.includes(form.id);
          })
          .map(({ form }) => form.id);
        state.selectedForms = [...state.selectedForms, ...newValues];
      }
    },
    setAllFormsSelected: (state) => {
      state.allFormsSelected = Boolean(
        !state.isLoadingFormList &&
          state.formList.length &&
          state.formList.every(({ form }) =>
            state.selectedForms.includes(form.id)
          )
      );
    },
    updateQuestionnaireOptions: (state, action) => {
      state.questionnaireOptions = {
        ...state.questionnaireOptions,
        ...action.payload,
      };
    },
    setFormQuestions: (state, action) => {
      state.formQuestions = action.payload;
    },
    setOpenQuestionModal: (state, action) => {
      state.openQuestionModal = action.payload;
    },
    setIsLoadingAffectedDocuments: (state, action) => {
      state.isLoadingAffectedDocuments = action.payload;
    },
    setAffectedDocuments: (state, action) => {
      state.affectedDocuments = action.payload;
    },
    setDeletionWarning: (state, action) => {
      state.showDeletionWarning = action.payload;
    },
    setFormToDelete: (state, action) => {
      state.formToDelete = action.payload;
    },
    setDynamicModalData: (state, action) => {
      state.dynamicModalData = action.payload;
    },
    toggleFormTemplate(state, action) {
      const forms = [...state.formList];
      const index = forms.findIndex(
        (element) => element.form.id === action.payload.id
      );
      forms[index].form.template = action.payload.template;
      state.formList = forms;
    },
    toggleAutoRequestSignatures(state, action) {
      const forms = [...state.formList];
      const index = forms.findIndex(
        (element) => element.form.id === action.payload.id
      );
      forms[index].form.auto_request_signatures =
        action.payload.auto_request_signatures;
      state.formList = forms;
    },
    setDocumentForm(state, action) {
      const forms = [...state.formList];
      const index = forms.findIndex(
        (element) => element.form.id === action.payload.id
      );
      forms[index].form[action.payload.initValProperty] =
        action.payload.values[action.payload.initValProperty];
      state.formList = forms;
    },
    setDocumentFormQuestions(state, action) {
      const forms = [...state.formList];
      const index = forms.findIndex(
        (element) => element.form.id === action.payload.id
      );
      forms[index].form.parent_qit = action.payload.parent_qit;
      forms[index].form.parent_choice = action.payload.parent_choice;
      state.formList = forms;
    },
    resetDynamicModalData: (state) => {
      state.dynamicModalData = BASE_DYNAMIC_MODAL_DATA;
    },
    resetSlice: () => {
      return BASE_INITIAL_STATE;
    },
  },
});

export default slice.reducer;

export const {
  setCertificationConfig,
  setFormList,
  setIsLoadingFormList,
  setSelectedForm,
  setSelectedForms,
  clearSelectedForms,
  toggleSelectAllForms,
  setAllFormsSelected,
  setFormQuestions,
  setOpenQuestionModal,
  updateQuestionnaireOptions,
  setIsLoadingAffectedDocuments,
  setAffectedDocuments,
  setDeletionWarning,
  setFormToDelete,
  setDynamicModalData,
  toggleFormTemplate,
  toggleAutoRequestSignatures,
  setDocumentForm,
  setDocumentFormQuestions,
  resetDynamicModalData,
  resetSlice,
} = slice.actions;

export function getFormList(queryParams) {
  return async (dispatch) => {
    dispatch(setIsLoadingFormList(true));
    try {
      const response = await CertificationWorkbenchService.fetchForms(
        queryParams
      );
      dispatch(setIsLoadingFormList(false));
      dispatch(setFormList(response.data));
      dispatch(setAllFormsSelected());
    } catch (error) {
      dispatch(setIsLoadingFormList(false));
      dispatch(setFormList([]));
      dispatch(setAllFormsSelected());
    }
  };
}
