// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// icons
/* import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
 import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
 import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'; */
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAddOutlined';
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined';
import React from 'react';
// ----------------------------------------------------------------------

const regulatoryManagerConfig = [
  {
    subheader: 'Pronto Administration',
    items: [
      {
        title: 'Forms Library',
        path: PATH_DASHBOARD.prontoHousingAdministration.forms.list,
        icon: <ListAltOutlinedIcon />,
        compliance: true,
      },
      {
        title: 'Questions Library',
        path: PATH_DASHBOARD.prontoHousingAdministration.questions.list,
        icon: <LiveHelpOutlinedIcon />,
        compliance: true,
      },
      {
        title: 'Templates Library',
        path: PATH_DASHBOARD.prontoHousingAdministration.templates.list,
        icon: <DescriptionOutlinedIcon />,
        compliance: true,
      },
      {
        title: 'Rule Templates',
        path: PATH_DASHBOARD.prontoHousingAdministration.dynamicRules.list,
        icon: <RuleFolderOutlinedIcon />,
        compliance: true,
      },
      {
        title: 'Certification Configuration',
        path: PATH_DASHBOARD.prontoHousingAdministration.certification_config
          .list,
        icon: <NoteAddIcon />,
        compliance: true,
      },
      {
        title: 'Demo Setup',
        path: PATH_DASHBOARD.prontoHousingAdministration.demoSetup.new,
        icon: <AddBusinessOutlinedIcon />,
        compliance: true,
      },
      /*{
       title: 'Contacts',
       path: PATH_DASHBOARD.prontoHousingAdministration.contacts.view,
       icon: <PermIdentityIcon />
       }*/
    ],
  },
];

export default regulatoryManagerConfig;
