import React from 'react';
// routes
import { HM_DASHBOARD } from '../../routes/paths';

// icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Icon } from '@iconify/react';
import baselineDraw from '@iconify/icons-ic/baseline-draw';

const tenantSidebarConfig = (memberType, householdMember) => {
  const sideMenu = [
    {
      subheader: 'General',
      items: [
        {
          title: 'Home',
          path: HM_DASHBOARD.tenant.user.user,
          icon: <DashboardIcon />,
          tenant: true,
        },
      ],
    },
    {
      subheader: 'eSign',
      items: [
        {
          title: 'eSign',
          path: HM_DASHBOARD.tenant.user.signatures,
          icon: <Icon icon={baselineDraw} width="25" />,
          tenant: true,
        },
      ],
    },
  ];
  if (
    memberType.toLowerCase() === 'head of household' ||
    memberType.toLowerCase() === 'non-household member emergency contact' ||
    householdMember?.is_helper
  ) {
    sideMenu.splice(1, 0, {
      subheader: 'Invite Members',
      items: [
        {
          title: 'Add Household Member',
          path: HM_DASHBOARD.tenant.user.addHouseholdMember,
          icon: <AssignmentIcon />,
          tenant: true,
        },
      ],
    });
  }
  return sideMenu;
};

export default tenantSidebarConfig;
