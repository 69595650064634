import jwtDecode from 'jwt-decode';
interface JwtToken {
  sub: string;
  name: string;
  admin?: boolean;
  iat?: number;
  exp?: number;
  [key: string]: unknown;
}
// ----------------------------------------------------------------------

const removeTokensMetadata = (): void => {
  cleanupOldTokens();
  cleanupTokens();
  window.location.href = '/';
};

const setSessionTokenDetails = (
  accessToken: string,
  refreshToken: string
): void => {
  cleanupOldTokens();
  setTokenMetadataInLocalStorage(accessToken, 'accessTokenMetadata');
  setTokenMetadataInLocalStorage(refreshToken, 'refreshTokenMetadata');
};

// TODO - Remove this function in the future
const cleanupOldTokens = (): void => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refresh');
};

// TODO - Remove this function in the future
const removeUserPilotFromLocalStorage = (): void => {
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);

    if (key.startsWith('userpilot')) {
      localStorage.removeItem(key);
    }
  }
};

const cleanupTokens = (): void => {
  localStorage.removeItem('accessTokenMetadata');
  localStorage.removeItem('refreshTokenMetadata');
};

const setTokenMetadataInLocalStorage = (token: string, key: string): void => {
  if (!token) {
    return;
  }
  const decodedToken = jwtDecode(token) as JwtToken;
  const tokenData = {
    exp: decodedToken.exp,
    iat: decodedToken.iat,
  };
  localStorage.setItem(key, JSON.stringify(tokenData));
};

const isTokenMetaValid = (metaKey: string): boolean => {
  const tokenMetadata = window.localStorage.getItem(metaKey);
  if (!tokenMetadata) {
    return false;
  }
  const decodedToken = JSON.parse(tokenMetadata);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

export {
  setSessionTokenDetails,
  removeTokensMetadata,
  removeUserPilotFromLocalStorage,
  cleanupTokens,
  isTokenMetaValid,
  setTokenMetadataInLocalStorage,
};
