import { useState, useEffect } from 'react';

interface BroadcastChannelHook {
  message: any;
  error: Event | null;
  sendMessage: (message: any) => void;
  resetMessage: () => void;
}

const useBroadcastChannel = (channelName: string): BroadcastChannelHook => {
  const [message, setMessage] = useState<any>(null);
  const [error, setError] = useState<Event | null>(null); // Update error type to Event
  const [channel, setChannel] = useState<BroadcastChannel | null>(null);

  useEffect(() => {
    let newChannel: BroadcastChannel | null = null;
    try {
      newChannel = new BroadcastChannel(channelName);

      newChannel.onmessage = (event) => {
        setMessage(event.data);
      };

      // Handle errors in onmessageerror directly
      newChannel.onmessageerror = (event) => {
        setError(event);
      };

      setChannel(newChannel);
    } catch (err) {
      setError(err);
    }

    return () => {
      if (newChannel) {
        newChannel.close();
      }
    };
  }, [channelName]);

  const sendMessage: (message: any) => void = (message) => {
    if (channel) {
      channel.postMessage(message);
    }
  };

  const resetMessage: () => void = () => {
    setMessage(null);
  };

  return { message, error, sendMessage, resetMessage };
};

export default useBroadcastChannel;
