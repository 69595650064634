import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
  Box,
  InputAdornment,
  OutlinedInput,
  Popover,
  Typography,
} from '@mui/material';
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
// icons
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import arrowIosDownwardOutline from '@iconify/icons-eva/arrow-ios-downward-outline';
// redux
import { resetData } from 'src/redux/slices/ProntoHousingAdministration/QuestionMapping';
import {
  getAvailableProperties,
  setSelectedProperty,
} from 'src/redux/slices/Compliance/Property';
// routes
import { COMPLIANCE_PATHS } from '../../routes/paths';
import BNLabel from './BNLabel';
// ----------------------------------------------------------------------

const PropertyBoxStyle = styled(Box)(({ theme }) => ({
  borderLeft: `3px solid ${theme.palette.background.default}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  '&:hover': {
    backgroundColor: `${theme.palette.action.hover} !important`,
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
}));

const PropertyLabel = ({ property, selectProperty }) => {
  const theme = useTheme();
  return (
    <PropertyBoxStyle
      style={{ cursor: 'pointer' }}
      px={2}
      py={1}
      onClick={() => {
        selectProperty(property);
      }}
    >
      <Box>
        <Typography
          // variant="body2"
          style={{ lineHeight: '10px' }}
          mt={1}
          color={theme.palette.text.primary}
        >
          {property.name}
        </Typography>
        <Typography variant="caption" color="text.disabled">
          {property.company_name}
        </Typography>
      </Box>

      {property?.building_number && <BNLabel bn={property?.building_number} />}
    </PropertyBoxStyle>
  );
};

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
  cursor: 'pointer',
}));

// const AddPropertyStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderTop: `1px solid ${theme.palette.divider} !important`,
//   cursor: 'pointer',

//   '&:hover': {
//     backgroundColor: `${theme.palette.action.hover} !important`,
//   }
// }));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  borderRadius: '0',
  '& fieldset': {
    border: '0px',
  },
}));

// ----------------------------------------------------------------------

export default function PropertyDropdown() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { selectedProperty, availableProperties } = useSelector(
    (state) => state.property
  );
  const [selectOpen, setSelectOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [popId, setPopId] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const isUserDashboard =
    pathname.startsWith('/workbench/user') ||
    pathname.startsWith('/workbench/team-renters');

  const _getProperties = () => {
    dispatch(
      getAvailableProperties({
        expand: ['portfolio', 'configured_programs'],
        fields: [
          'id',
          'name',
          'company_name',
          'slug',
          'setup_completed',
          'configured_programs.id',
          'configured_programs.display_name',
          'setup_completed',
          'portfolio.id',
          'portfolio.property_owner_company',
          'portfolio.name',
          'portfolio.slug',
          'content_type_id',
          'building_number',
        ],
        ...(search ? { search } : {}),
      })
    );
  };

  useEffect(() => {
    if (search !== null) {
      const timeOutId = setTimeout(() => {
        _getProperties();
      }, 500);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleUpdateSearch = (event) => {
    setSearch(event.target.value);
  };

  /*
   const openAddProperty = (event) => {
   // dispatch(setSelectedProperty(null));
   handleClose();
   setSearch('');
   navigate('add-property');
   };
   */

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectOpen(Boolean(event.currentTarget));
    _getProperties();
    setPopId('simple-popover');
  };

  const handleClose = () => {
    setPopId(undefined);
    setSelectOpen(false);
    setAnchorEl(null);
  };

  const selectProperty = (property) => {
    // reset mapping data before change to a new property
    if (property?.id !== selectedProperty?.id) {
      dispatch(resetData());
    }
    dispatch(setSelectedProperty(property));
    handleClose();
    setSearch('');
    navigate(
      COMPLIANCE_PATHS.general.property.replace(':property_slug', property.slug)
    );
  };

  return (
    <>
      <AccountStyle onClick={handleClick}>
        <Box width="100%">
          {selectedProperty?.name ? (
            <Box style={{ color: theme.palette.grey[700] }} width="100%">
              <Typography
                variant="body2"
                style={{ lineHeight: '100%' }}
                mt={1}
                color={theme.palette.text.primary}
              >
                {selectedProperty.name ? selectedProperty?.name : ''}
              </Typography>
              <Typography variant="caption" color="text.disabled">
                {selectedProperty.name ? selectedProperty?.company_name : ''}
              </Typography>
            </Box>
          ) : (
            <Box style={{ color: theme.palette.grey[700] }} width="100%">
              {isUserDashboard && (
                <Typography
                  variant="body2"
                  style={{ lineHeight: '100%' }}
                  mt={1}
                  color={theme.palette.text.primary}
                >
                  <b>My Workbench</b>
                </Typography>
              )}
              <Typography variant="caption" color="text.disabled">
                {isUserDashboard
                  ? 'Switch to property.'
                  : 'Click to select a property.'}
              </Typography>
            </Box>
          )}
        </Box>
        <Icon
          icon={arrowIosDownwardOutline}
          width="30px"
          style={{ color: theme.palette.grey[700], float: 'right' }}
        />
      </AccountStyle>
      <Popover
        id={popId}
        open={selectOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        width="230px"
      >
        <Box borderBottom={`1px solid ${theme.palette.divider}`}>
          <SearchStyle
            value={search}
            onChange={handleUpdateSearch}
            placeholder="Search property..."
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            }
          />
        </Box>
        <Box
          align="left"
          borderBottom={`1px solid ${theme.palette.divider}`}
          py={1}
          pl={2}
        >
          <Typography variant="caption" color={theme.palette.text.secondary}>
            Properties
          </Typography>
        </Box>

        {availableProperties && availableProperties.length > 0 ? (
          <Box
            sx={{
              maxHeight: '310px',
              overflow: 'scroll',
            }}
          >
            {availableProperties.map((property) => {
              return (
                <PropertyLabel
                  key={property.slug}
                  property={property}
                  selectProperty={selectProperty}
                />
              );
            })}
          </Box>
        ) : (
          <Box>
            <Typography
              variant="body1"
              align="center"
              my={2}
              color="text.disabled"
            >
              No properties found
            </Typography>
          </Box>
        )}
        {/* <AddPropertyStyle ml={1} onClick={openAddProperty}>
         <Icon icon={plusOutline} />
         <Box>
         <Typography variant="body2" ml={1}>Add Property</Typography>
         </Box>
         </AddPropertyStyle> */}
      </Popover>
    </>
  );
}
