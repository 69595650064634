import { motion } from 'framer-motion';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Button, Container, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
// components
import BlockIcon from '@mui/icons-material/Block';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function ForbiddenPage() {
  return (
    <RootStyle title="Permission Denied | Pronto Housing">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Permission Denied
              </Typography>
            </motion.div>

            <Typography sx={{ color: 'text.secondary' }}>
              You do not have permission to access this page.
            </Typography>

            <motion.div variants={varBounceIn}>
              <BlockIcon sx={{ color: '#E1E1E1', fontSize: 140, py: 2 }} />
            </motion.div>

            <Button
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Go Home
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
