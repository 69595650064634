import React, { Suspense, lazy, FC } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import PageLoadError from '../pages/PageLoadError';
import { datadogAddAction } from 'src/pages/tenant/QuestionnaireV4/utils';

interface Props {
  [key: string]: unknown;
}

const ComponentLoader = (
  componentImport: () => Promise<{ default: React.ComponentType<unknown> }>
): FC<Props> => {
  const LazyComponent = lazy(async () => {
    const retryLoad = JSON.parse(
      window.localStorage.getItem('retry-component-load') || 'true'
    );

    try {
      const component = await componentImport();
      window.localStorage.setItem('retry-component-load', 'true');
      return component;
    } catch (error) {
      if (retryLoad) {
        window.localStorage.setItem('retry-component-load', 'false');
        window.location.reload();
      }
      console.error(error);
      datadogAddAction(error, 'Chunk Load Error');
      return { default: () => <PageLoadError /> };
    }
  });

  const LoadableComponent: FC<Props> = (props) => {
    const { pathname } = useLocation();
    const isPHA = pathname.includes('pronto-admin');

    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              ...(!isPHA && {
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 9999,
                position: 'fixed',
              }),
            }}
          />
        }
      >
        <LazyComponent {...props} />
      </Suspense>
    );
  };

  return LoadableComponent;
};

export default ComponentLoader;
