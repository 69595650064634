import axios from 'src/utils/axios';
import { AxiosResponse } from 'axios';
import { AGGridConfig } from 'src/types/AGGridConfig';

export const getGridConfig = (name: string): Promise<AxiosResponse> => {
  return axios.get(`ag_grid_configuration/?name=${name}`);
};

export const createGridConfig = (
  payload: AGGridConfig
): Promise<AxiosResponse> => {
  return axios.post(`ag_grid_configuration/`, payload);
};

export const updateGridConfig = (
  id: number,
  payload: AGGridConfig
): Promise<AxiosResponse> => {
  return axios.patch(`ag_grid_configuration/${id}/`, payload);
};
