import { useEffect, useRef } from 'react';
import { GROUP_RENDER_TYPES, TYPES } from './constants';

export const CUSTOM_DATA_KEY = 'custom-data';
export const TOOLTIP_TYPES = {
  qit: 'qit',
  sit: 'sit',
  sitDate: 'sitDate',
  sitName: 'sitName',
  mit: 'mit',
  calculation: 'calculation',
};

const DIVIDER = '###';

export const reduceQits = (acc, curr) => {
  let result = [];
  if (!curr?.contextId) {
    result = [...acc, curr];
  }

  if (curr?.contextId && curr?.qits?.length) {
    result = [...acc, ...curr.qits];
  }

  return result;
};

export function buildTooltipDom(data) {
  const container = document.createElement('div');
  const title = document.createElement('h2');

  container.classList.add('MappingTooltip');

  // title
  title.classList.add('MappingTooltip-title');
  title.innerText = data?.label;
  container.appendChild(title);

  for (const key in data?.fields) {
    if (data?.fields && key in data.fields && data.fields[key]?.value) {
      const field = data?.fields[key];
      const p = document.createElement('p');
      const strong = document.createElement('strong');
      const span = document.createElement('span');

      p.classList.add('MappingTooltip-field');
      strong.innerText = `${field?.name}: `;
      span.innerText = field?.value;

      p.appendChild(strong);
      p.appendChild(span);
      container.appendChild(p);
    }
  }

  return container;
}

export function getAnnotationName(qit) {
  if (!qit) {
    return null;
  }
  const forCompliance = qit?.for_compliance ? 'For Compliance' : 'For Resident';

  if (qit?.contextName) {
    return `${qit?.contextName}\n${qit.section.name} / ${qit.page.name} / ${qit.question_group.name}`;
  } else if (!qit?.contextName && qit?.question) {
    return `${qit.question.name}\n${qit.section.name} / ${qit.page.name} / ${qit.question_group.name}\n${forCompliance}`;
  }
}

export function buildTooltipData(data, type, householdMemberTypes = []) {
  if (!data) {
    return null;
  }

  const buildedData = { fields: {} };
  const isContext = Boolean(data?.question_context);

  if (type === TOOLTIP_TYPES.qit) {
    buildedData.label = 'Questionnaire';

    if (isContext) {
      buildedData.fields.contextName = {
        name: 'Context name',
        value: data?.question_context?.context_template?.name,
      };
    }

    if (!isContext) {
      buildedData.fields.question = {
        name: 'Question',
        value: data?.qit?.question?.name,
      };
      buildedData.fields.choices = {
        name: 'Choices',
        value: data?.qit?.question?.question_choices?.length > 0 && data?.text,
      };
    }

    if (data?.qit?.question?.question_choices?.length > 0 && 'blank' in data) {
      buildedData.fields.blank = {
        name: 'Non-Selected',
        value: data?.blank ? 'Yes' : 'No',
      };
    }

    buildedData.fields.renderType = {
      name: 'Render Type',
      value: data?.question_render_type,
    };
    buildedData.fields.section = {
      name: 'Section',
      value: data?.qit?.section?.name,
    };
    buildedData.fields.page = { name: 'Page', value: data?.qit?.page?.name };
    buildedData.fields.group = {
      name: 'Group',
      value: data?.qit?.question_group?.name,
    };
    buildedData.fields.groupType = {
      name: 'Group Type',
      value: data?.qit?.question_group?.type,
    };

    if (!isContext) {
      buildedData.fields.groupRenderType = {
        name: 'Group Render Type',
        value:
          GROUP_RENDER_TYPES[data?.question_group_render_type]?.displayName,
      };
      buildedData.fields.groupRenderTypeChoice = {
        name: 'Group Render Type Choice',
        value: data?.question_group_render_type_choice,
      };
      buildedData.fields.forCompliance = {
        name: 'For',
        value: data.qit.for_compliance ? 'Compliance' : 'Resident',
      };
      buildedData.fields.hhmt = {
        name: 'House Hold Member Types',
        value: (data?.household_member_type || [])
          .map((el) => {
            const found = householdMemberTypes.find((hhmt) => hhmt.id === el);
            return found?.display_name || found?.name || '';
          })
          .join(', '),
      };
    }
  }
  if (
    [TOOLTIP_TYPES.sit, TOOLTIP_TYPES.sitDate, TOOLTIP_TYPES.sitName].includes(
      type
    )
  ) {
    buildedData.label = 'eSign';
    buildedData.fields.name = { name: 'Name', value: data?.sit?.name };
    buildedData.fields.isDate = {
      name: 'Is signature date',
      value: data?.is_date ? 'Yes' : 'No',
    };
    buildedData.fields.isName = {
      name: 'Is signature name',
      value: data?.is_name ? 'Yes' : 'No',
    };
    buildedData.fields.signatureCollection = {
      name: 'Signature Collection',
      value: data?.sit?.signature_collection,
    };
    buildedData.fields.signatureType = {
      name: 'Signature Type',
      value: data?.sit?.signature_type,
    };
    buildedData.fields.compliance = {
      name: 'Compliance',
      value: data?.sit?.compliance ? 'Yes' : 'No',
    };
    buildedData.fields.unassigned = {
      name: 'Unassigned',
      value: data?.sit?.unassigned ? 'Yes' : 'No',
    };
    buildedData.fields.hhmt = {
      name: 'Needed Signature',
      value: (data?.sit?.needed_signatures || [])
        .map((el) => {
          const found = householdMemberTypes.find((hhmt) => hhmt.id === el);
          return found?.display_name || found?.name || '';
        })
        .join(', '),
    };
  }
  if (type === TOOLTIP_TYPES.mit) {
    buildedData.label = 'Miscellaneous';
    buildedData.fields.name = { name: 'Field', value: data?.mit?.field_name };
    buildedData.fields.renderType = {
      name: 'Render Type',
      value: data?.render_type,
    };
  }

  if (type === TOOLTIP_TYPES.calculation) {
    const calc = data?.calculation || data?.bucket_calculation;
    buildedData.label = data?.bucket_calculation
      ? 'Bucket Calculation'
      : 'Calculation';
    buildedData.fields.name = { name: 'Name', value: calc?.name };
    buildedData.fields.type = { name: 'Type', value: calc?.type };
    buildedData.fields.formula = { name: 'Formula', value: calc?.formula };
    buildedData.fields.hhmt = {
      name: 'Household Member Types',
      value: (data?.household_member_type || [])
        .map((el) => {
          const found = householdMemberTypes.find((hhmt) => hhmt.id === el);
          return found?.display_name || found?.name || '';
        })
        .join(', '),
    };
    buildedData.fields.groupRenderType = {
      name: 'Group Render Type',
      value: data?.question_group_render_type_choice,
    };
  }

  return buildedData;
}

const getCalcAnnotName = (annotation) => {
  if (annotation?.calculation?.name) {
    return annotation.calculation.name;
  }
  if (annotation?.bucket_calculation?.name) {
    return `Bucket Calculation / ${annotation?.bucket_calculation?.name}`;
  }
  return '';
};

export const buildAnnotations = (
  viewerInstance,
  data,
  configOverrides = {},
  householdMemberTypes = []
) => {
  if (!Array.isArray(data)) {
    return;
  }
  const annotationsToDraw = [];
  const annotationConfig = {
    FillColor: [238, 239, 241],
    TextColor: [84, 98, 110],
    StrokeColor: [218, 220, 222],
    Locked: false,
    LockedContents: true,
    NoRotate: true,
    NoResize: true,
    NoMove: true,
    ...configOverrides,
  };

  for (const annotation of data) {
    const freeText = new viewerInstance.Annotations.FreeTextAnnotation();

    freeText.PageNumber = annotation.page;
    freeText.setContents(
      getAnnotationName({
        ...annotation.qit,
        contextName:
          annotation?.contextName ||
          annotation?.question_context?.context_template?.name ||
          null,
      }) ||
        getCalcAnnotName(annotation) ||
        annotation.text
    );
    freeText.X = annotation.x;
    freeText.Y = annotation.y;
    freeText.Width = annotation.width;
    freeText.Height = annotation.height;
    freeText.NoRotate = annotationConfig.NoRotate;
    freeText.NoResize = annotationConfig.NoResize;
    freeText.NoMove = annotationConfig.NoMove;
    freeText.FillColor = new viewerInstance.Annotations.Color(
      ...annotationConfig.FillColor
    );
    freeText.TextColor = new viewerInstance.Annotations.Color(
      ...annotationConfig.TextColor
    );
    freeText.StrokeColor = new viewerInstance.Annotations.Color(
      ...annotationConfig.StrokeColor
    );
    freeText.setPadding(new viewerInstance.Annotations.Rect(0, 0, 0, 0));
    freeText.FontSize = '9pt';
    freeText.Locked = annotationConfig.Locked;
    freeText.LockedContents = annotationConfig.LockedContents;
    freeText.TextVerticalAlign = 'bottom';
    // PDF.js version 8
    freeText.setCustomData(
      CUSTOM_DATA_KEY,
      JSON.stringify({
        isProntoAnnotation: true,
        question_id: annotation?.question,
        mit: annotation?.mit?.id,
        sit: annotation?.sit?.id,
        is_date: annotation?.is_date || false,
        blank: annotation?.blank || false,
        is_name: annotation?.is_name || false,
        signature_reference: annotation?.signature_reference || null,
        qit: annotation?.qit?.id,
        calculation:
          annotation?.calculation?.id ||
          annotation?.bucket_calculation?.id ||
          null,
        signatureId: annotation?.signatureId || undefined,
        id: annotation?.id,
        question_context: annotation?.question_context?.id || null,
        signature_completed: annotation?.signature_completed || false,
        tooltipData:
          buildTooltipData(
            annotation?.qit && annotation,
            TOOLTIP_TYPES.qit,
            householdMemberTypes
          ) ||
          buildTooltipData(
            annotation?.mit && annotation,
            TOOLTIP_TYPES.mit,
            householdMemberTypes
          ) ||
          buildTooltipData(
            (annotation?.calculation || annotation?.bucket_calculation) &&
              annotation,
            TOOLTIP_TYPES.calculation,
            householdMemberTypes
          ) ||
          buildTooltipData(
            annotation?.sit && annotation,
            TOOLTIP_TYPES.sit,
            householdMemberTypes
          ),
      })
    );

    annotationsToDraw.push(freeText);
  }

  viewerInstance.Core.annotationManager.addAnnotations(annotationsToDraw);
};

export const mapAnnotationFromLibToJson = (annotation, overrides) => {
  const CustomData = parseJson(annotation.getCustomData(CUSTOM_DATA_KEY));

  return {
    x: annotation.X,
    y: annotation.Y,
    height: annotation.Height,
    width: annotation.Width,
    text: annotation?.text,
    page: annotation.PageNumber,
    localId: annotation?.Id || undefined, // local library id
    id: CustomData?.id || undefined, // backend id
    question_id: CustomData?.question_id || undefined,
    ...overrides,
  };
};

export function mapStoreMapping(item) {
  return {
    id: item?.id,
    qit: item?.qit?.id || null,
    mit: item?.mit?.id || null,
    sit: item?.sit?.id || null,
    sit__name: item?.sit?.name,
    calculation: item?.calculation || null,
    bucket_calculation: item?.bucket_calculation || null,
    signature_reference: item?.signature_reference || null,
    is_date: item?.is_date || false,
    blank: item?.blank || false,
    is_name: item?.is_name || false,
    compliance: item?.sit?.compliance || false,
    question: item?.question || null,
    choices: item?.choices || null,
    form_page: item?.form_page,
    section_name: item?.qit?.section?.name || null,
    form_page_name: item?.qit?.page?.name || null,
    question_group_name: item?.qit?.question_group?.name || null,
    question_group_render_type: item?.question_group_render_type || null,
    question_group_render_type_choice:
      item?.question_group_render_type_choice || null,
    question_group: item?.qit?.question_group?.id || null,
    question_group_type: item?.qit?.question_group?.type || null,
    household_member_type: item?.household_member_type || [],
    unassigned: item?.sit?.unassigned || false,
    question_context: item?.question_context?.id || null,
    contextName: item?.question_context?.context_template?.name || null,
  };
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  return ref.current;
}

export function parseJson(str) {
  let value = {};
  try {
    value = JSON.parse(str);
  } catch (e) {
    return value;
  }

  return value;
}

const filterCalc = (calc, search) =>
  (calc?.name || '').toLowerCase().includes(search);

export const filterTicBuckets = (searchString = '', items = []) => {
  const value = searchString.toLowerCase();
  let result = [];

  if (!value) {
    return items;
  }

  result = items.filter((tb) =>
    tb.calculations.some((calc) => filterCalc(calc, value))
  );

  result = result.map((tb) => ({
    ...tb,
    calculations: (tb?.calculations || []).filter((calc) =>
      filterCalc(calc, value)
    ),
  }));

  return result;
};

export const buildQuestionnaireExpandedItems = (
  sections = [],
  mappings = []
) => {
  const toExpand = [];
  const qitsMapped = mappings.map((mapping) => mapping.qit);
  const questionnaireNode = `${
    TYPES.questionnaire
  }${DIVIDER}${0}${DIVIDER}${0}`;

  for (let x = 0; x < sections?.length; x++) {
    const section = sections[x];
    const sectionNode = `${TYPES.section}${DIVIDER}${section.id}${DIVIDER}${x}`;
    const pages = section.pages;

    for (let y = 0; y < pages.length; y++) {
      const page = section.pages[y];
      const pageNode = `${TYPES.page}${DIVIDER}${page.id}${DIVIDER}${y}`;
      const groups = page.question_groups;

      for (let z = 0; z < groups.length; z++) {
        const group = page.question_groups[z];
        const groupNode = `${TYPES.questionGroup}${DIVIDER}${
          group.id
        }${DIVIDER}${z}${DIVIDER}${DIVIDER}${JSON.stringify({
          sectionIndex: x,
          pageIndex: y,
        })}`;
        const resultQits = group?.questions.reduce(reduceQits, []);

        if (
          resultQits.some(
            (question) =>
              question.qit?.is_mapped &&
              question.qit?.id &&
              qitsMapped.includes(question.qit.id)
          )
        ) {
          if (!toExpand.includes(questionnaireNode)) {
            toExpand.push(questionnaireNode);
          }
          if (!toExpand.includes(sectionNode)) {
            toExpand.push(sectionNode);
          }
          if (!toExpand.includes(pageNode)) {
            toExpand.push(pageNode);
          }
          if (!toExpand.includes(groupNode)) {
            toExpand.push(groupNode);
          }
        }
      }
    }
  }

  return toExpand;
};
