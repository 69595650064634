import Papa from 'papaparse';
import { camelCase } from 'lodash';

export const UNITS_HEADERS = [
  { id: 'name', label: 'Name' },
  { id: 'type', label: 'Type' },
  { id: 'building', label: 'Building' },
  { id: 'rent', label: 'Rent' },
  { id: 'ami', label: 'AMI' },
  { id: 'preferenceGroups', label: 'Preference Groups' },
  { id: 'squareFeet', label: 'Square Feet' },
  { id: 'programTypes', label: 'Program Types' },
];

export const RENT_ROLL_HEADERS = [
  { id: 'household', label: 'Household' },
  { id: 'certification', label: 'Certification' },
  { id: 'unit', label: 'Unit' },
  { id: 'building', label: 'Building' },
  { id: 'applicantPool', label: 'Applicant Pool' },
  { id: 'firstName', label: 'First Name' },
  { id: 'middleName', label: 'Middle Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'type', label: 'Type' },
  { id: 'email', label: 'Email' },
  { id: 'mobilePhone', label: 'Mobile Phone' },
  { id: 'birthdate', label: 'Birthdate' },
  { id: 'preferredLanguage', label: 'Preferred Language' },
  { id: 'leaseStartDate', label: 'Lease Start Date' },
  { id: 'leaseEndDate', label: 'Lease End Date' },
  { id: 'moveOutDate', label: 'Move Out Date' },
  { id: 'moveInDate', label: 'Move In Date' },
  { id: 'effectiveDate', label: 'Effective Date' },
  { id: 'logNumber', label: 'Log Number' },
  { id: 'prefGroups', label: 'Pref Groups' },
];

export const parseFile = (file, download) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      encoding: 'UTF-8',
      skipEmptyLines: true,
      download: download,
      transformHeader: function (header) {
        return camelCase(header);
      },
      error: function (error) {
        reject(error);
      },
      complete: function (results) {
        resolve(results);
      },
    });
  });
};

export const getColorLabel = (header, rowErrors) => {
  if (rowErrors && rowErrors[header]) {
    if (rowErrors[header].error) {
      return 'error';
    } else {
      return 'warning';
    }
  }
  return 'success';
};

export const humanFileSize = (size) => {
  let i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB'][i]
  );
};

export const getInitialStep = (setup) => {
  let step = 0;
  if (setup && setup.id) {
    if (setup.completed) {
      return 4;
    } else {
      if (setup.units_completed) {
        step = 2;
        if (setup.rent_roll_completed || setup.rent_roll_skipped) {
          step = 3;
        }
      } else if (setup.units_skipped) {
        step = 3;
      }
      if (setup.certification_completed || setup.certification_skipped) {
        step = 4;
      }
    }
  }
  return step;
};

export const objToFormData = (obj) => {
  const formData = new FormData();
  for (const key in obj) {
    if (Boolean(obj[key]) || typeof obj[key] === 'number') {
      formData.append(key, obj[key]);
    }
  }
  return formData;
};

export const validateHasErrors = (errors) => {
  return Boolean(
    errors &&
      Object.values(errors).some((row) => {
        let r = {};
        r = Object.assign(r, row);
        return Object.values(r).some((header) => header.error);
      })
  );
};
