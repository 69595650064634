import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
// material
import { Box, Typography } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  width: PropTypes.any,
};

export default function Logo({ sx, width, isCompliance = true }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  const { user } = useAuth();
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && user.property?.logo) {
      setUrl(user.property.logo);
    }
    setLoading(false);
  }, [user, setLoading]);

  return url && !isCompliance && !loading ? (
    <>
      <Box sx={{ width: width || 150, ...sx }} align="left">
        <img style={{ width: '100%' }} src={url} alt="Property Logo" />
      </Box>
      <Typography
        color="text.disabled"
        variant="caption"
        sx={{ textDecoration: 'none' }}
      >
        powered by ProntoHousing
      </Typography>
    </>
  ) : (
    <Box sx={{ width: width || 150, ...sx }} align="left">
      <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 644 190">
        <path
          style={{ fill: '#89909a' }}
          d="M16.58,19.84V170.16H141V19.84ZM28.67,31.93h68.7v91.83l-68.7,27ZM128.89,158.07H43L109.46,132V31.93h19.43Z"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M211.26,36.28h-22V97.12h12.12V78.21h11.71c16,0,24.4-7.26,24.4-21C237.45,43.91,227.91,36.28,211.26,36.28Zm.66,32.54H201.34V46.71h9.74c12.86,0,14.34,6.15,14.34,10.77C225.42,65.11,221,68.82,211.92,68.82Z"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M297,97.12h13.54l-14.82-24v-.76l.3-.14c6.23-3,9.67-8.84,9.67-16.46,0-12.4-8.83-19.51-24.21-19.51H258.43V97.12h12.12V74.74h12.38ZM270.55,65.81V46.52h10.77c8.44,0,12.37,3,12.37,9.55,0,6.83-3.92,9.74-13.12,9.74Z"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M358.43,35.15c-18.9,0-33.14,13.4-33.14,31.17,0,18.8,13.62,31.93,33.14,31.93s33.15-13.13,33.15-31.93C391.58,48.55,377.33,35.15,358.43,35.15Zm0,52.2c-12.31,0-20.27-8.22-20.27-20.93,0-11.8,8.52-20.37,20.27-20.37s20.27,8.57,20.27,20.37C378.7,79.13,370.75,87.35,358.43,87.35Z"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M458.64,97.12h11.55V36.28H458.07v27.5c0,3.51.84,13,.84,13.12l0,.57h-1L424.29,36.28H412.55V97.12h12.12V70.46c0-4.62-.83-14.06-.84-14.15l-.05-.57h1Z"
        />
        <polygon
          style={{ fill: '#89909a' }}
          points="546.64 36.28 489 36.28 489 46.9 511.76 46.9 511.76 97.12 523.88 97.12 523.88 46.9 546.64 46.9 546.64 36.28"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M594.28,35.15c-18.9,0-33.15,13.4-33.15,31.17,0,18.8,13.63,31.93,33.15,31.93s33.14-13.13,33.14-31.93C627.42,48.55,613.17,35.15,594.28,35.15Zm0,52.2C582,87.35,574,79.13,574,66.42c0-11.8,8.53-20.37,20.28-20.37s20.27,8.57,20.27,20.37C614.55,79.13,606.59,87.35,594.28,87.35Z"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M194.58,118.2v14.88h19.69V118.2h5.36v36h-5.36v-16.3H194.58v16.3h-5.36v-36Z"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M270.76,136c0,10.83-7.82,18.87-19.14,18.87-10.78,0-19.15-7.5-19.15-18.87,0-10.45,8.43-18.44,19.15-18.44S270.76,125.53,270.76,136Zm-32.71,0c0,9,5.91,14.05,13.57,14.05S265.18,145,265.18,136c0-8.15-5.85-13.62-13.56-13.62S238.05,127.83,238.05,136Z"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M315.38,118.2v22.21c0,9.84-7.54,14.44-16,14.44s-16-4.6-16-14.44V118.2h5.36v22.21c0,5.85,3.5,9.62,10.67,9.62S310,146.26,310,140.41V118.2Z"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M328.18,151.67l1.8-4.59A29,29,0,0,0,341.85,150c6.23,0,9.19-2.84,9.19-6.45a4.36,4.36,0,0,0-2.19-3.83c-1.42-.88-4.05-1.37-7.38-1.75-3.83-.44-6.62-1-8.86-2.24a8.35,8.35,0,0,1-4.65-7.82c0-5.86,5.58-10.4,13.72-10.4a28.19,28.19,0,0,1,12.42,2.63l-1.75,4.48a30.5,30.5,0,0,0-10.67-2.29c-6.28,0-8.36,2.9-8.36,5.58a4.08,4.08,0,0,0,2.29,3.71c1.43.83,3.61,1.1,6.95,1.54,3.5.49,6.84,1.15,8.81,2.24a8.62,8.62,0,0,1,4.81,8.15c0,6.34-5.36,11.27-14.28,11.27A27.32,27.32,0,0,1,328.18,151.67Z"
        />
        <path style={{ fill: '#89909a' }} d="M374.39,118.2v36H369v-36Z" />
        <path
          style={{ fill: '#89909a' }}
          d="M417.05,118.2h5.36v36h-4.82l-22.42-27.51h-.11s.55,6.18.55,9.13v18.38h-5.36v-36h4.81l22.43,27.51h.1s-.54-6.29-.54-8.37Z"
        />
        <path
          style={{ fill: '#89909a' }}
          d="M440.72,136.41c0,8.43,6,13.62,12.58,13.62,5,0,9.3-2.18,10.83-3.66v-6.46h-10v-4.48h15.37v18.76h-3.77L464.24,150h-.38c-2.68,3.88-6,4.87-10.56,4.87-11.59,0-18.05-8.15-18.05-18.44,0-11,7.71-18.87,18.93-18.87,7.49,0,11.59,2.74,14.16,5.09l-2.57,4.16c-3.5-2.68-6.78-4.43-11.59-4.43C445.65,122.36,440.72,128.32,440.72,136.41Z"
        />
      </svg>
    </Box>
  );
}
