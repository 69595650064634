// constants
export const DASHBOARD_ACTION_TYPES = {
  START_CONVERSATION: 'START_CONVERSATION',
  SEE_QUESTIONNAIRE: 'SEE_QUESTIONNAIRE',
  SEE_FILE_MANAGER: 'SEE_FILE_MANAGER',
  REVIEW_FILE: 'REVIEW_FILE',
  REQUEST_SIGNATURES: 'REQUEST_SIGNATURES',
  INVITE_TO_CERTIFICATION: 'INVITE_TO_CERTIFICATION',
};

export const TABLE_HEAD = [
  {
    id: 'task',
    label: 'Task',
    alignRight: false,
    sort: 'text',
    style: { width: '22%' },
  },
  {
    id: 'household',
    label: 'Household',
    alignRight: false,
    sort: 'certification__household__name',
    style: { width: '20%' },
  },
  {
    id: 'due-date',
    label: 'Due Date',
    alignRight: false,
    sort: 'due_date',
    style: { width: '10%' },
  },
  {
    id: 'snoozed',
    label: 'Snoozed',
    alignRight: false,
    sort: 'snooze_until',
    style: { width: '10%' },
  },
  {
    id: 'created',
    label: 'Created',
    alignRight: false,
    sort: 'created',
    style: { width: '10%' },
  },
  { id: 'action', label: '', alignRight: false, style: { width: '14%' } },
  { id: 'viewed', label: '', alignRight: false, style: { width: '4%' } },
  { id: 'check', label: '', alignRight: false, style: { width: '4%' } },
  { id: 'group', label: '', alignRight: false, style: { width: '5%' } },
];

// functions

const getButtonLabel = (type, count) => {
  switch (type) {
    case 'REVIEW_FILE':
      return count > 1 ? 'Review Documents' : 'Review Document';
    case 'START_CONVERSATION':
      return 'Send Followup';
    case 'SEE_QUESTIONNAIRE':
      return 'See Questionnaire';
    case 'SEE_FILE_MANAGER':
      return 'See File Manager';
    case 'REQUEST_SIGNATURES':
      return 'Request Signatures';
    case 'INVITE_TO_CERTIFICATION':
      return 'Invite to a New Certification';
    case 'CERTIFICATION_NOTICE':
      return 'Send Certification Notice';
    default:
      return 'This needs your attention';
  }
};

export const buildData = (tasksResponse, isSummary) => {
  const tasksList = tasksResponse.results || tasksResponse;

  const cleanTasks = tasksList.map((parentTask) => {
    const uniqueTaskList = uniqueTasks(parentTask.todo_list);

    return {
      ...uniqueTaskList[0],
      certification: parentTask.certification,
      property: parentTask.property,
      household: parentTask.household,
      unit: parentTask.unit,
      task_count: uniqueTaskList.length,
      tasks: uniqueTaskList,
      todo_action: parentTask.todo_action,
      todo_action_display: getButtonLabel(parentTask.todo_action),
    };
  });

  if (!isSummary) {
    return {
      ...tasksResponse,
      results: cleanTasks,
    };
  } else {
    return cleanTasks;
  }
};

export const uniqueTasks = (list) => {
  const uniqueList = [];
  list.forEach((item) => {
    if (!uniqueList.find((itm) => itm.id === item.id)) {
      uniqueList.push(item);
    }
  });
  return uniqueList;
};
