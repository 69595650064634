import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Button, Container, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
// components
import { MaintenanceIllustration } from '../assets';
import Page from '../components/Page';
import { MotionContainer, varBounceIn } from '../components/animate';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

const PageLoadError: FC = () => {
  return (
    <RootStyle title="404 Page Not Found | Minimal-UI">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                {"Sorry, we couldn't load this page!"}
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Something went wrong trying to load this page.
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Perhaps you’re using an older version of the app?
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Try refreshing the page or using our app on a different device.
            </Typography>

            <motion.div variants={varBounceIn}>
              <MaintenanceIllustration
                sx={{ height: 260, my: { xs: 5, sm: 10 } }}
              />
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }} gutterBottom>
              If the problem persists please contact support.
            </Typography>
            <Button
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Go to Home
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};
export default PageLoadError;
