import axios from '../utils/axios';
import { AxiosResponse } from 'axios';

export default class TeamsService {
  public static fetchComplianceTeamMembers = (
    params: string
  ): Promise<AxiosResponse> => {
    return axios.get(`teams/compliance_team/${params}`);
  };
}
