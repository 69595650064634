import React, { FC } from 'react';
import { Box, Container, Link, Typography } from '@mui/material';

const CopyrightMessage: FC = () => {
  return (
    <Box
      sx={{
        py: 5,
        paddingTop: '30px',
        textAlign: 'center',
        position: 'relative',
        backgroundColor: 'background.default',
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="caption" component="p">
          © All rights reserved
          <br /> made by &nbsp;
          <Link href="https://prontohousing.com/">prontohousing.com</Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default CopyrightMessage;
