import axiosInstance from 'src/utils/axios';

export const NECESSITIES = {
  1: { id: 1, name: 'Required', color: 'success' },
  2: { id: 2, name: 'Desired', color: 'info' },
  3: { id: 3, name: 'Not Required', color: 'warning' },
};

export const QUESTIONS_TYPES_WITH_CHOICES = [
  'choices',
  'multiselect',
  'bool',
  'frequency',
];

export const QIT_FIELDS = [
  'id',
  'order',
  'help_text',
  'is_mapped',
  'for_compliance',
  'necessity',
  'question_context',
  'question_choices',
  'member_types',
  'parent_qit',
  'parent_choice',
  'section.id',
  'section.name',
  'section.help_text',
  'section.calculations',
  'page.id',
  'page.name',
  'page.help_text',
  'question.id',
  'question.name',
  'question.help_text',
  'question.question_choices',
  'question.question_type',
  'question.question_type_display',
  'question.number_of_answers_required',
  'question.number_of_files_required_per_answer',
  'question.number_of_pages_required_per_file',
  'question_group.id',
  'question_group.name',
  'question_group.help_text',
  'question_group.type',
  'question_group.parent_qit',
  'question_group.parent_choice',
  'question_group.householdmember_type',
  'question_group.age_max',
  'question_group.age_min',
  'question_group.carry_over_config',
  'question_group.calculations',
  'number_of_answers_required',
  'number_of_files_required_per_answer',
  'number_of_pages_required_per_file',
  'carry_over_config',
  'calculation',
  'question_group.tic_group',
  'is_TIC',
  'related_form.id',
  'related_form.name',
  'tic_element.tic_group.tic_setups.id',
  'tic_element.tic_group.tic_setups.program',
  'question_group.tic_group_obj.tic_setups.id',
  'question_group.tic_group_obj.tic_setups.program',
];

export const QIT_EXPAND = [
  'section',
  'section.calculations.variables',
  'page',
  'question_choices',
  'member_types',
  'parent_qit',
  'parent_choice',
  'question_context',
  'question_context.context_template',
  'parent_qit.question',
  'parent_qit.question_choices',
  'question',
  'question.question_choices',
  'related_form',
  'question_group.parent_choice',
  'question_group.parent_qit',
  'question_group.parent_qit.question_choices',
  'question_group.parent_qit.question',
  'question_group.parent_qit.question.question_choices',
  'question_group.householdmember_type',
  'question_group.render_types',
  'question_group.calculations.variables',
  'calculation.variables',
  'tic_element.tic_group.tic_setups.program',
  'question_group.tic_group_obj.tic_setups.program',
];

export const QUESTION_FIELDS = [
  'id',
  'name',
  'help_text',
  'question_choices',
  'question_type',
  'number_of_answers_required',
  'number_of_files_required_per_answer',
  'number_of_pages_required_per_file',
  'carry_over_config',
];

export const QUESTION_EXPAND = ['question_choices'];

export const PROPERTY_FIELDS = [
  'id',
  'name',
  'company_name',
  'slug',
  'description',
];

export const FORM_FIELDS = [
  'qit.section',
  'qit.page',
  'qit.question_group',
  'form_page.form.id',
  'form_page.form.name',
];

export const FORM_EXPAND = ['qit', 'form_page.form'];

export const GROUP_FIELDS = [
  'id',
  'name',
  'help_text',
  'type',
  'tic_group',
  'parent_qit',
  'parent_choice',
  'householdmember_type',
  'age_max',
  'age_min',
  'carry_over_config',
  'calculations',
  'tic_group_obj.tic_setups.id',
  'tic_group_obj.tic_setups.program',
];

export const GROUP_EXPAND = [
  'parent_choice',
  'parent_qit',
  'parent_qit.question_choices',
  'parent_qit.question',
  'parent_qit.question.question_choices',
  'householdmember_type',
  'calculations.variables',
  'tic_group_obj.tic_setups.program',
];

export const GROUP_TYPES = [
  { value: 'multi', label: 'Yes' },
  { value: 'single', label: 'No' },
];

export const CARRY_OVER_CONFIG = [
  { value: 1, name: 'Never' },
  { value: 2, name: 'Always' },
  { value: 3, name: 'Partial' },
];

/*export const GROUP_TICS_CHOICES = {
 "none": "None",
 "employment": "Employment",
 "self_employment": "Self Employment",
 "ssp_income": "Social Security and Pensions Income",
 "pa_income": "Public Assistance Income",
 "other_income": "Other Income",
 "bank_institution_acct": "Bank Institution ACCT",
 "retirement_accounts": "Retirement Accounts",
 "other_assets": "Other Assets",
 }*/

export const sortByOrder = (a, b) => a.order - b.order;

export const hasChoices = (type) => QUESTIONS_TYPES_WITH_CHOICES.includes(type);

export const capitalize = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

export const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj));

export const buildTreeData = (qits) => {
  const sections = {};
  for (let i = 0; i < qits.length; i++) {
    const qit = qits[i];
    if (!sections[qit.section.id]) {
      sections[qit.section.id] = {
        ...qit.section,
        pages: {},
        order: Object.keys(sections).length,
      };
    }
    if (!sections[qit.section.id].pages[qit.page.id]) {
      sections[qit.section.id].pages[qit.page.id] = {
        ...qit.page,
        groups: {},
        order: Object.keys(sections[qit.section.id].pages).length,
      };
    }
    if (
      !sections[qit.section.id].pages[qit.page.id].groups[qit.question_group.id]
    ) {
      sections[qit.section.id].pages[qit.page.id].groups[
        qit.question_group.id
      ] = {
        ...qit.question_group,
        qits: {},
        qitBoundingStart: i,
        order: Object.keys(sections[qit.section.id].pages[qit.page.id].groups)
          .length,
      };
    }
    if (qit.question_context) {
      if (
        !sections[qit.section.id].pages[qit.page.id].groups[
          qit.question_group.id
        ].qits[`context-${qit.question_context.id}`]
      ) {
        sections[qit.section.id].pages[qit.page.id].groups[
          qit.question_group.id
        ].qits[`context-${qit.question_context.id}`] = {
          ...qit.question_context,
          id: `context-${qit.question_context.id}`,
          qits: {},
          order: i,
        };
      }
      sections[qit.section.id].pages[qit.page.id].groups[
        qit.question_group.id
      ].qits[`context-${qit.question_context.id}`].qits[qit.id] = {
        ...qit,
        order: i,
      };
    } else {
      sections[qit.section.id].pages[qit.page.id].groups[
        qit.question_group.id
      ].qits[qit.id] = {
        ...qit,
        order: i,
      };
    }
  }
  return sections;
};

export const extractChildrenQits = (qit, groupQits) => {
  const childrenChoices = Object.values(qit.childrenChoices);
  if (childrenChoices?.length > 0) {
    const childrenQits = childrenChoices.reduce((acc, choice) => {
      const choiceQits = groupQits
        .filter((groupQit) => choice.qits.includes(groupQit.id))
        .reduce((acc, qit) => {
          return acc.concat(extractChildrenQits(qit, groupQits));
        }, []);
      return acc.concat(choiceQits);
    }, []);
    return [qit, ...childrenQits];
  }
  return [qit];
};

export const getChildrenQitsLevel = (qit, groupQits, baseLevel) => {
  const childrenChoices = Object.values(qit.childrenChoices);
  if (childrenChoices?.length > 0) {
    const childrenQit = groupQits.find(
      (groupQit) => groupQit.id === childrenChoices[0].qits[0]
    );
    return getChildrenQitsLevel(childrenQit, groupQits, baseLevel);
  }
  return qit.childrenLevel - baseLevel;
};

export const getChildrenQits = (qit) => {
  if (qit.childrenChoices) {
    const childrenQits = qit.childrenChoices.reduce((acc, choice) => {
      const choiceQits = Object.values(choice.qits).reduce((acc, qit) => {
        return acc.concat(getChildrenQits(qit));
      }, []);
      return acc.concat(choiceQits);
    }, []);
    return [qit, ...childrenQits];
  }
  return [qit];
};

export const getChildrenChoices = (parentQits, qit) => {
  const parentQit = parentQits[qit.id];
  if (parentQit) {
    return Object.values(parentQit.childrenChoices)
      .sort(sortByOrder)
      .map((choice) => ({
        ...choice,
        qits: Object.values(choice.qits)
          .sort(sortByOrder)
          .map((qit) => ({
            ...qit,
            childrenChoices: getChildrenChoices(parentQits, qit),
          })),
      }));
  }
  return null;
};

export const getOrderedGroupQits = (groupQits) => {
  const parentQits = {};
  const orderedGroupQits = [];
  let index = 0;

  for (const qit of groupQits) {
    if (qit.parent_qit && qit.parent_choice) {
      if (!parentQits[qit.parent_qit.id]) {
        parentQits[qit.parent_qit.id] = {
          ...qit.parent_qit,
          childrenChoices: {},
        };
      }
      if (
        !parentQits[qit.parent_qit.id].childrenChoices[qit.parent_choice.id]
      ) {
        parentQits[qit.parent_qit.id].childrenChoices[qit.parent_choice.id] = {
          ...qit.parent_choice,
          qits: {},
          order: index,
        };
      }
      parentQits[qit.parent_qit.id].childrenChoices[qit.parent_choice.id].qits[
        qit.id
      ] = {
        ...qit,
        order: index,
      };
    } else if (qit.parent_qit && !qit.parent_choice) {
      if (!parentQits[qit.parent_qit.id]) {
        parentQits[qit.parent_qit.id] = {
          ...qit.parent_qit,
          childrenChoices: {},
        };
      }
      if (!parentQits[qit.parent_qit.id].childrenChoices[qit.parent_qit.id]) {
        parentQits[qit.parent_qit.id].childrenChoices[qit.parent_qit.id] = {
          id: qit.parent_qit.id,
          name: 'Per File',
          qits: {},
          order: index,
        };
      }
      parentQits[qit.parent_qit.id].childrenChoices[qit.parent_qit.id].qits[
        qit.id
      ] = {
        ...qit,
        order: index,
      };
    } else {
      orderedGroupQits.push({
        ...qit,
        order: index,
      });
    }
    index++;
  }
  return orderedGroupQits.map((qit) => ({
    ...qit,
    childrenChoices: getChildrenChoices(parentQits, qit),
  }));
};

export const getOrderedQits = (data) => {
  const sections = buildTreeData(data);
  const orderedQits = Object.values(sections)
    .sort(sortByOrder)
    .reduce((acc, section) => {
      const sectionQits = Object.values(section.pages)
        .sort(sortByOrder)
        .reduce((acc, page) => {
          const pageQits = Object.values(page.groups)
            .sort(sortByOrder)
            .reduce((acc, group) => {
              const groupQits = Object.values(group.qits)
                .sort(sortByOrder)
                .reduce((acc, qit) => {
                  if (typeof qit.id === 'string') {
                    return acc.concat(
                      Object.values(qit.qits).sort(sortByOrder)
                    );
                  }
                  return [...acc, qit];
                }, []);
              const orderedGroupQits = getOrderedGroupQits(groupQits).reduce(
                (acc, qit) => {
                  return acc.concat(getChildrenQits(qit));
                },
                []
              );
              return acc.concat(orderedGroupQits);
            }, []);
          return acc.concat(pageQits);
        }, []);
      return acc.concat(sectionQits);
    }, []);
  return orderedQits.map((qit, index) => ({ ...qit, order: index }));
};

export const buildQitsData = (orderedQits) => {
  const sections = {};
  const pages = {};
  const groups = {};
  const qits = {};
  const questionContexts = {};

  for (let i = 0; i < orderedQits.length; i++) {
    const qit = orderedQits[i];
    let childrenLevel = 0;

    // build sections
    if (!sections[qit.section.id]) {
      sections[qit.section.id] = {
        ...qit.section,
        pages: [],
        qitBoundingStart: i,
        qitBoundingEnd: i,
        order: Object.keys(sections).length,
      };
    } else {
      // if exist update qitBoundingEnd
      sections[qit.section.id].qitBoundingEnd = i;
    }
    if (!sections[qit.section.id].pages.includes(qit.page.id)) {
      // add page
      sections[qit.section.id].pages.push(qit.page.id);
    }

    // build pages
    if (!pages[qit.page.id]) {
      pages[qit.page.id] = {
        ...qit.page,
        groups: [],
        qitBoundingStart: i,
        qitBoundingEnd: i,
        hasForms: false,
        sectionId: qit.section.id,
        order: sections[qit.section.id].pages.indexOf(qit.page.id),
      };
    } else {
      // if exists update qitBoundingEnd
      pages[qit.page.id].qitBoundingEnd = i;
    }
    if (!pages[qit.page.id].hasForms && qit.is_mapped) {
      pages[qit.page.id].hasForms = true;
    }
    if (!pages[qit.page.id].groups.includes(qit.question_group.id)) {
      // add question group
      pages[qit.page.id].groups.push(qit.question_group.id);
    }

    // build groups
    if (!groups[qit.question_group.id]) {
      groups[qit.question_group.id] = {
        ...qit.question_group,
        qits: [],
        qitBoundingStart: i,
        qitBoundingEnd: i,
        order: pages[qit.page.id].groups.indexOf(qit.question_group.id),
      };
    } else {
      // if exists update qitBoundingEnd
      groups[qit.question_group.id].qitBoundingEnd = i;
    }

    // build context
    if (qit.question_context) {
      if (!questionContexts[qit.question_context.id]) {
        questionContexts[qit.question_context.id] = {
          ...qit.question_context,
          qits: [],
          qitBoundingStart: i,
          qitBoundingEnd: i,
          order: i,
        };
        groups[qit.question_group.id].qits.push(
          `context-${qit.question_context.id}`
        ); // add context to group
      } else {
        // if exists update qitBoundingEnd
        questionContexts[qit.question_context.id].qitBoundingEnd = i;
      }
      questionContexts[qit?.question_context?.id].qits.push(qit.id); // add qit to context
    } else {
      // build parent qits
      if (qit.parent_qit && qit.parent_choice) {
        if (qits[qit.parent_qit.id]) {
          if (!qits[qit.parent_qit.id].childrenChoices[qit.parent_choice.id]) {
            qits[qit.parent_qit.id].childrenChoices[qit.parent_choice.id] = {
              ...qit.parent_choice,
              qits: [],
              qitBoundingStart: i,
              qitBoundingEnd: i,
              order: i,
            };
          } else {
            // if exists update qitBoundingEnd
            qits[qit.parent_qit.id].childrenChoices[
              qit.parent_choice.id
            ].qitBoundingEnd = i;
          }
          qits[qit.parent_qit.id].childrenChoices[
            qit.parent_choice.id
          ].qits.push(qit.id); // add qit to parent
          qits[qit.parent_qit.id].qitBoundingEnd = i; // update qitBoundingEnd
          childrenLevel = qits[qit.parent_qit.id].childrenLevel + 1;
        } else {
          groups[qit.question_group.id].qits.push(qit.id); // add qit to group
        }
      } else if (qit.parent_qit && !qit.parent_choice) {
        if (qits[qit.parent_qit.id]) {
          if (!qits[qit.parent_qit.id].childrenChoices[qit.parent_qit.id]) {
            qits[qit.parent_qit.id].childrenChoices[qit.parent_qit.id] = {
              id: qit.parent_qit.id,
              name: 'Per File',
              qits: [],
              qitBoundingStart: i,
              qitBoundingEnd: i,
              order: i,
            };
          } else {
            // if exists update qitBoundingEnd
            qits[qit.parent_qit.id].childrenChoices[
              qit.parent_qit.id
            ].qitBoundingEnd = i;
          }
          qits[qit.parent_qit.id].childrenChoices[qit.parent_qit.id].qits.push(
            qit.id
          ); // add qit to parent
          qits[qit.parent_qit.id].qitBoundingEnd = i; // update qitBoundingEnd
          childrenLevel = qits[qit.parent_qit.id].childrenLevel + 1;
        } else {
          groups[qit.question_group.id].qits.push(qit.id); // add qit to group
        }
      } else {
        groups[qit.question_group.id].qits.push(qit.id); // add qit to group
      }
    }
    // build qits
    qits[qit.id] = {
      ...qit,
      childrenLevel,
      childrenChoices: {},
      qitBoundingStart: i,
      qitBoundingEnd: i,
      order: i,
    };
  }

  return { sections, pages, groups, qits, questionContexts };
};

export const buildGroupForms = (mappings) => {
  const groupForms = {};

  for (let i = 0; i < mappings.length; i++) {
    const mapping = mappings[i];
    const questionGroup = mapping.qit.question_group;
    const form = mapping.form_page.form;
    if (!groupForms[questionGroup]) {
      groupForms[questionGroup] = {};
    }
    if (!groupForms[questionGroup][form.id]) {
      groupForms[questionGroup][form.id] = {
        ...form,
        page: mapping.qit.page,
        section: mapping.qit.section,
      };
    }
  }

  return groupForms;
};

export const patchQitsOrder = (orderedQits, certificationConfig) => {
  const qitsBody = orderedQits.map((qit, index) => ({
    qit: qit.id,
    order: index,
  }));
  const url = `questionnaire/question_library/update_order_qits/?certification_config=${certificationConfig.id}`;

  axiosInstance
    .post(url, qitsBody)
    .then((response) => {
      console.log(response.data); // eslint-disable-line
    })
    .catch((error) => {
      console.error(error);
    });
};

export const insertCreatedQit = (
  pages,
  groups,
  sections,
  questionContexts,
  orderedQits,
  insertedElement
) => {
  const qit = insertedElement[0];

  if (pages[qit.page.id].groups.length > 1) {
    const sectionOrder = sections[qit.section.id]?.order;
    const pageOrder = pages[qit.page.id]?.order;
    const groupIndex = pages[qit.page.id].groups.indexOf(qit.question_group.id);
    const contiguousGroupId =
      pages[qit.page.id].groups[
        groupIndex === 0 ? groupIndex + 1 : groupIndex - 1
      ];
    const contiguousGroup = groups[contiguousGroupId];
    const contiguousGroupLastQit =
      groups[contiguousGroupId].qits[
        groupIndex === 0 ? 0 : contiguousGroup.qits.length - 1
      ];
    let contiguousQitIndex;

    if (typeof contiguousGroupLastQit === 'string') {
      const contextId = contiguousGroupLastQit.split('-').pop();
      contiguousQitIndex =
        questionContexts[contextId].qits[
          groupIndex === 0 ? 0 : questionContexts[contextId].qits.length - 1
        ];
    } else {
      contiguousQitIndex = contiguousGroupLastQit;
    }

    const orderedQitIndex = orderedQits.findIndex(
      (qit) => qit.id === contiguousQitIndex
    );
    orderedQits.splice(
      sectionOrder === 0 && pageOrder === 0 && groupIndex === 0
        ? 0
        : groupIndex === 0
        ? orderedQitIndex
        : orderedQitIndex + 1,
      0,
      ...insertedElement
    );
  } else {
    const sectionPages = sections[qit.section.id].pages;
    if (sectionPages && sectionPages.length > 1) {
      const pageIndex = sectionPages.indexOf(qit.page.id);
      const contiguousPageId =
        sectionPages[pageIndex === 0 ? 0 : pageIndex - 1];
      const contiguousPage = pages[contiguousPageId];
      const contiguousGroupId =
        contiguousPage.groups[contiguousPage.groups.length - 1];
      const contiguousGroup = groups[contiguousGroupId];
      const contiguousGroupLastQit =
        groups[contiguousGroupId].qits[contiguousGroup.qits.length - 1];
      let contiguousQitIndex;

      if (typeof contiguousGroupLastQit === 'string') {
        const contextId = contiguousGroupLastQit.split('-').pop();
        contiguousQitIndex =
          questionContexts[contextId].qits[
            questionContexts[contextId].qits.length - 1
          ];
      } else {
        contiguousQitIndex = contiguousGroupLastQit;
      }

      const orderedQitIndex = orderedQits.findIndex(
        (qit) => qit.id === contiguousQitIndex
      );
      orderedQits.splice(orderedQitIndex + 1, 0, ...insertedElement);
    } else if (Object.entries(sections).length > 1) {
      const orderedSections = Object.values(sections).sort(sortByOrder);
      const sectionIndex = orderedSections.findIndex(
        (section) => section.id === qit.section.id
      );
      const contiguousSection =
        orderedSections[
          sectionIndex === 0 ? sectionIndex + 1 : sectionIndex - 1
        ];
      const contiguousPageId =
        contiguousSection.pages[
          sectionIndex === 0 ? 0 : contiguousSection.pages.length - 1
        ];
      const contiguousPage = pages[contiguousPageId];
      const contiguousGroupId =
        contiguousPage.groups[
          sectionIndex === 0 ? 0 : contiguousPage.groups.length - 1
        ];
      const contiguousGroup = groups[contiguousGroupId];
      const contiguousGroupLastQit =
        contiguousGroup.qits[
          sectionIndex === 0 ? 0 : contiguousGroup.qits.length - 1
        ];
      let contiguousQitIndex;

      if (typeof contiguousGroupLastQit === 'string') {
        const contextId = contiguousGroupLastQit.split('-').pop();
        contiguousQitIndex =
          questionContexts[contextId].qits[
            sectionIndex === 0 ? 0 : questionContexts[contextId].qits.length - 1
          ];
      } else {
        contiguousQitIndex = contiguousGroupLastQit;
      }

      const orderedQitIndex = orderedQits.findIndex(
        (qit) => qit.id === contiguousQitIndex
      );
      orderedQits.splice(
        sectionIndex === 0 ? 0 : orderedQitIndex + 1,
        0,
        ...insertedElement
      );
    }
  }
  return orderedQits;
};

export const validateReorder = (orderedQits) => {
  return !orderedQits.some((currentQit, i) => {
    if (!currentQit.question_group.parent_qit && !currentQit.parent_qit) {
      return false;
    }

    const topQits = orderedQits.slice(0, i); // get qits from 0 to Question Index
    let validGroupParentQit;
    let validParentQit;

    if (currentQit.question_group.parent_qit) {
      validGroupParentQit = Boolean(
        topQits.find(
          (topQit) => topQit.id === currentQit.question_group.parent_qit.id
        )
      );
      if (!validGroupParentQit) {
        console.error(
          'Paren question: ',
          currentQit.question_group.parent_qit.question.name
        );
        console.error('Child question: ', currentQit.question.name);
      }
    } else {
      validGroupParentQit = true;
    }

    if (currentQit.parent_qit) {
      validParentQit = Boolean(
        topQits.find((topQit) => topQit.id === currentQit.parent_qit.id)
      );
    } else {
      validParentQit = true;
    }
    return !(validGroupParentQit && validParentQit);
  });
};

export const preValidateReorderQits = (active, over, orderedQits) => {
  if (!active || !over) {
    return false;
  }
  // splice bounding qits
  const boundingQits = orderedQits.splice(
    active.qitBoundingStart,
    active.qitBoundingEnd - active.qitBoundingStart + 1
  );
  // insert bounding qits
  if (active.qitBoundingStart < over.qitBoundingStart) {
    orderedQits.splice(
      over.qitBoundingEnd - boundingQits.length + 1,
      0,
      ...boundingQits
    );
  } else {
    orderedQits.splice(over.qitBoundingStart, 0, ...boundingQits);
  }
  // reindex qits
  const newOrderedQits = orderedQits.map((qit, index) => ({
    ...qit,
    order: index,
  }));

  return validateReorder(newOrderedQits);
};

export const getPreviousQitIndex = (
  pages,
  groups,
  sections,
  questionContexts,
  orderedQits,
  element
) => {
  const qit = element;

  if (pages[qit.page].groups.length > 1) {
    const groupIndex = pages[qit.page].groups.indexOf(qit.question_group);
    const contiguousGroupId =
      pages[qit.page].groups[
        groupIndex === 0 ? groupIndex + 1 : groupIndex - 1
      ];
    const contiguousGroup = groups[contiguousGroupId];
    const contiguousGroupLastQit =
      groups[contiguousGroupId].qits[contiguousGroup.qits.length - 1];
    let contiguousQitIndex;

    if (typeof contiguousGroupLastQit === 'string') {
      const contextId = contiguousGroupLastQit.split('-').pop();
      contiguousQitIndex =
        questionContexts[contextId].qits[
          groupIndex === 0 ? 0 : questionContexts[contextId].qits.length - 1
        ];
    } else {
      contiguousQitIndex = contiguousGroupLastQit;
    }

    const orderedQitIndex = orderedQits.findIndex(
      (qit) => qit.id === contiguousQitIndex
    );
    return groupIndex === 0 ? 0 : orderedQitIndex + 1;
  } else {
    const sectionPages = sections[qit.section].pages;
    const pageIndex = sectionPages.indexOf(qit.page);
    if (sectionPages && sectionPages.length > 1 && pageIndex !== 0) {
      const contiguousPageId = sectionPages[pageIndex - 1];
      const contiguousPage = pages[contiguousPageId];
      const contiguousGroupId =
        contiguousPage.groups[contiguousPage.groups.length - 1];
      const contiguousGroup = groups[contiguousGroupId];
      const contiguousGroupLastQit =
        groups[contiguousGroupId].qits[contiguousGroup.qits.length - 1];
      let contiguousQitIndex;

      if (typeof contiguousGroupLastQit === 'string') {
        const contextId = contiguousGroupLastQit.split('-').pop();
        contiguousQitIndex =
          questionContexts[contextId].qits[
            questionContexts[contextId].qits.length - 1
          ];
      } else {
        contiguousQitIndex = contiguousGroupLastQit;
      }

      const orderedQitIndex = orderedQits.findIndex(
        (qit) => qit.id === contiguousQitIndex
      );
      return orderedQitIndex + 1;
    } else if (Object.entries(sections).length > 1) {
      const orderedSections = Object.values(sections).sort(sortByOrder);
      const sectionIndex = orderedSections.findIndex(
        (section) => section.id === qit.section
      );
      const contiguousSection =
        orderedSections[
          sectionIndex === 0 ? sectionIndex + 1 : sectionIndex - 1
        ];
      const contiguousPageId =
        contiguousSection.pages[
          sectionIndex === 0 ? 0 : contiguousSection.pages.length - 1
        ];
      const contiguousPage = pages[contiguousPageId];
      const contiguousGroupId =
        contiguousPage.groups[
          sectionIndex === 0 ? 0 : contiguousPage.groups.length - 1
        ];
      const contiguousGroup = groups[contiguousGroupId];
      const contiguousGroupLastQit =
        contiguousGroup.qits[
          sectionIndex === 0 ? 0 : contiguousGroup.qits.length - 1
        ];
      let contiguousQitIndex;

      if (typeof contiguousGroupLastQit === 'string') {
        const contextId = contiguousGroupLastQit.split('-').pop();
        contiguousQitIndex =
          questionContexts[contextId].qits[
            sectionIndex === 0 ? 0 : questionContexts[contextId].qits.length - 1
          ];
      } else {
        contiguousQitIndex = contiguousGroupLastQit;
      }

      const orderedQitIndex = orderedQits.findIndex(
        (qit) => qit.id === contiguousQitIndex
      );
      return sectionIndex === 0 ? 0 : orderedQitIndex + 1;
    }
  }
  return 0;
};

// this a function for general proposes, returns try if one if the elements
// of the provided items include the search value
export const listIncludesSearch = (
  idsArray = [],
  itemsObj = {},
  search = '',
  isQit = false
) => {
  let result = false;

  for (let i = 0; i < idsArray.length; i++) {
    const itemId = idsArray[i];
    if (!itemsObj[itemId]) {
      continue;
    }

    let itemName = (itemsObj[itemId]?.name || '').toLowerCase();
    if (isQit) {
      itemName = itemsObj[itemId]?.question?.name?.toLowerCase() || '';
    }

    if (itemName.includes(search)) {
      result = true;
      break;
    }
  }

  return result;
};

// validate if any of the qits or the qits inside of a context
// includes the search value
const searchOnQits = ({ qitId, questionContexts, qits, search }) => {
  let result = false;
  // if the qitId is a string this means that we are evaluating a questionnaire context
  // and we need to iterate over the questions inside
  if (typeof qitId === 'string') {
    const contextId = parseInt(qitId.split('-').pop());
    const context = questionContexts[contextId];
    if (context) {
      const contextResult = listIncludesSearch(
        context.qits,
        qits,
        search,
        true
      );
      if (contextResult) {
        result = true;
      }
    }
  } else {
    const questionName = qits[qitId]?.question?.name?.toLowerCase() || '';
    if (questionName.includes(search)) {
      result = true;
    }
  }
  return result;
};

export const searchOnGroupsInnerElements = ({
  groupsIdsArray = [],
  groupsObj = {},
  searchValue = '',
  qitsObj = {},
  questionContexts = {},
}) => {
  // validate if the search value exist on the name of any of the page groups
  let result = listIncludesSearch(groupsIdsArray, groupsObj, searchValue);

  if (!result) {
    // if the search name is not the groups name
    // search on the questions name of each group
    groupsLoop: for (let i = 0; i < groupsIdsArray.length; i++) {
      const groupData = groupsObj[groupsIdsArray[i]];
      if (!groupData?.qits?.length) {
        continue;
      }

      for (let x = 0; x < groupData.qits.length; x++) {
        const qitId = groupData.qits[x];
        if (
          searchOnQits({
            qitId,
            questionContexts,
            qits: qitsObj,
            search: searchValue,
          })
        ) {
          result = true;
          break groupsLoop;
        }
      }
    }
  }
  return result;
};

// iterate over all the inner elements of the section
// to look if any of the names of the inner elements
// matches with the search value
const searchOnSectionInnerElements = ({
  pages = [],
  sectionId,
  groups,
  qits,
  questionContexts,
  search,
}) => {
  let result = false;

  // we look inside the pages on the section
  pagesLoop: for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    // ignores all the items not related to the section
    if (page.sectionId !== sectionId) {
      continue;
    }

    // if the section name includes the value search
    if (page.name.toLowerCase().includes(search)) {
      result = true;
      break;
    }

    // we look inside the pages of the section
    for (let x = 0; x < page.groups.length; x++) {
      const groupId = page.groups[x];
      const groupQits = groups[groupId]?.qits || [];
      const groupName = groups[groupId]?.name?.toLowerCase() || '';

      if (groupName.includes(search)) {
        result = true;
        break pagesLoop;
      }

      // finally we look on the qits and contexts
      for (let y = 0; y < groupQits.length; y++) {
        const qitId = groupQits[y];
        // if the qitId is a string this means that we are evaluating a questionnaire context
        // and we need to iterate over the questions inside
        if (searchOnQits({ qitId, questionContexts, qits, search })) {
          result = true;
          break pagesLoop;
        }
      }
    }
  }
  return result;
};

export const filterSections = (
  sections,
  pages,
  groups,
  qits,
  search,
  questionContexts
) => {
  const searchLower = `${search}`.toLowerCase();
  const allPages = Object.values(pages);
  const result = [];

  // iterate over all the sections
  for (let a = 0; a < sections.length; a++) {
    const section = sections[a];
    // we look for the section by name
    if (section.name.toLowerCase().includes(searchLower)) {
      result.push(section);
      continue;
    }
    // we look on the section page names
    const config = {
      pages: allPages,
      sectionId: section.id,
      groups,
      qits,
      questionContexts,
      search: searchLower,
    };
    if (searchOnSectionInnerElements(config)) {
      result.push(section);
    }
  }

  return result;
};
