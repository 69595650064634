import { Box } from '@mui/material';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { setExpandedContent } from '../../redux/slices/Main/main';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export default function SidebarControl() {
  const dispatch = useDispatch();

  const expandedPageContent = useSelector(
    ({ main: { expandedPageContent } }) => expandedPageContent,
    shallowEqual
  );

  const toggleSidebar = () => {
    dispatch(setExpandedContent(!expandedPageContent));
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 27,
        left: expandedPageContent ? 0 : 280,
        width: 35,
        height: 35,
        zIndex: 1200,
        transform: expandedPageContent
          ? 'translateX(-50%)'
          : 'translateX(-50%)',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          color: (theme) => theme.palette.text.secondary,
          backgroundColor: (theme) => theme.palette.background.paper,
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
          borderRadius: '8px',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: expandedPageContent ? 'flex-end' : 'center',
        }}
        onClick={toggleSidebar}
      >
        {expandedPageContent ? (
          <ChevronRightOutlinedIcon size="medium" />
        ) : (
          <ChevronLeftOutlinedIcon size="medium" />
        )}
      </Box>
    </Box>
  );
}
