import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;
const APP_VERSION = process.env.REACT_APP_VERSION || 'v1.x.x';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: '100%',
  },
}));

// ----------------------------------------------------------------------

export default function SidebarFooter() {
  const theme = useTheme();

  const renderContent = (
    <>
      <Typography variant="body2" align="center">
        <RouterLink
          to={PATH_PAGE.termsOfUse}
          style={{ color: theme.palette.text.disabled, textDecoration: 'none' }}
        >
          Terms of Use
        </RouterLink>
      </Typography>
      <Typography variant="body2" align="center">
        <RouterLink
          to={PATH_PAGE.cookiesPolicy}
          style={{ color: theme.palette.text.disabled, textDecoration: 'none' }}
        >
          Cookie Policy
        </RouterLink>{' '}
        &nbsp;&nbsp;
        <RouterLink
          to={PATH_PAGE.privacyPolicy}
          style={{ color: theme.palette.text.disabled, textDecoration: 'none' }}
        >
          Privacy Policy
        </RouterLink>
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{
          color: theme.palette.grey[500],
          marginBottom: '10px',
        }}
      >
        {APP_VERSION}
      </Typography>
    </>
  );

  return <RootStyle>{renderContent}</RootStyle>;
}
